export const renderedText = (text: string) => {
  const lines = text.split('\n');
  let inList = false;
  let listItems: React.ReactNode[] = [];

  const renderedLines = lines.map((line, index) => {
    if (line.trim().startsWith('- ')) {
      if (!inList) {
        inList = true;
        listItems = [];
      }
      listItems.push(<li key={index}>{line.substring(2)}</li>);
    } else {
      if (inList) {
        inList = false;
        return <ul key={index}>{listItems}</ul>;
      }
      return <p key={index}>{line}</p>;
    }
    return null;
  });

  if (inList) {
    return [...renderedLines, <ul key={lines.length}>{listItems}</ul>];
  }

  return renderedLines;
};
