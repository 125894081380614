import Head from 'next/head';
import { useRouter } from 'next/router';
import { useFormat } from 'helpers/hooks/useFormat';

interface Props {
  title: { [key: string]: string } | string | undefined;
  description?: { [key: string]: string } | string | undefined;
}

export const MetaTitle = ({ title, description }: Props) => {
  const { locale } = useRouter() ?? '';

  const { formatMessage } = useFormat({ name: 'common' });

  // If title is not added in studio, it is coming as empty array ('[]'). Adding title from language json file as fallback.
  let pageTitle =
    Array.isArray(title) || !title
      ? formatMessage({ id: 'meta.title' })
      : typeof title === 'object'
      ? locale && title[locale]
      : title;

  pageTitle += ' | lululemon CA/US';

  const pageContent =
    Array.isArray(description) || !description
      ? formatMessage({ id: 'meta.desc' })
      : typeof description === 'object'
      ? locale && description[locale]
      : description;

  return (
    <Head>
      <title data-testid="title__page-title_test-id">{pageTitle}</title>
      <meta name="description" content={pageContent} />
    </Head>
  );
};
