import { useCallback } from 'react';
export type PasswordSpecification = {
  ruleText: string;
  isValid: boolean;
};
export type PasswordSpecificationRules = {
  length: PasswordSpecification;
  character: PasswordSpecification;
  case: PasswordSpecification;
  digit: PasswordSpecification;
};
export const getPasswordRulesData = (): PasswordSpecificationRules => {
  return {
    length: {
      ruleText: '12 characters',
      isValid: false,
    },
    character: {
      ruleText: '1 special character',
      isValid: false,
    },
    case: {
      ruleText: '1 uppercase',
      isValid: false,
    },
    digit: {
      ruleText: '1 digit',
      isValid: false,
    },
  };
};
const useValidate = () => {
  const validateTextExists = useCallback((name: string) => name.length >= 1, []);

  const validatePassword = useCallback((password: string) => {
    const upperCaseMatches = /[A-Z]/.test(password);
    const digitMatches = /\d/.test(password);
    const specialCharacterMatches = /[\W_]/.test(password);

    const criteriaMet = [upperCaseMatches, digitMatches, specialCharacterMatches].filter(Boolean).length;
    const passwordRules = getPasswordRulesData();
    if (password.length >= 12) {
      passwordRules.length.isValid = true;
    }
    if (specialCharacterMatches) {
      passwordRules.character.isValid = true;
    }
    if (upperCaseMatches) {
      passwordRules.case.isValid = true;
    }
    if (digitMatches) {
      passwordRules.digit.isValid = true;
    }

    if (criteriaMet == 3 && passwordRules.length.isValid) {
      return { passwordSpecificationList: passwordRules, isValid: true };
    } else {
      return { passwordSpecificationList: passwordRules, isValid: false };
    }
  }, []);

  const validateEmail = useCallback((email: string) => {
    const emailRules =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return emailRules.test(email);
  }, []);

  const validatePostalCode = useCallback((value: string) => Number.isInteger(Number(value)) && value.length === 5, []);

  const validatePhoneNumber = useCallback((value: string) => {
    const phonenumberRules = /^\d{10}$/;
    return phonenumberRules.test(value);
  }, []);

  const validateExpiryDate = useCallback((dateString: string) => {
    const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;
    if (!regex.test(dateString)) {
      return false; // Invalid format
    }

    const [inputMonth, inputYear] = dateString.split('/').map(Number);

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-indexed
    const currentYear = currentDate.getFullYear() % 100; // Get last two digits of the year
    // Compare with the current month and year
    if (inputYear < currentYear || (inputYear === currentYear && inputMonth <= currentMonth)) {
      return false;
    }

    return true;
  }, []);

  const validateCalculatedExpiryDate = useCallback((inputMonth: number, inputYear: number) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-indexed
    const currentYear = currentDate.getFullYear(); // Get last two digits of the year
    // Compare with the current month and year
    if (inputYear < currentYear || (inputYear === currentYear && inputMonth <= currentMonth)) {
      return false;
    }

    return true;
  }, []);

  return {
    validatePassword,
    validateEmail,
    validateTextExists,
    validatePostalCode,
    validatePhoneNumber,
    validateExpiryDate,
    validateCalculatedExpiryDate,
  };
};

export default useValidate;
