import React from 'react';
import WhatsNewCardGroup, { WhatsNewCardsProps } from 'components/commercetools-ui/organisms/home/whats-new-card';
import { Product } from 'shared/types/product/Product';
import { Tastic } from 'types/tastic';
import { FEATURE_FLAG_LIST, useFeatureFlags } from 'hooks';

const WhatsNewCardGroupTastic = ({ data }: Tastic<{ items: Product[] }, WhatsNewCardsProps>) => {
  const { isFeatureEnabled } = useFeatureFlags();
  return isFeatureEnabled(FEATURE_FLAG_LIST.HOME_PAGE) ? <WhatsNewCardGroup {...data} /> : null;
};

export default WhatsNewCardGroupTastic;
