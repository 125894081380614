import React from 'react';
import { Price } from '@lululemon/ecom-pattern-library';
import { LineItem } from 'shared/types/ngcCart';
import Image from 'frontastic/lib/image';
import styles from './cartSummaryLineItem.module.scss';

type CartSummaryLineItemProps = {
  lineItem: LineItem;
};
const CartSummaryLineItem: React.FC<CartSummaryLineItemProps> = ({ lineItem }) => {
  const listPrice = lineItem?.lineItemTotalPrice?.listPrice;
  const salePrice = lineItem.lineItemTotalPrice?.salePrice;
  return (
    <div className={styles.cartLineItemContainer} data-testid="cartLineItemContainer__content_test-id">
      <div className={styles.cartItemImage} data-testid="cartItem__image_test-id">
        <Image layout="fill" src={lineItem.images} objectFit="contain" />
      </div>
      <div className={styles.cartItemProductDetails} data-testid="cartItem__ProductDetails_test-id">
        <span className={styles.cartItemProductName}>{lineItem.productName}</span>
        <span>{lineItem.colorDescription}</span>
        <span>{`Size ${lineItem.size || '-'}`}</span>
        <div className={styles.cartQtyPriceRow}>
          <span>{`Quantity ${lineItem.quantity || '-'}`}</span>
          <Price
            prices={listPrice}
            salePrices={salePrice}
            showDecimals
            classes={{
              prices: styles.listPrice,
              salePrices: styles.prices,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CartSummaryLineItem;
