import { Account } from 'shared/types/account';

const categoryQueryParams = (
  account: Account | undefined,
  limitStep: number,
  params: URLSearchParams | null = null,
) => {
  const queryParams = params || new URLSearchParams();
  queryParams.append('limit', limitStep.toString());
  const { primaryInventoryChannelId, priceChannelId } = account?.companyProfile || {};
  if (primaryInventoryChannelId && priceChannelId) {
    queryParams.append('pic', primaryInventoryChannelId);
    queryParams.append('prc', priceChannelId);
  }

  return queryParams || `limit=${limitStep}`;
};

export default categoryQueryParams;
