import { COMPONENT_TYPE } from 'helpers/constants/analyticsEventType';

export const HEADER = {
  LULU_LOGO_CLICKED: {
    eventSubType: 'interaction',
    component: {
      id: 'lulu_logo',
      type: COMPONENT_TYPE.LINK,
      text: `Lulu logo clicked`,
    },
  },
  TOP_NAV_LOGO_CLICK: {
    eventSubType: 'interaction',
    component: {
      id: 'top_nav_logo',
      type: COMPONENT_TYPE.LOGO,
      text: '',
    },
  },
  PRIMARY_NAV_ITEM_CLICKED: (text: string | undefined) => {
    return {
      eventSubType: 'interaction',
      component: {
        id: 'header_nav_click',
        type: COMPONENT_TYPE.LINK,
        text: text,
      },
    };
  },

  HEADER_SUB_NAV_CLICK: (text: string | undefined) => {
    return {
      eventSubType: 'interaction',
      component: {
        id: 'header_sub_nav_click',
        type: COMPONENT_TYPE.LINK,
        text: `${text}`,
      },
    };
  },
  MY_BAG_ICON_CLICK: {
    eventSubType: 'interaction',
    component: {
      id: 'my_bag_icon',
      type: COMPONENT_TYPE.ICON,
      text: '',
    },
  },
  SEARCH: (searchValue: string) => {
    return {
      eventSubType: 'interaction',
      component: {
        id: 'search_executed',
        type: COMPONENT_TYPE.SEARCH_QUERY,
        text: `${searchValue}`,
      },
    };
  },
};
