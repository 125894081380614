import React, { FC, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { DesktopNavProps, HeaderProps } from 'shared/types/nav';
import { useNavContext } from 'context/nav';
import { useFormat } from 'helpers/hooks/useFormat';
import { HEADER } from 'helpers/utils/googleAnalyticsEvents';
import { useAnalytics } from 'hooks/useAnalytics';
import useNavData from 'hooks/useNavData';
import { useAccount } from 'frontastic';
import DetailedNav from './detailed-nav';
import styles from './mega-nav.module.scss';
import PrimaryNav from './primary-nav';
import SecondaryNav from './secondary-nav';
import SiteLogo from './site-logo';
import TopNavDesktopBar from './top-nav-desktop';

export const DesktopNav: FC<DesktopNavProps> = ({ categories, navData }) => {
  const [isSticky, setIsSticky] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const { trackEvent, EVENT_CATEGORY } = useAnalytics();

  useEffect(() => {
    const handleScroll = () => {
      if (!headerRef.current) return;
      setIsSticky(window.scrollY > headerRef.current?.offsetTop);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isSticky]);

  const handleLogoClick = () => {
    trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, HEADER.TOP_NAV_LOGO_CLICK);
  };

  return (
    <>
      <div className={classnames(styles.topNavWrapperDesktop)}>
        <TopNavDesktopBar />
      </div>
      <div id={isSticky ? 'fixedHeader' : ''} ref={headerRef} className={classnames({ [styles.isSticky]: isSticky })}>
        <div className={classnames(styles.navContainerDesktop)}>
          <div className={classnames(styles.navWrapperDesktop)}>
            <div className={classnames(styles.logo)}>
              <SiteLogo logoLink="/" onClick={handleLogoClick} />
            </div>
            <PrimaryNav navData={navData} />
            <SecondaryNav categories={categories} />
          </div>
        </div>
        <DetailedNav />
      </div>
    </>
  );
};

const HeaderContainer: FC<HeaderProps> = ({ categories, studioData }) => {
  const navData = useNavData({ categories, studioData });

  const { isDetailedNavOpen } = useNavContext();
  const { isUserLoggedIn } = useAccount();
  const { formatMessage } = useFormat({ name: 'common' });

  useEffect(() => {
    // Set focus to the top of the page
    document?.getElementById('top-of-page')?.focus();
  }, []);

  if (!isUserLoggedIn) {
    return null;
  }

  return (
    <header
      className={classnames(styles.megaNav, {
        [styles.open]: isDetailedNavOpen,
      })}
    >
      <a className={styles.skipNav} href="#main-content">
        {formatMessage({ id: 'skipNavigation', defaultMessage: 'Skip Navigation' })}
      </a>
      <nav className={classnames(styles.mainNav)} aria-label="Main Navigation">
        <DesktopNav categories={categories} navData={navData} />
      </nav>
    </header>
  );
};

export default HeaderContainer;
