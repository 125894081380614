import { COMPONENT_TYPE } from 'helpers/constants/analyticsEventType';
import { toPageProductDisplayedProduct } from 'helpers/mappers/toProductCollectionList';

const getSortLabels = (key: string) => {
  switch (key) {
    case 'name:desc':
      return 'Alphabetical (Z-A)';
    case 'createdAt:desc':
      return 'New Arrivals';
    default:
      return 'Alphabetical (A-Z)';
  }
};

export const CDP_ANALYTIC_EVENT = {
  CDP_PAGE_VIEW: (cdpData: any, attributes: any) => {
    return {
      screen: {
        attributes: {
          gender: attributes.gender ?? '',
          pageType: 'cdp',
          primaryCategory: attributes.primaryCategory ?? '',
          subCategory1: attributes.subCategory1 ?? '',
          categoryName: attributes.categoryName ?? '',
        },
        collections: [''],
        header: {
          unified: '',
        },
      },
      collectionList: toPageProductDisplayedProduct(cdpData, 'product-list', COMPONENT_TYPE.PRODUCT_DETAILS),
    };
  },

  CDP_PAGE_VIEW_WITH_FILTER_SORT: (
    cdpData: any,
    attributes: any,
    filterData: any,
    sort: { attribute?: string; value?: string },
  ) => {
    return {
      screen: {
        attributes: {
          gender: attributes.gender ?? '',
          pageType: 'cdp',
          primaryCategory: attributes.primaryCategory ?? '',
          subCategory1: attributes.subCategory1 ?? '',
          categoryName: attributes.categoryName ?? '',
          sortBy: getSortLabels(`${sort.attribute}:${sort.value}`),
          filter: {
            appliedFilters: filterData ?? '',
          },
        },
        collections: [''],
        header: {
          unified: '',
        },
      },
      collectionList: toPageProductDisplayedProduct(cdpData, 'product-list', COMPONENT_TYPE.PRODUCT_DETAILS),
    };
  },

  FILTER_PILL_CLICKED: (key: string, label: string) => {
    return {
      component: {
        id: 'filter_pill',
        type: COMPONENT_TYPE.BUTTON,
        text: ` Filter pill is clicked/removed`,
      },
      attributes: {
        details: { data: { key, label } },
      },
    };
  },
  LOAD_MORE_BUTTON_CLICK: {
    component: {
      id: 'load_more_button',
      type: COMPONENT_TYPE.BUTTON,
      text: 'Load More',
    },
  },

  CLEAR_FILTERS_CLICKED: {
    component: {
      id: 'clear_filters',
      type: COMPONENT_TYPE.BUTTON,
      text: 'Clear filters is clicked',
    },
  },
  PRODUCT_TILE_CLICKED: (data: any, productText: string) => {
    return {
      component: {
        id: 'product_tile',
        type: COMPONENT_TYPE.INVENTORY_GRID,
        text: productText,
      },
      collectionList: data,
    };
  },

  PRODUCT_SWATCH_CLICKED: (data: any, productString: string) => {
    return {
      component: {
        id: 'product_swatch',
        type: COMPONENT_TYPE.INVENTORY_GRID,
        text: productString,
      },
      collectionList: data,
    };
  },

  PRODUCT_VIEWED: (data: any) => {
    return {
      component: {
        id: 'product_viewed',
        type: COMPONENT_TYPE.BUTTON,
        text: `Product viewed`,
      },
      attributes: {
        details: data,
      },
    };
  },

  PRODUCT_CLICKED: (data: any) => {
    return {
      component: {
        id: 'product',
        type: COMPONENT_TYPE.BUTTON,
        text: `Product clicked`,
      },
      attributes: {
        details: data,
      },
    };
  },

  INVENTORY_GRID_INTERACTION: (data: any) => {
    return {
      component: {
        id: 'inventory_grid_interaction',
        type: COMPONENT_TYPE.BUTTON,
        text: `Inventory grid interaction`,
      },
      attributes: {
        details: data,
      },
    };
  },

  ADD_TO_BAG_API_RESPONSE: {
    event: {
      id: 'add_to_bag_api_response',
      attributes: {
        details: 'Add to bag call success response',
      },
    },
  },

  ADD_TO_BAG_API_ERROR: (errorDetails: any) => {
    return {
      error: {
        errorType: 'remove_line_item_api_error',
        errorMessage: 'Error in calling add to bag API',
        errorDetails,
      },
    };
  },
};
