import React, { useEffect, FC, useMemo, useCallback } from 'react';
import NextLink from 'next/link';
import { LoadingIndicator, Link } from '@lululemon/ecom-pattern-library';
import { Address } from 'shared/types/account';
import Combobox, { Option } from 'components/commercetools-ui/atoms/combobox';
import { useCart } from 'context';
import { useFormat } from 'helpers/hooks/useFormat';
import { getFormattedAddress, formatAddressDisplay } from 'helpers/utils/formatAddress';
import { useProjectSettings } from 'hooks/useProjectSettings';
import { useBusinessUnit } from 'frontastic/hooks';
import styles from './shipping-address.module.scss';
import { CheckoutError } from '../../../index';
import CheckoutNotification from '../../checkoutNotification';

export interface ShippingAddressProps {
  onChangeHandler: (addressId: string, isRetry?: boolean, initialLoad?: boolean) => void;
  isLoading: boolean;
  setSelectedShippingAddress: (address: Address) => void;
  shippingAddressError: CheckoutError;
  setShippingAddressError: (shippingAddressError: CheckoutError) => void;
}

const ShippingAddress: FC<ShippingAddressProps> = ({
  onChangeHandler,
  isLoading,
  setSelectedShippingAddress,
  shippingAddressError,
  setShippingAddressError,
}) => {
  const { formatMessage } = useFormat({ name: 'checkout' });
  const { formatMessage: formatMessageAccount } = useFormat({ name: 'checkout' });
  const { shippingAddresses, defaultShippingAddress } = useBusinessUnit();
  const { cart } = useCart();
  const { customerSupportEmail } = useProjectSettings();

  const initialError = {
    isError: true,
    callBack: null,
    showTryAgain: false,
  };

  useEffect(() => {
    if (shippingAddresses && shippingAddresses.length > 0) {
      findSelectedShippingAddress();
    }
  }, [shippingAddresses]);

  const findSelectedShippingAddress = () => {
    if (cart?.shippingAddress?.addressId) {
      const selectedAddress = shippingAddresses.filter((item) => item.addressId === cart.shippingAddress?.addressId)[0];
      setSelectedShippingAddress(selectedAddress);
      selectedAddress.addressId && onChangeHandler(selectedAddress.addressId, false, true);
    } else if (defaultShippingAddress !== undefined) {
      setSelectedShippingAddress(defaultShippingAddress);
      defaultShippingAddress.addressId && onChangeHandler(defaultShippingAddress.addressId);
    } else if (!Boolean(defaultShippingAddress) && shippingAddresses.length > 0) {
      setSelectedShippingAddress(shippingAddresses[0]);
      shippingAddresses[0].addressId && onChangeHandler(shippingAddresses[0].addressId);
    } else {
      setShippingAddressError(initialError);
    }
  };

  const options: Option[] = useMemo(() => {
    return shippingAddresses.reduce((finalArray: any, address: Address) => {
      const optionObj: Option = {
        value: address?.addressId || '',
        label: formatAddressDisplay(address),
        isDefaultValue: address.isDefaultShippingAddress || false,
        customText: () => {
          return getFormattedAddress(address);
        },
      };
      return [...finalArray, optionObj];
    }, []);
  }, [shippingAddresses]);

  const searchHandler = (searchTxt: string): Option[] => {
    return options.filter((address) => {
      return address.label.toLowerCase().includes(searchTxt.toLowerCase());
    });
  };
  const accountManagerEmail = (
    <Link tag="div" className="nav-link" data-testid="ar__account-manager-email_test-id" direction="right">
      <NextLink href={`mailto:${customerSupportEmail}`}>
        <span className="underline">{customerSupportEmail}.</span>
      </NextLink>
    </Link>
  );

  const getErrorMessage = useCallback(() => {
    const errorText = formatMessage({
      id: 'shipping.address.error',
      defaultMessage: 'We were unable to load your shipping addresses.',
    });
    return (
      <span>
        {errorText}
        {!shippingAddressError.showTryAgain ? (
          <>
            {' '}
            {formatMessage({
              id: 'contact.account.manager',
              defaultMessage: 'Please contact your Account Manager directly, or email ',
            })}
            {accountManagerEmail}
          </>
        ) : null}
      </span>
    );
  }, [shippingAddressError]);

  return (
    <div className={styles.shippingAddressContainer} data-testid="shipping-address__container_test-id">
      <div className={styles.title}>
        {formatMessage({
          id: 'shippingInformation',
          defaultMessage: 'Shipping Information',
        })}
      </div>
      <div className={styles.subtext}>
        <span className="lll-text-body-2 leading-tight">
          {formatMessage({
            id: 'contact.helpTxt',
            defaultMessage:
              'If you need to update a shipping address, contact your Account Manager directly, or email ',
          })}
        </span>
        {accountManagerEmail}
      </div>

      <div className={styles.shippingAddressContentSection}>
        {isLoading ? (
          <div className={styles.loader}>
            <LoadingIndicator color="red" />
          </div>
        ) : (
          (shippingAddressError.isError && (
            <CheckoutNotification
              message={getErrorMessage()}
              show={shippingAddressError.isError}
              showTryAgain={shippingAddressError.showTryAgain}
              tryAgainCallback={shippingAddressError.callBack}
            />
          )) || (
            <>
              {shippingAddresses && shippingAddresses.length > 0 && (
                <Combobox
                  options={options}
                  name="shipping-addresses"
                  onChange={(option: Option) => onChangeHandler(option.value)}
                  initialSelectedItem={
                    {
                      value: cart?.shippingAddress?.addressId || '',
                      label: cart?.shippingAddress && formatAddressDisplay(cart?.shippingAddress),
                      isDefaultValue: cart?.shippingAddress?.addressId === defaultShippingAddress?.addressId,
                    } as Option
                  }
                  label={formatMessage({ id: 'select.shipping.address', defaultMessage: 'Select shipping address' })}
                  searchHandler={searchHandler}
                  data-testid="shipping-address__combo-box_test-id"
                  noResultsFoundContent={
                    <>
                      <div>{formatMessage({ id: 'no.address.found', defaultMessage: 'No address found.' })}</div>
                      <div>
                        {formatMessage({
                          id: 'search.subtext',
                          defaultMessage: 'Try searching with different keywords.',
                        })}
                      </div>
                    </>
                  }
                />
              )}
            </>
          )
        )}
      </div>
    </div>
  );
};

export default ShippingAddress;
