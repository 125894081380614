export enum Country {
  CA = 'ca',
  US = 'us',
}

export enum CanadaState {
  BC = 'BC',
  SK = 'SK',
  MB = 'MB',
  QC = 'QC',
}
