import { COMPONENT_TYPE } from 'helpers/constants/analyticsEventType';

export const ACCOUNT = {
  EDIT_PHONE_NUMBER_SAVE_CLICKED: {
    eventSubType: 'interaction',
    component: {
      id: 'account_edit_phone_number_save',
      type: COMPONENT_TYPE.BUTTON,
      text: `Edit phone number save clicked`,
    },
  },

  CHANGE_PASSWORD_CANCEL_CLICKED: {
    eventSubType: 'interaction',
    component: {
      id: 'account_change_password_cancel',
      type: COMPONENT_TYPE.BUTTON,
      text: `Change password cancel button clicked`,
    },
  },
  EDIT_PHONE_NUMBER_CLICKED: {
    eventSubType: 'interaction',
    component: {
      id: 'account_edit_phone_number',
      type: COMPONENT_TYPE.BUTTON,
      text: `Edit phone number clicked`,
    },
  },
  EDIT_PHONE_NUMBER_CANCEL_CLICKED: {
    eventSubType: 'interaction',
    component: {
      id: 'account_edit_phone_number_cancel',
      type: COMPONENT_TYPE.BUTTON,
      text: `Edit phone number cancel button clicked`,
    },
  },
  EDIT_PASSWORD_LINK_CLICKED: {
    eventSubType: 'interaction',
    component: {
      id: 'account_edit_password_link',
      type: COMPONENT_TYPE.LINK,
      text: `Edit password link clicked`,
    },
  },
  EDIT_PHONE_NUMBER_LINK_CLICKED: {
    eventSubType: 'interaction',
    component: {
      id: 'account_edit_phone_number_link',
      type: COMPONENT_TYPE.LINK,
      text: `Edit phone number link clicked`,
    },
  },
  ACCOUNT_RECEIVABLE_CHANGED_CLICKED: {
    eventSubType: 'interaction',
    component: {
      id: 'account_account_receivable_changed',
      type: COMPONENT_TYPE.RADIO_BUTTON,
      text: `Account receivable changed clicked`,
    },
  },
  MANAGER_EMAIL_LINK_CLICKED: {
    eventSubType: 'interaction',
    component: {
      id: 'account_manager_email_link',
      type: COMPONENT_TYPE.LINK,
      text: `Account manager email link clicked`,
    },
  },
  EDIT_CREDIT_CARD_CLICKED: {
    eventSubType: 'interaction',
    component: {
      id: 'account_edit_credit_card',
      type: COMPONENT_TYPE.BUTTON,
      text: `Edit credit card button clicked`,
    },
  },
  ADD_NEW_CREDIT_CARD_CLICKED: {
    eventSubType: 'interaction',
    component: {
      id: 'account_add_new_credit_card',
      type: COMPONENT_TYPE.BUTTON,
      text: `Add new credit card button clicked`,
    },
  },
  BILLING_INFORMATION_TAB_CLICKED: (paymentType: string) => {
    return {
      eventSubType: 'interaction',
      component: {
        id: `account_billing_information_tab`,
        type: COMPONENT_TYPE.TAB,
        text: `Payment type tab clicked`,
      },
      attributes: {
        details: { data: { paymentType } },
      },
    };
  },
  ADD_CREDIT_CARD_CANCEL_CLICKED: {
    eventSubType: 'interaction',
    component: {
      id: 'account_Add_credit_card_cancel',
      type: COMPONENT_TYPE.BUTTON,
      text: `Add credit card cancel button clicked`,
    },
  },
  SAVE_CREDIT_CARD_CLICKED: {
    eventSubType: 'interaction',
    component: {
      id: 'account_save_credit_card',
      type: COMPONENT_TYPE.BUTTON,
      text: `Save credit card button clicked`,
    },
  },
  CREDIT_CARD_ENABLED_CHECKED_CLICKED: {
    eventSubType: 'interaction',
    component: {
      id: 'account_credit_card_enabled_checked',
      type: COMPONENT_TYPE.CHECKBOX,
      text: `Enabled selected checked`,
    },
  },
  SELECT_DEFAULT_CREDIT_CARD_CLICKED: {
    eventSubType: 'interaction',
    component: {
      id: 'account_select_default_credit_card',
      type: COMPONENT_TYPE.CHECKBOX,
      text: `Select default credit card button clicked`,
    },
  },
  REMOVE_CREDIT_CARD_CLICKED: {
    eventSubType: 'interaction',
    component: {
      id: 'account_remove_credit_card',
      type: COMPONENT_TYPE.BUTTON,
      text: `Remove credit card button clicked`,
    },
  },
  ACCOUNT_MENU_ICON_CLICK: (linkText: string) => {
    return {
      eventSubType: 'interaction',
      component: {
        id: 'account_menu_icon',
        type: COMPONENT_TYPE.ICON,
        text: `${linkText}`,
      },
    };
  },
  ACCOUNT_MENU_LINK_CLICK: (linkText: string) => {
    return {
      eventSubType: 'interaction',
      component: {
        id: 'account_menu_link',
        type: COMPONENT_TYPE.LINK,
        text: `${linkText}`,
      },
    };
  },
  FOOTER_MENU_ICON_CLICK: (linkText: string) => {
    return {
      eventSubType: 'interaction',
      component: {
        id: 'footer_menu_icon',
        type: COMPONENT_TYPE.ICON,
        text: `${linkText}`,
      },
    };
  },
  ACCOUNT_MENU_SIGN_OUT_CLICK: {
    eventSubType: 'interaction',
    event: {
      id: 'account_menu_sign_out',
      attributes: {
        details: 'Api call response',
      },
    },
  },
  FOOTER_MENU_SIGN_OUT_CLICK: {
    event: {
      id: 'footer_sign_out',
      attributes: {
        details: 'Api call response',
      },
    },
  },
  GET_USER_LIST_API_ERROR: (errorDetails: any) => {
    return {
      error: { errorType: 'get_user_list_api_error', errorMessage: 'Error in calling get_ ser list API', errorDetails },
    };
  },
  UPDATE_PHONE_NUMBER_API_ERROR: (errorDetails: any) => {
    return {
      error: {
        errorType: 'update_phone_number_api_error',
        errorMessage: 'Error in calling update phone number API',
        errorDetails,
      },
    };
  },

  CHANGE_PASSWORD_API_ERROR: (errorDetails: any) => {
    return {
      error: {
        errorType: 'change_password_api_error',
        errorMessage: 'Error in calling change password API',
        errorDetails,
      },
    };
  },
};
