import { useCallback } from 'react';
import {
  paymentMethodAmexIcon,
  paymentMethodMastercardIcon,
  paymentMethodDiscoverIcon,
  paymentMethodJcbIcon,
  paymentMethodVisaIcon,
  creditDebitCardIcon,
} from '@lululemon/ecom-pattern-library';
import ccType from 'credit-card-type';

const useResolveCCImage = () => {
  const resolveCCImage = useCallback((number: string) => {
    const types = ccType(number);

    if (!number || types.length == 0) return '';

    return (
      {
        visa: paymentMethodVisaIcon,
        mastercard: paymentMethodMastercardIcon,
        'american-express': paymentMethodAmexIcon,
        jcb: paymentMethodJcbIcon,
        discover: paymentMethodDiscoverIcon,
        maestro: paymentMethodMastercardIcon,
      }[types[0].type] ?? creditDebitCardIcon
    );
  }, []);

  const resolveCCImageFromBrandName = useCallback((cardType: string) => {
    const cardImg: any = {
      visa: paymentMethodVisaIcon,
      mastercard: paymentMethodMastercardIcon,
      amex: paymentMethodAmexIcon,
      jcb: paymentMethodJcbIcon,
      discover: paymentMethodDiscoverIcon,
      maestro: paymentMethodMastercardIcon,
      americanexpress: paymentMethodAmexIcon,
    };

    return cardImg[cardType] ? cardImg[cardType] : creditDebitCardIcon;
  }, []);

  const getCCDetails = useCallback((number: string) => {
    if (!number) return null;

    const types = ccType(number);
    return types.length ? { ...types[0], image: resolveCCImage(number) } : null;
  }, []);

  return {
    resolveCCImage,
    resolveCCImageFromBrandName,
    getCCDetails,
  };
};

export default useResolveCCImage;
