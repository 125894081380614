import { COMPONENT_TYPE } from 'helpers/constants/analyticsEventType';

export const MANAGE_ORDER = {
  INVOICE_DOWNLOAD: (order: any) => {
    return {
      component: {
        id: 'invoice_download',
        type: COMPONENT_TYPE.BUTTON,
        text: 'The Invoice Downloaded',
      },
      attributes: {
        details: { data: order },
      },
    };
  },
  EXPORT_ORDER: (order: any) => {
    return {
      component: {
        id: 'export_order',
        type: COMPONENT_TYPE.BUTTON,
        text: 'Export Order',
      },
      attributes: {
        details: { data: order },
      },
    };
  },
  EXPORT_BULK_ORDERS: () => {
    return {
      component: {
        id: 'export_bulk_orders',
        type: COMPONENT_TYPE.BUTTON,
        text: 'Exported Bulk Orders',
      },
    };
  },
};
