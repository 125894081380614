import { useEffect } from 'react';
import HeaderContainer from 'components/commercetools-ui/organisms/lulu-header';
import { MarketProvider } from 'context/market';
import { NavProvider } from 'context/nav';
import { Category } from 'shared/types/product/Category';
import { StudioData } from 'shared/types/nav';
import { useStudioDataContext } from 'context/studioData';

interface Props {
  data: StudioData;
  categories: Category[];
}

const LuluHeaderTastic = ({ data: studioData, categories }: Props) => {
  const { data, setData } = useStudioDataContext();

  useEffect(() => {
    setData({ ...data, navData: studioData });
  }, []);

  return (
    <MarketProvider>
      <NavProvider>
        <div id="header-container">
          <HeaderContainer studioData={studioData} categories={categories} />
        </div>
      </NavProvider>
    </MarketProvider>
  );
};
export default LuluHeaderTastic;
