import { useState, cloneElement } from 'react';
import { renderContentfulComponent, RadioGroup, Tabpanel, Table } from '@lululemon/ecom-pattern-library';
import classNames from 'classnames';
import styles from './size-guide-primary-content.module.scss';
import { getTableSizeGrade, modifyContent } from '../utils';
import { PrimaryContent } from '../types';

interface SizeGuidePrimaryContentProps {
  customStyles?: any;
  primaryContent: PrimaryContent;
  productDisplayName?: string;
  productSizeGrade?: string;
  sizeGuideCategory?: string;
  [key: string]: any;
}

export const SizeGuidePrimaryContent = ({
  customStyles = {},
  primaryContent,
  productDisplayName = '',
  productSizeGrade,
  sizeGuideCategory,
  ...extraProps
}: SizeGuidePrimaryContentProps) => {
  const [activeTabPanelIdx, setActiveTabPanelIdx] = useState<number>(0);

  const handleTabChange = (index: number) => {
    setActiveTabPanelIdx(index);
  };

  if (primaryContent) {
    modifyContent(primaryContent, sizeGuideCategory);
  }

  if (!primaryContent) {
    return null;
  }

  return (
    <section
      className={classNames(styles.primaryContent, 'lll-font-weight-regular', customStyles?.primaryContent)}
      data-testid="size-guide__primary-content-section_test-id"
      {...extraProps}
    >
      {renderContentfulComponent({
        contentfulComponent: primaryContent,
        options: {
          renderContentType: {
            webTabpanel: ({
              data,
              children,
              contentfulComponent,
            }: {
              data: any;
              children: any;
              contentfulComponent: any;
            }) => {
              const panelName = contentfulComponent?.fields?.name || '';
              if (panelName.includes('Redesigned Size Chart Panel')) {
                return (
                  <RadioGroup {...data} activeTabPanelIdx={activeTabPanelIdx}>
                    {children}
                  </RadioGroup>
                );
              }

              return (
                <Tabpanel
                  {...data}
                  className={customStyles.tabPanel || ''}
                  setActive={(index: number) => handleTabChange(index)}
                >
                  {children}
                </Tabpanel>
              );
            },
            webTable: ({
              data: { columnHeading, rows },
              contentfulComponent,
            }: {
              data: { columnHeading: string; rows: any[] };
              contentfulComponent: any;
            }) => {
              const contourFitType = 'Contour Fit';
              const contourFitTypeFr = 'Coupe contour';
              const tableName = contentfulComponent?.fields?.contentfulEntryName || '';
              const isContourFitLeggings =
                productDisplayName.includes(contourFitType) || productDisplayName.includes(contourFitTypeFr);
              const isContourFitTable = tableName.includes(contourFitType);
              const tableStyles = {
                tableCell: customStyles?.tableCell || styles.tableCell,
                tableColHeader: customStyles?.tableColHeader || styles.tableColHeader,
                tableContainer: customStyles?.tableContainer || styles.tableContainer,
                table: customStyles?.table || styles.table,
              };

              if (
                (isContourFitLeggings && isContourFitTable) ||
                productSizeGrade === getTableSizeGrade(tableName) ||
                !productSizeGrade
              ) {
                return <Table classes={tableStyles} columnHeading={columnHeading} rows={rows} />;
              }
              return null;
            },
          },
          transformElement: ({ element, contentType }: { element: any; contentType: any }) => {
            if (contentType === 'link') {
              return cloneElement(element, {
                className: classNames(element.props.className, styles.link),
              });
            }

            if (contentType === 'richTextBody') {
              return cloneElement(element, {
                className: classNames(element.props.className, customStyles?.richTextBody || styles.richTextBody),
              });
            }

            if (contentType === 'heading') {
              return cloneElement(element, {
                className: classNames(element.props.className, 'lll-font-weight-semibold'),
                wrapperClassName: customStyles?.headingContainer,
              });
            }

            return element;
          },
        },
      })}
    </section>
  );
};
