type ReasonCodeMapping = {
  [key: string]: {
    status: string;
    reason: string;
  };
};

export enum CybersourceErrorCode {
  MISSING_FIELD = 101,
  INVALID_DATA = 102,
  DUPLICATE_REQUEST = 104,
  PARTIAL_APPROVAL = 110,
  SYSTEM_ERROR = 150,
  SERVER_TIMEOUT = 151,
  ISSUER_UNAVAILABLE = 207,
  PROCESSOR_UNAVAILABLE = 236,
  PROCESSOR_TIMEOUT = 250,
  CONTACT_PROCESSOR = 201,
  PROCESSOR_DECLINED = 203,
  INSUFFICIENT_FUNDS = 204,
  STOLEN_LOST_CARD = 205,
  UNAUTHORIZED_CARD = 208,
  EXCEEDS_CREDIT_LIMIT = 210,
  DECLINED_CHECK = 220,
  BLACKLISTED_CUSTOMER = 221,
  SUSPENDED_ACCOUNT = 222,
  GENERAL_DECLINE = 233,
  INVALID_MERCHANT_CONFIGURATION = 234,
  DECISION_PROFILE_REJECT = 481,
  AVS_FAILED = 200,
  EXPIRED_CARD = 202,
  CVN_NOT_MATCH = 209,
  INVALID_CVN = 211,
  CV_FAILED = 230,
  INVALID_ACCOUNT = 231,
  CARD_TYPE_NOT_ACCEPTED = 232,
  EXCEEDS_AUTH_AMOUNT = 235,
  AUTH_ALREADY_REVERSED = 237,
  TRANSACTION_ALREADY_SETTLED = 238,
  INVALID_AMOUNT = 239,
  INVALID_CARD_TYPE = 240,
  INVALID_PAYMENT_ID = 241,
  MISSING_AUTH = 242,
  TRANSACTION_ALREADY_REVERSED_OR_SETTLED = 243,
  NOT_VOIDABLE = 246,
  CAPTURE_ALREADY_VOIDED = 247,
  BOLETO_DECLINED = 248,
  DEBIT_CARD_USAGE_LIMIT_EXCEEDED = 251,
  ACCOUNT_NOT_ALLOWED_CREDIT = 254,
  SCORE_EXCEEDS_THRESHOLD = 400,
  APARTMENT_NUMBER_NOT_FOUND = 450,
  INSUFFICIENT_ADDRESS_INFORMATION = 451,
  HOUSE_OR_BOX_NUMBER_NOT_FOUND = 452,
  MULTIPLE_ADDRESS_MATCHES = 453,
  BOX_NUMBER_NOT_FOUND = 454,
  ROUTE_SERVICE_NOT_FOUND = 455,
  STREET_NAME_NOT_FOUND = 456,
  POSTAL_CODE_NOT_FOUND = 457,
  UNVERIFIABLE_ADDRESS = 458,
  MULTIPLE_ADDRESS_MATCHES_INTERNATIONAL = 459,
  ADDRESS_MATCH_NOT_FOUND = 460,
  UNSUPPORTED_CHARACTER_SET = 461,
  CONSUMER_AUTHENTICATION_REQUIRED = 475,
  CONSUMER_AUTHENTICATION_FAILED = 476,
  CUSTOMER_AUTHENTICATION_REQUIRED = 478,
  DECISION_PROFILE_REVIEW = 480,
  RISK_CONTROL_DECLINE = 490,
  CUSTOMER_WATCHLIST_MATCH = 700,
  ADDRESS_COUNTRY_WATCHLIST_MATCH = 701,
  EMAIL_COUNTRY_WATCHLIST_MATCH = 702,
  IP_COUNTRY_WATCHLIST_MATCH = 703,
}

export const reasonCodeMap: ReasonCodeMapping = {
  [CybersourceErrorCode.MISSING_FIELD]: {
    status: 'INVALID_REQUEST',
    reason: 'MISSING_FIELD',
  },
  [CybersourceErrorCode.INVALID_DATA]: {
    status: 'INVALID_REQUEST',
    reason: 'INVALID_DATA',
  },
  [CybersourceErrorCode.DUPLICATE_REQUEST]: {
    status: 'INVALID_REQUEST',
    reason: 'DUPLICATE_REQUEST',
  },
  [CybersourceErrorCode.PARTIAL_APPROVAL]: {
    status: 'PARTIAL_AUTHORIZED',
    reason: 'N/A',
  },
  [CybersourceErrorCode.SYSTEM_ERROR]: {
    status: 'SERVER_ERROR',
    reason: 'SYSTEM_ERROR',
  },
  [CybersourceErrorCode.SERVER_TIMEOUT]: {
    status: 'SERVER_ERROR',
    reason: 'SERVER_TIMEOUT',
  },
  [CybersourceErrorCode.AVS_FAILED]: {
    status: 'AUTHORIZED_PENDING_REVIEW',
    reason: 'AVS_FAILED',
  },
  [CybersourceErrorCode.CONTACT_PROCESSOR]: {
    status: 'AUTHORIZED_PENDING_REVIEW',
    reason: 'CONTACT_PROCESSOR',
  },
  [CybersourceErrorCode.EXPIRED_CARD]: {
    status: 'DECLINED',
    reason: 'EXPIRED_CARD',
  },
  [CybersourceErrorCode.PROCESSOR_DECLINED]: {
    status: 'DECLINED',
    reason: 'PROCESSOR_DECLINED',
  },
  [CybersourceErrorCode.INSUFFICIENT_FUNDS]: {
    status: 'DECLINED',
    reason: 'INSUFFICIENT_FUND',
  },
  [CybersourceErrorCode.STOLEN_LOST_CARD]: {
    status: 'DECLINED',
    reason: 'STOLEN_LOST_CARD',
  },
  [CybersourceErrorCode.ISSUER_UNAVAILABLE]: {
    status: 'DECLINED',
    reason: 'ISSUER_UNAVAILABLE',
  },
  [CybersourceErrorCode.UNAUTHORIZED_CARD]: {
    status: 'DECLINED',
    reason: 'UNAUTHORIZED_CARD',
  },
  [CybersourceErrorCode.CVN_NOT_MATCH]: {
    status: 'DECLINED',
    reason: 'CVN_NOT_MATCH',
  },
  [CybersourceErrorCode.EXCEEDS_CREDIT_LIMIT]: {
    status: 'DECLINED',
    reason: 'EXCEEDS_CREDIT_LIMIT',
  },
  [CybersourceErrorCode.INVALID_CVN]: {
    status: 'DECLINED',
    reason: 'INVALID_CVN',
  },
  [CybersourceErrorCode.DECLINED_CHECK]: {
    status: 'DECLINED',
    reason: 'DECLINED_CHECK',
  },
  [CybersourceErrorCode.BLACKLISTED_CUSTOMER]: {
    status: 'DECLINED',
    reason: 'BLACKLISTED_CUSTOMER',
  },
  [CybersourceErrorCode.SUSPENDED_ACCOUNT]: {
    status: 'DECLINED',
    reason: 'SUSPENDED_ACCOUNT',
  },
  [CybersourceErrorCode.CV_FAILED]: {
    status: 'AUTHORIZED_PENDING_REVIEW',
    reason: 'CV_FAILED',
  },
  [CybersourceErrorCode.INVALID_ACCOUNT]: {
    status: 'DECLINED',
    reason: 'INVALID_ACCOUNT',
  },
  [CybersourceErrorCode.CARD_TYPE_NOT_ACCEPTED]: {
    status: 'INVALID_REQUEST',
    reason: 'CARD_TYPE_NOT_ACCEPTED',
  },
  [CybersourceErrorCode.GENERAL_DECLINE]: {
    status: 'DECLINED',
    reason: 'GENERAL_DECLINE',
  },
  [CybersourceErrorCode.INVALID_MERCHANT_CONFIGURATION]: {
    status: 'INVALID_REQUEST',
    reason: 'INVALID_MERCHANT_CONFIGURATION',
  },
  [CybersourceErrorCode.EXCEEDS_AUTH_AMOUNT]: {
    status: 'INVALID_REQUEST',
    reason: 'EXCEEDS_AUTH_AMOUNT',
  },
  [CybersourceErrorCode.PROCESSOR_UNAVAILABLE]: {
    status: 'INVALID_REQUEST',
    reason: 'PROCESSOR_UNAVAILABLE',
  },
  [CybersourceErrorCode.AUTH_ALREADY_REVERSED]: {
    status: 'INVALID_REQUEST',
    reason: 'AUTH_ALREADY_REVERSED',
  },
  [CybersourceErrorCode.TRANSACTION_ALREADY_SETTLED]: {
    status: 'INVALID_REQUEST',
    reason: 'TRANSACTION_ALREADY_SETTLED',
  },
  [CybersourceErrorCode.INVALID_AMOUNT]: {
    status: 'INVALID_REQUEST',
    reason: 'INVALID_AMOUNT',
  },
  [CybersourceErrorCode.INVALID_CARD_TYPE]: {
    status: 'INVALID_REQUEST',
    reason: 'INVALID_CARD_TYPE',
  },
  [CybersourceErrorCode.INVALID_PAYMENT_ID]: {
    status: 'INVALID_REQUEST',
    reason: 'INVALID_PAYMENT_ID',
  },
  [CybersourceErrorCode.MISSING_AUTH]: {
    status: 'INVALID_REQUEST',
    reason: 'MISSING_AUTH',
  },
  [CybersourceErrorCode.TRANSACTION_ALREADY_REVERSED_OR_SETTLED]: {
    status: 'INVALID_REQUEST',
    reason: 'TRANSACTION_ALREADY_REVERSED_OR_SETTLED',
  },
  [CybersourceErrorCode.NOT_VOIDABLE]: {
    status: 'INVALID_REQUEST',
    reason: 'NOT_VOIDABLE',
  },
  [CybersourceErrorCode.CAPTURE_ALREADY_VOIDED]: {
    status: 'INVALID_REQUEST',
    reason: 'CAPTURE_ALREADY_VOIDED',
  },
  [CybersourceErrorCode.BOLETO_DECLINED]: {
    status: 'DECLINED',
    reason: 'BOLETO_DECLINED',
  },
  [CybersourceErrorCode.PROCESSOR_TIMEOUT]: {
    status: 'SERVER_ERROR',
    reason: 'PROCESSOR_TIMEOUT',
  },
  [CybersourceErrorCode.DEBIT_CARD_USAGE_LIMIT_EXCEEDED]: {
    status: 'DECLINED',
    reason: 'DEBIT_CARD_USAGE_LIMIT_EXCEEDED',
  },
  [CybersourceErrorCode.ACCOUNT_NOT_ALLOWED_CREDIT]: {
    status: 'INVALID_REQUEST',
    reason: 'ACCOUNT_NOT_ALLOWED_CREDIT',
  },
  [CybersourceErrorCode.SCORE_EXCEEDS_THRESHOLD]: {
    status: 'REJECTED',
    reason: 'SCORE_EXCEEDS_THRESHOLD',
  },
  [CybersourceErrorCode.APARTMENT_NUMBER_NOT_FOUND]: {
    status: 'DECLINED',
    reason: 'APARTMENT_NUMBER_NOT_FOUND',
  },
  [CybersourceErrorCode.INSUFFICIENT_ADDRESS_INFORMATION]: {
    status: 'DECLINED',
    reason: 'INSUFFICIENT_ADDRESS_INFORMATION',
  },
  [CybersourceErrorCode.HOUSE_OR_BOX_NUMBER_NOT_FOUND]: {
    status: 'DECLINED',
    reason: 'HOUSE_OR_BOX_NUMBER_NOT_FOUND',
  },
  [CybersourceErrorCode.MULTIPLE_ADDRESS_MATCHES]: {
    status: 'DECLINED',
    reason: 'MULTIPLE_ADDRESS_MATCHES',
  },
  [CybersourceErrorCode.BOX_NUMBER_NOT_FOUND]: {
    status: 'DECLINED',
    reason: 'BOX_NUMBER_NOT_FOUND',
  },
  [CybersourceErrorCode.ROUTE_SERVICE_NOT_FOUND]: {
    status: 'DECLINED',
    reason: 'ROUTE_SERVICE_NOT_FOUND',
  },
  [CybersourceErrorCode.STREET_NAME_NOT_FOUND]: {
    status: 'DECLINED',
    reason: 'STREET_NAME_NOT_FOUND',
  },
  [CybersourceErrorCode.POSTAL_CODE_NOT_FOUND]: {
    status: 'DECLINED',
    reason: 'POSTAL_CODE_NOT_FOUND',
  },
  [CybersourceErrorCode.UNVERIFIABLE_ADDRESS]: {
    status: 'DECLINED',
    reason: 'UNVERIFIABLE_ADDRESS',
  },
  [CybersourceErrorCode.MULTIPLE_ADDRESS_MATCHES_INTERNATIONAL]: {
    status: 'DECLINED',
    reason: 'MULTIPLE_ADDRESS_MATCHES_INTERNATIONAL',
  },
  [CybersourceErrorCode.ADDRESS_MATCH_NOT_FOUND]: {
    status: 'DECLINED',
    reason: 'ADDRESS_MATCH_NOT_FOUND',
  },
  [CybersourceErrorCode.UNSUPPORTED_CHARACTER_SET]: {
    status: 'DECLINED',
    reason: 'UNSUPPORTED_CHARACTER_SET',
  },
  [CybersourceErrorCode.CONSUMER_AUTHENTICATION_REQUIRED]: {
    status: 'PENDING_AUTHENTICATION',
    reason: 'CONSUMER_AUTHENTICATION_REQUIRED',
  },
  [CybersourceErrorCode.CONSUMER_AUTHENTICATION_FAILED]: {
    status: 'DECLINED',
    reason: 'CONSUMER_AUTHENTICATION_FAILED',
  },
  [CybersourceErrorCode.CUSTOMER_AUTHENTICATION_REQUIRED]: {
    status: 'DECLINED',
    reason: 'CUSTOMER_AUTHENTICATION_REQUIRED',
  },
  [CybersourceErrorCode.DECISION_PROFILE_REVIEW]: {
    status: 'AUTHORIZED_PENDING_REVIEW',
    reason: 'DECISION_PROFILE_REVIEW',
  },
  [CybersourceErrorCode.DECISION_PROFILE_REJECT]: {
    status: 'AUTHORIZED_RISK_DECLINED',
    reason: 'DECISION_PROFILE_REJECT',
  },
  [CybersourceErrorCode.RISK_CONTROL_DECLINE]: {
    status: 'DECLINED',
    reason: 'RISK_CONTROL_DECLINE',
  },
  [CybersourceErrorCode.CUSTOMER_WATCHLIST_MATCH]: {
    status: 'DECLINED',
    reason: 'CUSTOMER_WATCHLIST_MATCH',
  },
  [CybersourceErrorCode.ADDRESS_COUNTRY_WATCHLIST_MATCH]: {
    status: 'DECLINED',
    reason: 'ADDRESS_COUNTRY_WATCHLIST_MATCH',
  },
  [CybersourceErrorCode.EMAIL_COUNTRY_WATCHLIST_MATCH]: {
    status: 'DECLINED',
    reason: 'EMAIL_COUNTRY_WATCHLIST_MATCH',
  },
  [CybersourceErrorCode.IP_COUNTRY_WATCHLIST_MATCH]: {
    status: 'DECLINED',
    reason: 'IP_COUNTRY_WATCHLIST_MATCH',
  },
  '152': {
    status: 'SERVER_ERROR',
    reason: 'SERVER_TIMEOUT',
  },
  '491': {
    status: 'DECLINED',
    reason: 'RISK_CONTROL_DECLINE',
  },
  '520': {
    status: 'N/A',
    reason: 'N/A',
  },
};

type ReverseMapping = {
  [key: string]: string;
};

export const createReverseMapping = (mapping: ReasonCodeMapping): ReverseMapping => {
  const reverseMapping: ReverseMapping = {};
  for (const [code, details] of Object.entries(mapping)) {
    const fullKey = `${details.status} | ${details.reason}`;
    const reasonKey = details.reason;
    reverseMapping[fullKey] = code;
    reverseMapping[reasonKey] = code;
  }

  return reverseMapping;
};

const reverseReasonCodeMap = createReverseMapping(reasonCodeMap);

export const findReasonCode = (errorKey: string): number | null => {
  return Number.parseInt(reverseReasonCodeMap[errorKey]);
};
