import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useCart } from 'context';
import { useFormat } from 'helpers/hooks/useFormat';
import { HEADER } from 'helpers/utils/googleAnalyticsEvents/header';
import { FEATURE_FLAG_LIST, useFeatureFlags } from 'hooks';
import { useAnalytics } from 'hooks/useAnalytics';
import { useAccount } from 'frontastic';
import styles from './simple-header.module.scss';
import QuickViewBag from '../lulu-header/quick-view-bag';
import SiteLogo from '../lulu-header/site-logo';

const SimpleHeader = () => {
  const router = useRouter();
  const { isUserLoggedIn } = useAccount();
  const { formatMessage } = useFormat({ name: 'common' });
  const { isFeatureEnabled } = useFeatureFlags();
  const { cart } = useCart();
  const { trackEvent, EVENT_CATEGORY } = useAnalytics();

  useEffect(() => {
    // Set focus to the top of the page
    document?.getElementById('top-of-page')?.focus();
  }, []);

  const isCartPage = router.asPath.startsWith('/cart');
  const showQuickViewBag = !isCartPage && isUserLoggedIn && isFeatureEnabled(FEATURE_FLAG_LIST.CART);

  const handleLogoClick = () => {
    trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, HEADER.TOP_NAV_LOGO_CLICK);
  };

  return (
    <header className={styles.simpleHeaderContainer} data-testid="header__simple-header_test-id">
      <a className={styles.skipNav} href="#main-content">
        {formatMessage({ id: 'skipNavigation', defaultMessage: 'Skip Navigation' })}
      </a>
      <nav className={styles.navWrapper}>
        <SiteLogo logoLink="/" onClick={handleLogoClick} />
        {showQuickViewBag && <QuickViewBag cartCount={cart?.totalQuantity ?? 0} cart={cart} />}
      </nav>
    </header>
  );
};

export default SimpleHeader;
