import { useState, useRef } from 'react';
import { NavItem } from 'shared/types/nav';

const useNav = () => {
  // For category states:
  const [activeCategory, setActiveCategory] = useState<NavItem | null>(null);
  const [isDetailedNavOpen, setIsDetailedNavOpen] = useState(false);

  // For categories accessibility, states:
  const [activeL1Index, setActiveL1Index] = useState<number>(0); // L1 index is used to indicate main level categories which are always shown in header
  const [activeL2Index, setActiveL2Index] = useState<number>(0); // L2 index is used to indicate feature items list's first item and subcategories heading
  const [featuresActivated, setFeaturesActivated] = useState(false);
  const [subcategoriesActivated, setSubcategoriesActivated] = useState(false);
  const [menuLength, setMenuLength] = useState(0);
  const [tabActivated, setTabActivated] = useState(false);

  // for search input states:
  const [isSearchFocused, setSearchFocused] = useState(false);

  // for bag states:
  const [showMyBag, setShowMyBag] = useState(false);

  // For Account popup states:
  const [isAccountButtonFocused, setAccountButtonFocused] = useState(false);
  const [isAccountMenuListFocused, setAccountMenuListFocused] = useState(false);
  const [isAccountMenuVisible, setAccountMenuVisible] = useState(false);

  const navActivable = useRef<boolean>(true);

  const closeAccountPopup = () => {
    setAccountMenuVisible(false);
    setAccountButtonFocused(false); // to unfocus account button
  };

  const handleMouseEnter = (navItem: NavItem, index: number, isFocused = false) => {
    isFocused && setTabActivated(true);
    if (!isFocused) {
      resetAccessibility();
      const activeElement = document?.activeElement;
      if (activeElement instanceof HTMLElement) {
        activeElement.blur();
      }
    }
    if (!navActivable.current) return null;
    closeAccountPopup();
    setActiveL1Index(index);
    if (navItem?.subCategories?.length) {
      setActiveCategory(navItem);
      setIsDetailedNavOpen(true);
    } else {
      setActiveCategory(null);
      setIsDetailedNavOpen(false);
    }
  };

  const handleTabPress = (shiftKey: boolean) => {
    setFeaturesActivated(false);
    setSubcategoriesActivated(false);
    if (!shiftKey) {
      setActiveL1Index(activeL1Index + 1);
    }
  };

  const handleEscapePress = () => {
    setFeaturesActivated(false);
    setSubcategoriesActivated(false);
  };

  const resetAccessibility = () => {
    setActiveL1Index(0);
    setActiveL2Index(0);
    setFeaturesActivated(false);
    setSubcategoriesActivated(false);
    setTabActivated(false);
  };

  const handleCloseDetailedNav = () => {
    setIsDetailedNavOpen(false);
    setActiveCategory(null);

    // unset accessibilities values to initial values
    resetAccessibility();
  };

  return {
    activeCategory,
    handleCloseDetailedNav,
    handleMouseEnter,
    isDetailedNavOpen,
    setActiveCategory,
    activeL1Index,
    activeL2Index,
    setActiveL2Index,
    setActiveL1Index,
    handleTabPress,
    handleEscapePress,
    isAccountButtonFocused,
    setAccountButtonFocused,
    isAccountMenuListFocused,
    setAccountMenuListFocused,
    isAccountMenuVisible,
    setAccountMenuVisible,
    setSearchFocused,
    isSearchFocused,
    setShowMyBag,
    showMyBag,
    featuresActivated,
    setFeaturesActivated,
    subcategoriesActivated,
    setSubcategoriesActivated,
    menuLength,
    setMenuLength,
    navActivable,
    tabActivated,
  };
};

export default useNav;
