import React, { useState, useEffect, FC, ChangeEvent } from 'react';
import { Field, Modal, Heading, Checkbox, Select, Icon, Notification } from '@lululemon/ecom-pattern-library';
import cx from 'classnames';
import useResolveCCImage from 'hooks/useResolveCCImage';
import { OverlayLoader } from 'components/overlayLoader';
import { useFormat } from 'helpers/hooks/useFormat';
import useValidate from 'helpers/hooks/useValidate';
import { getYearOptions, getCurrentMonth, getCurrentYear } from 'helpers/utils/datePicker';
import { formatAddressDisplay } from 'helpers/utils/formatAddress';
import { ACCOUNT } from 'helpers/utils/googleAnalyticsEvents';
import { NgcErrorCodes } from 'helpers/utils/ngcResponseUtil';
import { PartnerIdUUID } from 'helpers/utils/partnerIdUUID';
import { useAnalytics } from 'hooks/useAnalytics';
import { useBusinessUnit, useAccount } from 'frontastic';
import useCybersource from 'frontastic/hooks/useCybersource';
import {
  ContextResponseMapper,
  CreditCardType,
  RequestResponsePaymentSessionMapper,
} from 'frontastic/hooks/useCybersource/types';
import paymentStyle from './payment-add.module.scss';
import styles from './payment-add.module.scss';
import SaveOrCancel from '../../../components/save-or-cancel';

export interface PaymentAddModal {
  toggleModalVisibility: (e: MouseEvent) => void;
  creditCardsList: CreditCardType[];
  updateNotification: (isError: boolean) => void;
}

export interface TransformedBillingInfo {
  label?: string;
  value?: string;
}

const PaymentAdd: FC<PaymentAddModal> = ({ toggleModalVisibility, creditCardsList, updateNotification }) => {
  const { formatMessage: formatPaymentMessage } = useFormat({ name: 'payment' });
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

  const { resolveCCImageFromBrandName } = useResolveCCImage();
  const { validateCalculatedExpiryDate } = useValidate();
  const { generateCaptureContext, saveTokenCC } = useCybersource();
  const { businessUnit, billingAddresses } = useBusinessUnit();
  const { account } = useAccount();
  //Google Analytics
  const { trackEvent, EVENT_CATEGORY } = useAnalytics();

  const [captureContextError, setCaptureContextError] = useState<boolean>(false);
  const [microformObj, setMicroformObj] = useState<any>(null);
  const [isLoading, setLoading] = useState(false);
  const [businessUnitData, setBusinessUnitData] = useState<TransformedBillingInfo[]>([]);

  const [ccType, setCcType] = useState<string>();
  const [isTokenResponseError, setIsTokenResponseError] = useState({
    securityCode: false,
    number: false,
  });
  const [generatedPartnerUUID, setGeneratedPartnerUUID] = useState<string>('');
  const [saveTokenError, setSaveTokenError] = useState({
    isError: false,
    message: '',
  });

  const [isErrored, setIsErrored] = useState({
    cardBillingAddressId: false,
    expirydate: false,
    nameOnCard: false,
  } as any);

  const [displayStatus, setDisplayStatus] = useState({
    cardBillingAddressId: 'warning',
    expirydate: 'warning',
    nameOnCard: 'warning',
  });

  const [formData, setFormData] = useState<CreditCardType>({
    cardBillingAddressId: '',
    isCardPrimary: true,
    cardToken: ' ',
    cardType: '',
    expires: `${getCurrentMonth}/${getCurrentYear}`,
    maskedPan: ' ',
    nameOnCard: '',
    partnerIdUUID: '',
  });

  const [expiryMonth, setExpiryMonth] = useState(getCurrentMonth);
  const [expiryYear, setExpiryYear] = useState(getCurrentYear);

  useEffect(() => {
    const windowObject = window as any;
    const displayMicroFormError = (error: any) => {
      if (NgcErrorCodes.GET_SESSIONS_ERROR == error?.code || error) {
        setCaptureContextError(true);
      }
    };
    if (windowObject !== undefined && (windowObject.Flex || typeof windowObject.Flex === 'function')) {
      const getContextValue = async () => {
        try {
          const result: ContextResponseMapper = await getContextToken();
          if (!result.isError) {
            const token = result?.data.data[0]?.attributes.captureContext;
            const flex = new windowObject.Flex(token);
            const microform = flex.microform();
            const numberField = microform.createField('number', {
              placeholder: formatPaymentMessage({ defaultMessage: 'Enter card number', id: 'placeholder.cc.text' }),
            });
            const securityCode = microform.createField('securityCode', { placeholder: '•••' });
            numberField.load('#number-container');
            securityCode.load('#securityCode-container');

            setMicroformObj(microform);

            numberField.on('change', function (data: any) {
              if (data.card && data.card.length > 0) {
                setCcType(data.card[0]?.name);
              } else {
                setCcType(undefined);
              }
            });
          } else {
            displayMicroFormError(result.error);
          }
        } catch (error: any) {
          displayMicroFormError(error);
        }
      };
      getContextValue();
    }
  }, []);

  useEffect(() => {
    if (billingAddresses.length >= 1) {
      const transformedBillingAddress: TransformedBillingInfo[] = billingAddresses.map((address) => ({
        label: formatAddressDisplay(address, false),
        value: address.addressId,
      }));
      setBusinessUnitData(transformedBillingAddress);
      setFormData((prevFormData) => ({
        ...prevFormData,
        cardBillingAddressId: billingAddresses[0].addressId || '',
      }));
    }
  }, [billingAddresses]);

  useEffect(() => {
    if (expiryYear && expiryMonth) {
      const formattedExpiryDate = `${expiryMonth}/${expiryYear}`;
      setFormData((prevFormData) => ({
        ...prevFormData,
        ['expires']: formattedExpiryDate,
      }));
    }
    if (validateCalculatedExpiryDate(parseInt(expiryMonth), parseInt(expiryYear))) {
      setIsErrored(
        (prevIsErrored: any) =>
          ({
            ...prevIsErrored,
            expirydate: false,
          } as any),
      );
      setDisplayStatus((prevDisplayStatus) => ({
        ...prevDisplayStatus,
        expirydate: 'warning',
      }));
    }
  }, [expiryYear, expiryMonth]);

  const getContextToken = async () => {
    const partnerUUIDValue = `${businessUnit.key}-${PartnerIdUUID()}`;
    setGeneratedPartnerUUID(partnerUUIDValue);
    const requestBody: RequestResponsePaymentSessionMapper = {
      data: [
        {
          type: 'sessions',
          attributes: {
            partnerIdUUID: partnerUUIDValue,
            targetOrigin: window.location.origin,
          },
        },
      ],
    };

    const result: ContextResponseMapper = await generateCaptureContext(requestBody);

    return result;
  };

  const closeModal = (e?: MouseEvent | React.FormEvent) => {
    setLoading(false);
    toggleModalVisibility(e as MouseEvent);
  };

  const handleChange = (e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSelectBoxChange = (e: ChangeEvent<any>) => {
    if (e.target.name == 'expiryMonth') {
      setExpiryMonth(e.target.value);
    }
    if (e.target.name == 'expiryYear') {
      setExpiryYear(e.target.value);
    }
  };

  const generateTransientToken = () => {
    const options = {
      expirationMonth: expiryMonth,
      expirationYear: expiryYear,
    };

    const windowObject = window as any;
    if (
      windowObject !== undefined &&
      (windowObject.Flex || typeof windowObject.Flex === 'function') &&
      microformObj !== null
    ) {
      microformObj.createToken(options, function (err: any, token: string) {
        if (err) {
          setIsTokenResponseError({
            securityCode: Boolean(err.details?.filter((e: any) => e.location == 'securityCode')[0]),
            number: Boolean(err.details?.filter((e: any) => e.location == 'number')[0]),
          });
        } else {
          setIsTokenResponseError({
            securityCode: false,
            number: false,
          });
          console.info(' flexResponse.value -> ', token); //purposefully added the flexresponse in console for debugging
          saveCC(token);
        }
      });
    }
  };

  const saveToken = () => {
    const formattedExpiryDate = `${expiryMonth}/${expiryYear}`;
    setFormData((prevFormData) => ({
      ...prevFormData,
      ['expires']: formattedExpiryDate,
    }));

    const validExpiryData: boolean = validateCalculatedExpiryDate(parseInt(expiryMonth), parseInt(expiryYear));
    const validNameOnCard = formData.nameOnCard.trim() !== '';
    const validBillingAddress = Boolean(formData.cardBillingAddressId);
    const validForm = validExpiryData && validNameOnCard && validBillingAddress;

    if (validForm) {
      generateTransientToken();
      setIsErrored((prevIsErrored: any) => ({
        expirydate: false,
        nameOnCard: false,
        cardBillingAddressId: false,
      }));
      setDisplayStatus((prevDisplayStatus) => ({
        expirydate: 'warning',
        nameOnCard: 'warning',
        cardBillingAddressId: 'warning',
      }));
    } else {
      if (!validNameOnCard) {
        setIsErrored((prevIsErrored: any) => ({
          ...prevIsErrored,
          nameOnCard: !validNameOnCard,
        }));
        setDisplayStatus((prevDisplayStatus) => ({
          ...prevDisplayStatus,
          nameOnCard: 'error',
        }));
      }

      if (!validExpiryData) {
        setIsErrored((prevIsErrored: any) => ({
          ...prevIsErrored,
          expirydate: !validExpiryData,
        }));
        setDisplayStatus((prevDisplayStatus) => ({
          ...prevDisplayStatus,
          expirydate: 'error',
        }));
      }
      if (!validBillingAddress) {
        setIsErrored((prevIsErrored: any) => ({
          ...prevIsErrored,
          cardBillingAddressId: !validBillingAddress,
        }));
        setDisplayStatus((prevDisplayStatus) => ({
          ...prevDisplayStatus,
          cardBillingAddressId: 'error',
        }));
      }
    }
  };
  const displayErrorForSaveToken = (error: any) => {
    if (
      NgcErrorCodes.CARD_BILLING_ADDRESS_INVALID == error?.code ||
      NgcErrorCodes.EMAIL_INVALID == error?.code ||
      NgcErrorCodes.CARD_HOLDER_NAME_INVALID == error?.code ||
      NgcErrorCodes.EMAIL_INVALID == error?.code ||
      NgcErrorCodes.CARD_HOLDER_NAME_INVALID == error?.code ||
      NgcErrorCodes.JWT_TOKEN_INVALID == error?.code ||
      NgcErrorCodes.PARTNERUUID_INVALID == error?.code ||
      NgcErrorCodes.GET_SESSIONS_ERROR == error?.code
    ) {
      setSaveTokenError({
        isError: true,
        message: error?.errors[0]?.message,
      });
    }
  };

  const updateCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      isCardPrimary: checked ? checked : false,
    }));
  };

  const saveCC = async (token: string) => {
    setLoading(true);
    const requestData = {
      data: [
        {
          type: 'payments',
          attributes: {
            partnerIdUUID: generatedPartnerUUID,
            jwttoken: token,
            cardHolderName: formData.nameOnCard,
            cardBillingAddressId: formData.cardBillingAddressId,
            isCardPrimary: formData.isCardPrimary,
            email: account?.email ?? '',
          },
        },
      ],
    };
    try {
      const response = await saveTokenCC(requestData);
      if (response.isError) {
        updateNotification(true);
        closeModal();
      } else {
        setSaveTokenError({
          isError: false,
          message: '',
        });
        updateNotification(false);
        closeModal();
      }
    } catch (error: any) {
      updateNotification(true);
      closeModal();
    }
  };

  const getMonthOptions = () => {
    const monthsOptions = Array.from({ length: 12 }, (_, index) => {
      const month = (index + 1).toString().padStart(2, '0'); // Ensure double-digit format
      return { label: month, value: month };
    });

    return monthsOptions;
  };
  const displayErrorMessage = () => {
    const capturnContextErrorMsg = (
      <span className="lll-text-body-2">
        {formatPaymentMessage({
          id: 'microform.load.error',
          defaultMessage: 'Unable to Load Microform',
        })}
      </span>
    );
    const savetokenErrorMsg = <span className="lll-text-body-2">{saveTokenError.message}</span>;
    if (captureContextError) return capturnContextErrorMsg;
    // if (saveTokenError) return savetokenErrorMsg;
  };

  return (
    <Modal
      aria-label="Add new card"
      onRequestClose={(e: MouseEvent) => {
        closeModal(e);
      }}
      visible={true}
      classes={{
        modal: 'min-w-[27rem]',
      }}
      data-testid="cc-container__add-card-modal_test-id"
    >
      <div className={styles.modalContainer}>
        <Heading className="lll-text-xsmall mb-16" tag="h1" data-testid="add-card-modal__heading-test-id">
          {creditCardsList.length < 5
            ? formatPaymentMessage({
                id: 'add.card',
                defaultMessage: 'Add new card',
              })
            : formatPaymentMessage({
                id: '"max.limit.warning"',
                defaultMessage: 'You have reached the maximum limit on file.',
              })}
        </Heading>
        {creditCardsList.length < 5 ? (
          <div
            className={cx(styles.contentContainer, {
              [styles.loading]: isLoading,
            })}
          >
            {isLoading && <OverlayLoader color="red" name="payment-methods" />}
            {(captureContextError || saveTokenError?.isError) && (
              <Notification type="error" visible={true}>
                {displayErrorMessage()}
              </Notification>
            )}
            {/* {!isLoading && ( */}
            <>
              <div className="flex flex-col">
                <Field
                  type="text"
                  required
                  data-testid={'add-card-modal__nameOnCard-input_test-id'}
                  label={formatMessage({ id: 'name_on_card', defaultMessage: 'Name on card' })}
                  name="nameOnCard"
                  id="add-card-modal__nameOnCard-input"
                  disabled={false}
                  value={formData.nameOnCard}
                  onChange={handleChange}
                  status={displayStatus['nameOnCard']}
                  hint={isErrored['nameOnCard'] ? 'Invalid Name' : ''}
                  className={styles.flexLabelGroup}
                >
                  <span className={styles.ModelFieldLabel}>
                    {formatMessage({ id: 'name_on_card', defaultMessage: 'Name on card' })}
                  </span>
                </Field>
              </div>
              <div data-testid="card-number__input_test-id" className={styles.flexLabelGroup}>
                <span className={styles.ModelFieldLabel}>
                  {formatCheckoutMessage({ id: 'card.number', defaultMessage: 'Card number' })}
                </span>
                <span className={paymentStyle.numberContainerParentClass}>
                  <div className={paymentStyle.numberContainerClassName} id="number-container"></div>
                  {ccType !== undefined && resolveCCImageFromBrandName(ccType) !== ' ' ? (
                    <div className={paymentStyle.ccImageIcon}>
                      <Icon
                        className="h-fit w-26"
                        title={'cardType'}
                        content={resolveCCImageFromBrandName(ccType)}
                        data-testid={'add-credit-card-cctype__icon_test-id'}
                      />
                    </div>
                  ) : (
                    ' '
                  )}
                </span>
              </div>
              {isTokenResponseError.number && (
                <div
                  className={styles.validationErrorMessage}
                  data-testid="add-card-modal__validation-error-message_test-id"
                >
                  {formatMessage({
                    id: 'enter.valid.cc',
                    defaultMessage: 'Please enter a valid card number',
                  })}{' '}
                </div>
              )}

              <div className="flex justify-between">
                <div className={styles.flexLabelGroup}>
                  <div className={styles.ModelFieldLabel}>
                    {formatPaymentMessage({ id: 'expiration.date', defaultMessage: 'Expiration date' })}
                  </div>
                  <div className="flex items-center">
                    <div className="w-88">
                      <Select
                        id="expiry_month"
                        name="expiryMonth"
                        options={getMonthOptions()}
                        data-testid="add-payment__select-expiry-month_test-id"
                        onChange={handleSelectBoxChange}
                        value={expiryMonth}
                        status={displayStatus['expirydate']}
                        hideStatusIcon
                      >
                        {''}
                      </Select>
                    </div>
                    <div className="mx-8 text-xl"> / </div>
                    <div className="w-88">
                      <Select
                        id="expiry_year"
                        name="expiryYear"
                        options={getYearOptions()}
                        data-testid="add-payment__select-expiry-year_test-id"
                        onChange={handleSelectBoxChange}
                        value={expiryYear}
                        status={displayStatus['expirydate']}
                        hideStatusIcon
                      >
                        {''}
                      </Select>
                    </div>
                  </div>
                  {isErrored['expirydate'] && (
                    <div className={styles.errorMessage} data-testid="add-card-modal__error_test-id">
                      {formatMessage({
                        defaultMessage: 'Please enter a valid expiry date.',
                        id: 'edit.invalid.expires',
                      })}
                    </div>
                  )}
                </div>
                <div className={styles.flexLabelGroup}>
                  <span className={styles.ModelFieldLabel}>
                    {formatCheckoutMessage({ id: 'ccv', defaultMessage: 'CCV' })}
                  </span>
                  <div
                    className={`${paymentStyle.cvvContainer} w-121`}
                    data-testid="secureCode__input_test-id"
                    id="securityCode-container"
                  ></div>

                  {isTokenResponseError.securityCode && (
                    <div
                      className={styles.validationErrorMessage}
                      data-testid="add-card-modal__validation-error-message_test-id"
                    >
                      {formatMessage({
                        id: 'enter.valid.ccv',
                        defaultMessage: 'Please enter a valid ccv',
                      })}{' '}
                    </div>
                  )}
                </div>
              </div>

              <div className={styles.flexLabelGroup}>
                <Heading className="lll-text-xsmall" tag="h1">
                  {formatPaymentMessage({ id: 'billing.address', defaultMessage: 'Billing address' })}
                </Heading>
                <span className={styles.ModelFieldLabel}>
                  {formatPaymentMessage({
                    id: 'select.address.credit_card',
                    defaultMessage: 'Select an existing address on file',
                  })}
                </span>

                <Select
                  id="billing_address"
                  data-testid="add-card-modal__billing-address-select_test-id"
                  hint={isErrored['cardBillingAddressId'] ? 'Please select the billing address' : ''}
                  // messages={''}
                  placeholder="Select address..."
                  name="cardBillingAddressId"
                  onChange={handleChange}
                  options={businessUnitData}
                  required={false}
                  status={displayStatus['cardBillingAddressId']}
                  value={formData.cardBillingAddressId}
                >
                  {''}
                </Select>

                <Checkbox
                  required={false}
                  id={'add_credit_card__default-checkbox'}
                  name="isCardPrimary"
                  // defaultChecked={formData.isCardPrimary}
                  checked={formData.isCardPrimary}
                  onChange={updateCheckBoxChange}
                  data-testid={'add_credit_card__default-checkbox_test-id'}
                >
                  {formatMessage({ id: 'set.default.credit_card', defaultMessage: 'Set as default credit card' })}
                </Checkbox>
              </div>
            </>
            {/* )} */}
            <div className="mt-16 flex items-center justify-between">
              <SaveOrCancel
                onCancel={closeModal}
                variant="save"
                className="flex-col"
                saveButtonText={formatMessage({ id: 'save.credit_card', defaultMessage: 'SAVE CREDIT CARD' })}
                displayCancelLink={true}
                onSave={saveToken}
                testId="add-cc-save-card__button_test-id"
                secondaryTestId="addCC-cancel__link_test-id"
              />
            </div>
          </div>
        ) : (
          <>
            {formatPaymentMessage({
              id: 'remove.card.continue',
              defaultMessage: 'Please Remove an existing card to continue.',
            })}
            <div className="mt-16 flex items-center justify-between">
              <SaveOrCancel
                onCancel={closeModal}
                variant="save"
                className="flex-col"
                saveButtonText={formatMessage({ id: 'ok', defaultMessage: 'OK' })}
                displayCancelLink={false}
                onSave={closeModal}
                testId="add-cc-save-card-ok__button_test-id"
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default PaymentAdd;
