import { CheckoutStep } from 'ui/checkout';
import { COMPONENT_TYPE } from 'helpers/constants/analyticsEventType';
import { NgcCart } from 'shared/types/ngcCart';

import { buildPurchasePayload } from 'helpers/mappers/toProductCollectionList';
import { toProductPageDisplayedCart } from 'helpers/mappers/toProductCart';
export const CHECKOUT = {
  ORDER_CONFIRMED: (orderId: string) => {
    return {
      eventSubType: 'interaction',
      component: {
        id: 'filter_pill_clicked',
        type: COMPONENT_TYPE.BUTTON,
        text: `Order Confirmed`,
        attributes: {
          details: { data: orderId },
        },
      },
    };
  },

  CTA_ACTION: (stepValue: number, orderData: NgcCart | undefined, eventSubType: string) => {
    let stepTextId = '';
    let stepText = '';
    switch (stepValue) {
      case CheckoutStep.SHIPPING:
        stepTextId = 'payment_method';
        stepText = 'SELECT PAYMENT METHOD';
        break;
      case CheckoutStep.PAYMENT:
        stepTextId = 'review_order';
        stepText = 'REVIEW ORDER';
        break;
      case CheckoutStep.REVIEW_SUMMARY:
        stepTextId = 'place_order';
        stepText = 'PLACE ORDER';
        break;
    }
    return {
      eventSubType: eventSubType,
      component: {
        id: stepTextId,
        type: COMPONENT_TYPE.BUTTON,
        text: stepText,
        attributes: {
          cartId: orderData?.cartId,
          paymentType: orderData?.payments?.paymentMethod,
        },
      },
    };
  },
  PROMO_CODE_ACTION: (promo_code: string, action: string) => {
    const text = action === 'APPLIED' ? 'PROMO CODE APPLIED' : 'PROMO CODE REMOVED';

    return {
      eventSubType: 'interaction',
      component: {
        id: 'promo_code_text',
        type: COMPONENT_TYPE.BUTTON,
        text: text,
        attributes: {
          details: { data: promo_code },
        },
      },
    };
  },
  PAYMENT_METHOD: (payment_method: string) => {
    const text = `Applied Payment Method is ${payment_method}`;
    return {
      eventSubType: 'interaction',
      component: {
        id: 'payment_method',
        type: COMPONENT_TYPE.BUTTON,
        text: text,
        attributes: {
          details: { data: payment_method },
        },
      },
    };
  },
  PLACE_ORDER: (cart: NgcCart, orderId: string) => {
    const result = {
      orderId: orderId, // should not be an empty string
      screenCollections: 'purchase',
      collectionList: toProductPageDisplayedCart(cart, 'purchase', 'checkout_page'),
      component: {
        eventSubType: '',
        type: 'purchase', // "purchase"
        id: 'purchase', // "" if N/A
      },
      screen: {
        attributes: {
          orderId: orderId, // should not be an empty string
          cartId: cart?.cartId,
          cartTotal: cart?.cartTotal?.total, // total value of cart
          currency: cart?.cartTotal?.currencyCode, // 3 digit currency code
          discountTotal: cart?.cartTotal?.discount, // "" if N/A
          taxTotal: cart?.cartTotal?.tax?.total, // "" if N/A
          shippingTotal: cart?.shippingInfo?.shippingPrice?.total, // "" if N/A
          totalQuantity: cart?.totalQuantity,
          promoCode: cart?.promotions?.code,
          orderPaymentMethod: cart?.payments?.paymentMethod,
        },
      },
    };
    return result;
  },
  PLACE_ORDER_TC_ERROR: () => {
    return {
      error: {
        errorType: 'checkout',
        errorDetails: 'Place Order Terms and Conditions not accepted',
        errorGuestFacing: true,
        errorMessage: 'Place Order Terms and Conditions not accepted',
      },
    };
  },
  PLACE_ORDER_API_ERROR: (errorDetails: any) => {
    return {
      error: {
        errorType: 'place_order_error',
        errorMessage: 'Error in calling Place Order API',
        errorGuestFacing: true,
        errorDetails,
      },
    };
  },
  CART_VALUES: (cart: NgcCart) => {
    return {
      screen: {
        attributes: {
          cartId: cart?.cartId,
          cartTotal: cart?.cartTotal?.total, // total value of cart
          currency: cart?.cartTotal?.currencyCode, // 3 digit currency code
          discount: cart?.cartTotal?.discount, // "" if N/A
          tax: cart?.cartTotal?.tax?.total, // "" if N/A
          shipping: cart?.shippingInfo?.shippingPrice?.total, // "" if N/A
          totalQuantity: cart?.totalQuantity, //  total number of products in cart
          gender: '', // "" if N/A
          pageType: 'checkout', // "" if N/A
          primaryCategory: '', // "" if N/A
          subCategory1: '', // "" if N/A
          categoryName: '', // "" if N/A
        },
        collections: [''],
        header: {
          unified: '', // "" if N/A
        },
      },
      collectionList: toProductPageDisplayedCart(cart, 'purchase', 'checkout_page'),
    };
  },
};
