import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Link } from '@lululemon/ecom-pattern-library';
import { useFormat } from 'helpers/hooks/useFormat';
import styles from './simple-footer.module.scss';
import { FooterLinkProps, LocaleByPartnerCountry, LocaleSpecific } from '../detailed-footer/types';
import { filteredLocaleSpecificFooterData } from '../detailed-footer/utils';
import { useAccount } from 'frontastic/index';

type SimpleFooterProps = {
  links: FooterLinkProps[];
};

const SimpleFooter = ({ links }: SimpleFooterProps) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const { account } = useAccount();

  const partnerCountry = account?.companyProfile?.partnerCountry;

  const localeValue = partnerCountry
    ? LocaleByPartnerCountry[partnerCountry as keyof typeof LocaleByPartnerCountry]
    : '';

  const filteredLocaleSpecificLinks = filteredLocaleSpecificFooterData(links, localeValue as LocaleSpecific);

  return (
    <footer data-testid="simple-footer_test-id" className={styles.simpleFooterContainer}>
      <ul role="list" className={styles.legalLinksContainer}>
        {filteredLocaleSpecificLinks.map(({ name, reference }, index) => {
          return (
            <Link
              className={styles.legalLink}
              data-testid={`simple-footer__legal-link-${index}_test-id`}
              key={index}
              tag="li"
              textStyle="body-2"
              variant="underlineAnimated"
            >
              <NextLink href={reference.type === 'link' ? reference.link : reference.pageFolder._url} passHref>
                <a
                  rel={reference.openInNewWindow ? 'noopener' : undefined}
                  target={reference.openInNewWindow ? '_blank' : undefined}
                >
                  {name}
                </a>
              </NextLink>
            </Link>
          );
        })}
        <li className={styles.copyright}>
          {formatMessage({
            id: 'copyright',
            defaultMessage: '© lululemon athletica 1818 Cornwall Ave, Vancouver BC V6J 1C7',
          })}
        </li>
      </ul>
    </footer>
  );
};

export default SimpleFooter;
