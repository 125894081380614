import { Product, Colour } from 'shared/types/product/index';
import { UIColorSwatchAndImagesWithSKU } from 'types/ui';

export const toColorSwatchesAndImages = (product: Product) => {
  const mappedColors: UIColorSwatchAndImagesWithSKU[] = [
    ...new Map(
      product.colours?.map((colours: Colour) => [
        colours.name,
        {
          swatch: {
            name: colours.description,
            description: colours.description,
            id: colours.code,
            sku: colours.sku,
          },
          imageUrl: colours.images?.[0]?.src,
          hoverImageUrl: colours.images?.[1]?.src,
        },
      ]),
    ).values(),
  ];

  return mappedColors;
};
