import useSWR from 'swr';
import { sdk } from 'sdk';
import { Response } from './types';

export const useAllSizeGuideEntriesByCategory = (slug: string): Response => {
  const response = useSWR(
    `/action/contentful/getAllSizeGuideEntriesByCategory?slug=${slug}`,
    () => sdk.callAction({ actionName: 'contentful/getAllSizeGuideEntriesByCategory', query: { slug } }),
    {
      dedupingInterval: 3600000, // 1 hour
    },
  );

  if (response.data?.isError) {
    return {
      data: undefined as any,
      error: response.data?.error,
      _type: 'error',
      isLoading: false,
    };
  }

  return {
    data: response.data?.data ?? {},
    _type: 'success',
    isLoading: Boolean(!response.data?.data),
  };
};
