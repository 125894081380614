import React from 'react';
import QuickLinksGroup, { QuickLinksProps } from 'components/commercetools-ui/organisms/home/quick-links-group';
import { FEATURE_FLAG_LIST, useFeatureFlags } from 'hooks';

interface Props {
  data: QuickLinksProps;
}

const QuickLinksGroupTastic = ({ data }: Props) => {
  const { isFeatureEnabled } = useFeatureFlags();
  return isFeatureEnabled(FEATURE_FLAG_LIST.HOME_PAGE) ? <QuickLinksGroup {...data} /> : null;
};

export default QuickLinksGroupTastic;
