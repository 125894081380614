import { Category } from 'shared/types/product/Category';
import { Level1Item, Level2Item, Level3Item, DataProps, Breadcrumb } from 'shared/types/nav';
import { ACCESSORIES, BAGS, WOMEN, MEN, FEATURES } from 'helpers/constants/nav';

const getAccessoriesBagData = (level1MainCategorySection: Level1Item[]) => {
  const mainCategory = level1MainCategorySection?.find((elem: Level1Item) => ACCESSORIES === elem?.l1MainCategorySlug);

  if (mainCategory) {
    const item = mainCategory.level2CategoryLinksSection?.find((el: Level2Item) => el?.l2CategoryLinkSlug === BAGS);
    if (item) {
      return item;
    }
  }
};

export const categoryNamingFromStudioNavData = (data: DataProps, ancestors: Breadcrumb[]) => {
  const { navData } = data;
  if (!navData) return ancestors;

  const { level1MainCategorySection } = navData;

  let level1Ancestor: Level2Item;
  let level2Ancestor: Level3Item;
  const level1AncestorBag: Level2Item | undefined = getAccessoriesBagData(level1MainCategorySection);

  let dataWithStudioNamings = ancestors?.map((breadcrumbItem, index) => {
    const slug = breadcrumbItem?.slug;
    if (index === 0) {
      const mainCategory = level1MainCategorySection?.find((elem: Level1Item) => slug === elem?.l1MainCategorySlug);

      if (mainCategory) {
        const item = mainCategory.level2CategoryLinksSection?.find((el: Level2Item) => el?.l2CategoryLinkSlug === slug);
        if (item) {
          level1Ancestor = item;
          const title = item?.l2Title || item?.l2CategoryLinkDisplayName;
          return { ...breadcrumbItem, name: item?.l2CategoryLinkDisplayName, title };
        }
      }
    }

    if (index === 1) {
      const item = level1Ancestor?.level3SubcategoryLinksSection?.find((el) => el.l3SubcategoryLinkSlug === slug);

      if (item) {
        level2Ancestor = item;
        const title = item?.l3Title || item?.l3SubcategoryLinkDisplayName;
        return { ...breadcrumbItem, name: item?.l3SubcategoryLinkDisplayName, title };
      }
      if (slug === BAGS) {
        const item = level1AncestorBag;
        if (item) {
          const title = item?.l2Title || item?.l2CategoryLinkDisplayName;
          return { ...breadcrumbItem, name: item?.l2CategoryLinkDisplayName, title };
        }
      }

      const temp = level1AncestorBag?.level3SubcategoryLinksSection?.find((el) => el.l3SubcategoryLinkSlug === slug);
      if (temp) {
        level2Ancestor = temp;
        const title = temp?.l3Title || temp?.l3SubcategoryLinkDisplayName;
        return { ...breadcrumbItem, name: temp?.l3SubcategoryLinkDisplayName, title };
      }
    }
    if (index === 2) {
      const item = level2Ancestor?.level4SubcategoryLinksSection?.find((el) => el.l4SubcategoryLinkSlug === slug);
      if (item) {
        const title = item?.l4Title || item?.l4SubcategoryLinkDisplayName;
        return { ...breadcrumbItem, name: item?.l4SubcategoryLinkDisplayName, title };
      }
    }

    return { ...breadcrumbItem, title: breadcrumbItem?.name };
  });

  return dataWithStudioNamings;
};

const getLevel1Data = (level1MainCategorySection: Level1Item[], slug: String) => {
  return level1MainCategorySection?.find((elem: Level1Item) => elem?.l1MainCategorySlug === slug);
};

const getLevel2Data = (mainCategory: Level1Item, slug: String) => {
  return mainCategory.level2CategoryLinksSection?.find((el: Level2Item) => el?.l2CategoryLinkSlug === slug);
};

const getLevel3Data = (studioDataForParentCategory: Level2Item, slug: String) => {
  return studioDataForParentCategory?.level3SubcategoryLinksSection?.find((el) => el.l3SubcategoryLinkSlug === slug);
};

const getLevel4Data = (level3Data: Level3Item, slug: String) => {
  return level3Data?.level4SubcategoryLinksSection?.find((el) => el.l4SubcategoryLinkSlug === slug);
};

export const getGender = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get('gender');
};

const getStudioDataForCategory = (category: Category, level1MainCategorySection: Level1Item[]) => {
  const slug = category?.slug || '';

  const SLUGS = [MEN, WOMEN];
  const isMainCategory = SLUGS.includes(slug);
  if (isMainCategory) {
    const mainCategory = getLevel1Data(level1MainCategorySection, slug);
    if (mainCategory) {
      const item = getLevel2Data(mainCategory, slug);
      if (item) {
        return item;
      }
    }
  }

  const gender = getGender();
  if (slug === ACCESSORIES) {
    if (gender && SLUGS.includes(gender)) {
      const mainCategory = getLevel1Data(level1MainCategorySection, gender);
      if (mainCategory) {
        const item = getLevel2Data(mainCategory, slug);
        if (item) {
          return item;
        }
      }
    } else {
      const mainCategory = getLevel1Data(level1MainCategorySection, slug);
      if (mainCategory) {
        const item = getLevel2Data(mainCategory, slug);
        if (item) {
          return item;
        }
      }
    }
  }
  if (!gender && slug === BAGS) {
    const mainCategory = getLevel1Data(level1MainCategorySection, ACCESSORIES);
    if (mainCategory) {
      const item = getLevel2Data(mainCategory, slug);
      if (item) {
        return item;
      }
    }
  }
};

export const getCategoryTitleFromStudioData = (
  data: DataProps,
  slug: string | undefined,
  categories: Category[],
  breadcrumbCategories: Breadcrumb[] = [],
) => {
  const { navData } = data;
  if (!navData) return '';
  if (!slug) return '';
  const category = categories.find((category) => category.slug === slug);
  if (!category) return '';

  const { level1MainCategorySection } = navData;

  const studioDataForCategory = getStudioDataForCategory(category, level1MainCategorySection);

  if (studioDataForCategory) {
    // category is at level 2 in studio data
    return studioDataForCategory?.l2Title || studioDataForCategory?.l2CategoryLinkDisplayName || '';
  }

  const parentCategory = categories.find((elem) => elem.categoryId === category?.parentId);
  if (!parentCategory) return '';

  const isFeature = parentCategory?.slug === FEATURES;

  if (!studioDataForCategory && !isFeature) {
    const studioDataForParentCategory = getStudioDataForCategory(parentCategory, level1MainCategorySection);

    if (studioDataForParentCategory) {
      const item = getLevel3Data(studioDataForParentCategory, slug);

      if (item) {
        // category is at level 3 in studio data
        return item?.l3Title || item?.l3SubcategoryLinkDisplayName || '';
      }
    }
  }

  // if current category is feature, checking under which main category that feature is. Then getting related data for that.
  if (!studioDataForCategory && isFeature) {
    const gender = getGender();

    if (!gender) return '';

    const featureParentCategory = getLevel1Data(level1MainCategorySection, gender);

    if (featureParentCategory) {
      const featuresData = getLevel2Data(featureParentCategory, FEATURES);

      if (featuresData) {
        const item = getLevel3Data(featuresData, slug);

        if (item) {
          return item?.l3Title || item?.l3SubcategoryLinkDisplayName || '';
        }
      }
    }
  }

  // Checking the category maybe the 4th level category, so Checking with breadcrumb data, available on page(CDP/PDP).
  if (breadcrumbCategories.length) {
    const studioDataForCategory = getStudioDataForCategory(breadcrumbCategories[0], level1MainCategorySection);

    if (studioDataForCategory) {
      const breadcrumbSecondItemSlug = breadcrumbCategories[1]?.slug || '';
      const thirdLevelItem = getLevel3Data(studioDataForCategory, breadcrumbSecondItemSlug);

      if (thirdLevelItem?.level4SubcategoryLinksSection.length) {
        const item = getLevel4Data(thirdLevelItem, slug);

        if (item) {
          return item?.l4Title || item?.l4SubcategoryLinkDisplayName || '';
        }
      }
    }
  }

  const genderFromUrlParam = getGender();
  const genderFromPath = !genderFromUrlParam ? parentCategory.path?.split('/')[2] : '';

  let fallbackTitle = '';

  if (genderFromUrlParam || genderFromPath) {
    const gender = genderFromUrlParam || genderFromPath;
    const capitalizedGender = gender ? gender.charAt(0).toUpperCase() + gender.slice(1).toLowerCase() : '';

    fallbackTitle = `${capitalizedGender}'s ${category.name}`;
  } else {
    if (category.isMenCategory) {
      fallbackTitle = `Men's ${category.name}`;
    } else if (category.isWomenCategory) {
      fallbackTitle = `Women's ${category.name}`;
    } else {
      fallbackTitle = category.name || '';
    }
  }

  return fallbackTitle;
};
