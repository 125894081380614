import React from 'react';
import FAQ, { FaqProps } from '../../../ui/customer-support/components/faq';

interface Props {
  data: FaqProps;
}

const FaqTastic: React.FC<Props> = ({ data }) => {
  return <FAQ {...data} />;
};

export default FaqTastic;
