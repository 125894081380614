import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Link } from '@lululemon/ecom-pattern-library';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic/index';
import styles from './column.module.scss';
import { FooterColumnProps, LocaleSpecific } from './types';
import { filteredLocaleSpecificFooterData } from './utils';
import { useAnalytics } from 'hooks/useAnalytics';
import { ACCOUNT } from 'helpers/utils/googleAnalyticsEvents/account';

export type ColumnProps = {
  column: FooterColumnProps;
  columnIndex: number;
};

export const Column = ({ column, columnIndex }: ColumnProps) => {
  const { trackEvent, EVENT_CATEGORY } = useAnalytics();

  const { header, links } = column;

  const { logout } = useAccount();
  const router = useRouter();
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const handleLogout = () => {
    trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, ACCOUNT.FOOTER_MENU_ICON_CLICK('Sign Out'));
    logout().then(() => {
      trackEvent(EVENT_CATEGORY.APP_RESPONSE, ACCOUNT.FOOTER_MENU_SIGN_OUT_CLICK);
      router.replace('login').then(() => router.reload());
    });
  };

  const filteredLocaleSpecificLinks = filteredLocaleSpecificFooterData(links, router.locale as LocaleSpecific);

  const footerAccountMenuLinkHandler = (linkText = '') => {
    trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, ACCOUNT.FOOTER_MENU_ICON_CLICK(linkText));
  };

  return (
    <div>
      <div className={styles.header} data-testid="footer__column-header_test-id">
        {header}
      </div>

      <ul role="list" className={styles.listContainer}>
        {filteredLocaleSpecificLinks.map(({ reference, name }, i) => (
          <li key={i}>
            <Link
              className={styles.footerLinks}
              data-testid={`footer__column-link-${columnIndex}-${i}_test-id`}
              tag="div"
              textStyle="body-2"
              variant="underlineAnimated"
            >
              <NextLink href={reference.type === 'link' ? reference.link : reference.pageFolder._url}>
                <a
                  onClick={columnIndex === 0 ? () => footerAccountMenuLinkHandler(name) : undefined}
                  rel={reference.openInNewWindow ? 'noopener' : undefined}
                  target={reference.openInNewWindow ? '_blank' : undefined}
                >
                  {name}
                </a>
              </NextLink>
            </Link>
          </li>
        ))}
        {columnIndex === 0 && (
          <li>
            <Link
              className={styles.footerLinks}
              data-testid={`footer__column-link-sign-out_test-id`}
              onClick={handleLogout}
              tag="button"
              textStyle="body-2"
              variant="underlineAnimated"
            >
              {formatAccountMessage({ id: 'sign.out', defaultMessage: 'Sign Out' })}
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};
