import React from 'react';
import ProductPromotionCarousel from 'components/commercetools-ui/organisms/pattern-library/product-promotion-carousel';
import { Data } from 'components/commercetools-ui/organisms/pattern-library/product-promotion-carousel/types/index';

const ProductPromotionCarouselTastic: React.FC<Data> = ({ data }) => {
  if (!data?.data?.dataSource?.items?.length) return <p>No products found.</p>;
  return <ProductPromotionCarousel data={data} />;
};

export default ProductPromotionCarouselTastic;
