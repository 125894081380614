import UnderMaintenance, { Data } from 'ui/customer-support/components/under-maintenance';

type Props = {
  data: Data;
};

const UnderMaintenanceTastic = ({ data }: Props) => {
  return <UnderMaintenance data={data} />;
};

export default UnderMaintenanceTastic;
