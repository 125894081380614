import { FC, useLayoutEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { useNavContext } from 'context/nav';
import styles from './detailed-nav.module.scss';
import Features from './features';
import MainDetails from './main-details';

const DetailedNav: FC = () => {
  const { activeCategory, handleCloseDetailedNav } = useNavContext();
  const [height, setHeight] = useState(0);
  const elRef = useRef<HTMLDivElement>(null);
  const hasFeatures = !!activeCategory?.hasFeatures;
  const showDetailedNav = !!activeCategory?.subCategories?.length;

  useLayoutEffect(() => {
    if (!activeCategory) setHeight(0);
    else setHeight(elRef.current?.offsetHeight ?? 0);
  }, [activeCategory]);

  return (
    <div
      data-testid="nav-desktop__details_test-id"
      className={classnames(styles.detailedNavOuter, {
        [styles.showDetailedNav]: showDetailedNav,
      })}
      style={{ height }}
      onMouseLeave={handleCloseDetailedNav}
    >
      {showDetailedNav && (
        <div ref={elRef} className={classnames(styles.detailedNavInner)}>
          {hasFeatures && <Features />}
          <MainDetails />
        </div>
      )}
    </div>
  );
};

export default DetailedNav;
