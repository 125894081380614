import { useState } from 'react';
import { Heading, AccordionStatelessV2, AccordionItemV2 } from '@lululemon/ecom-pattern-library';
import cs from 'classnames';
import { ExpandedAccordionPanels } from 'shared/types/ecom-pattern-library';
import { CUSTOMER_SUPPORT } from 'helpers/utils/googleAnalyticsEvents';
import { useAnalytics } from 'hooks/useAnalytics';
import styles from './faq.module.scss';

type FaqAccordionProps = {
  accordionHeading: string;
  accordionContent: string;
};

export type FaqProps = {
  faqHeading: string;
  faqSubheading: string;
  faqAccordionItems: FaqAccordionProps[];
};

const FAQ = ({ faqHeading, faqSubheading, faqAccordionItems }: FaqProps) => {
  const { trackEvent, EVENT_CATEGORY } = useAnalytics();

  const accordionStates = () => {
    const states: ExpandedAccordionPanels = {};
    faqAccordionItems.forEach((_, index) => {
      states[index] = false;
    });

    return states;
  };

  const [expandedAccordionPanels, setExpandedAccordionPanels] = useState<ExpandedAccordionPanels>(accordionStates());

  return (
    <div data-testid="customer-support__faq_test-id" className={cs('lll-grid lll-grid-padding', styles.container)}>
      <Heading
        data-testid="customer-support__faq-heading_test-id"
        wrapperClassName={styles.heading}
        className="lll-text-large"
        tag="h1"
      >
        {faqHeading}
      </Heading>
      <div data-testid="customer-support__faq-subheading_test-id" className={styles.subheading}>
        {faqSubheading}
      </div>
      <div data-testid="customer-support__faq-accordion_test-id" className={styles.accordionContainer}>
        <AccordionStatelessV2
          id="faq-accordion"
          defaultTypeStyle="body-1"
          expandedAccordionPanels={expandedAccordionPanels}
          onClickAccordionItem={(index: number) => {
            setExpandedAccordionPanels((prevState) => {
              return {
                ...prevState,
                [index]: !prevState[index],
              };
            });
            trackEvent(
              EVENT_CATEGORY.COMPONENT_EVENT,
              CUSTOMER_SUPPORT.FAQ_ACCORDION_CLICKED(faqAccordionItems[index].accordionHeading),
            );
          }}
          v2
        >
          {faqAccordionItems.map(({ accordionHeading, accordionContent }, index) => {
            return (
              <AccordionItemV2 key={index} heading={accordionHeading}>
                {accordionContent}
              </AccordionItemV2>
            );
          })}
        </AccordionStatelessV2>
      </div>
    </div>
  );
};

export default FAQ;
