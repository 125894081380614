import React from 'react';
import NextLink from 'next/link';
import { Button, Heading } from '@lululemon/ecom-pattern-library';
import { useFormat } from 'helpers/hooks/useFormat';
import styles from './empty-cart.module.scss';

export const EmptyCart = () => {
  const { formatMessage } = useFormat({ name: 'cart' });

  return (
    <div className={styles.emptyCartContainer} data-testid="cart__empty-cart_test-id">
      <Heading className={styles.headingBlock} tag="h2">
        {formatMessage({ id: 'empty.cart.heading', defaultMessage: 'Explore newly dropped products' })}
      </Heading>
      {/* Need to check with team for hardcoding */}
      <NextLink href={`/features/whats-new`}>
        <Button kind="primary">{formatMessage({ id: 'empty.cart.cta', defaultMessage: 'shop what’s new' })}</Button>
      </NextLink>
    </div>
  );
};
