import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { LoadingIndicator, Heading } from '@lululemon/ecom-pattern-library';
import cs from 'classnames';
import { NgcErrorType } from 'shared/types/error';
import { OverlayLoader } from 'components/overlayLoader';
import { useCart } from 'context/cart';
import { useFormat } from 'helpers/hooks/useFormat';
import { parseNGCError } from 'helpers/ngc';
import { CHECKOUT } from 'helpers/utils/googleAnalyticsEvents';
import { ngcErrorInitialState, errorObj, NgcErrorCodes } from 'helpers/utils/ngcResponseUtil';
import { useAnalytics } from 'hooks/useAnalytics';
import { EmptyCart } from 'ui/cart/empty-cart';
import styles from './checkout.module.scss';
import CartSummary from './components/cart-summary';
import CtaWrapper from './components/cta-wrapper';
import OrderReviewSummary from './components/orderReviewSummary';
import PaymentMethods, { PaymentType } from './components/paymentMethods';
import PlaceOrderErrorModal from './components/placeOrderErrorModal';
import ShippingWrapper from './components/shippingWrapper';
import { ConfirmLeave } from '../checkout/components/confirmLeave';
import NotFound from 'components/commercetools-ui/organisms/not-found';
import { ORDER_REFERENCE_ID_LENGTH } from '../../../frontend/helpers/constants';

export enum CheckoutStep {
  SHIPPING,
  PAYMENT,
  REVIEW_SUMMARY,
}

export interface CheckoutError {
  callBack: (() => void) | null;
  isError: boolean;
  showTryAgain: boolean;
}

export interface ShippingMethodError extends CheckoutError {
  errorType: 'getShippingMethod' | 'updateShippingMethod' | null;
}

export type CheckoutNotificationPayloadType =
  | {
      message: string | React.JSX.Element;
      showTryAgain?: boolean;
      tryAgainCallback?: () => void;
      type?: string;
    }
  | null
  | undefined;

type CheckoutFields = 'isCreditCardValid' | 'paymentMethodAddedInCart' | 'termsAndConditionsChecked';

export type CheckoutFieldValidityType = {
  [key in CheckoutFields]?: boolean;
};

const Checkout = () => {
  const { formatMessage } = useFormat({ name: 'checkout' });
  const { trackEvent, EVENT_CATEGORY, EVENT_TYPE } = useAnalytics();
  const router = useRouter();
  const { cart, updatePoNumberToCart, cartLoading, placeOrder, resetCart, initializeCart, resetCartSession } =
    useCart();
  const [activeStep, setActiveStep] = useState<CheckoutStep>(CheckoutStep.SHIPPING);
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [placeOrderError, setPlaceOrderError] = useState<errorObj>(ngcErrorInitialState);
  const [confirmOnDismiss, setConfirmOnDismiss] = useState(false);
  const [loadingShippingMethod, setLoadingShippingMethod] = useState(false);

  const hasItems = Boolean(cart?.lineItems?.length);

  const [poNumber, setPoNumber] = useState(cart?.poNumber || '');
  const initialError = {
    isError: false,
    callBack: null,
    showTryAgain: false,
  };

  const [poNumberError, setPoNumberError] = useState<CheckoutError>(initialError);

  const [checkoutValidity, setCheckoutValidity] = useState<CheckoutFieldValidityType>({
    isCreditCardValid: false,
    paymentMethodAddedInCart: false,
    termsAndConditionsChecked: false,
  });
  const [showErrorOnCTAClick, setShowErrorOnCTAClick] = useState(false);

  const updateFieldValidity = (updatedValidity: CheckoutFieldValidityType) => {
    setCheckoutValidity((prevValidity) => ({
      ...prevValidity,
      ...updatedValidity,
    }));
  };

  const setVisibilityOfErrorOnCTAClick = (value: boolean) => {
    setShowErrorOnCTAClick(value);
  };

  useEffect(() => {
    if (cart) trackEvent(EVENT_CATEGORY.PAGE_PRODUCTS_DISPLAYED, CHECKOUT.CART_VALUES(cart));
  }, [hasItems]);

  useEffect(() => {
    if (!poNumber) {
      setPoNumber(cart?.poNumber || '');
    }
  }, [cart]);

  const isShippingSectionValid = () => {
    return Boolean(cart?.shippingAddress?.addressId) && Boolean(cart?.shippingInfo?.id);
  };

  const isPoNumberValid = () => {
    return poNumber === '' || poNumber.length <= ORDER_REFERENCE_ID_LENGTH;
  };

  const updatePoNumber = async (poNumber: string) => {
    const trimmedPoNumber = poNumber.trim();
    if (cart?.poNumber === trimmedPoNumber) return;
    try {
      await updatePoNumberToCart(trimmedPoNumber);
      setPoNumberError(initialError);
    } catch (error) {
      const parsedError = parseNGCError(error);
      handleUpdatePoNumberError(parsedError as NgcErrorType, () => updatePoNumber(trimmedPoNumber));
    }
  };

  const handleUpdatePoNumberError = (error?: NgcErrorType, callback?: () => void) => {
    let showTryAgain = false;
    const errorCodesToCheck: string[] = [
      NgcErrorCodes.CART_NOT_FOUND,
      NgcErrorCodes.OPERATION_NOT_PERMITTED,
      NgcErrorCodes.SHIPPING_ADDRESS_NULL,
    ];
    if (error?.code && errorCodesToCheck.includes(error.code)) {
      showTryAgain = true;
    } else if (error?.code === NgcErrorCodes.CART_ALREADY_ORDERED) {
      router.push('/something?isCartAlreadyOrdered=true');
    }
    setPoNumberError({
      isError: true,
      callBack: () => {
        if (callback) {
          callback();
        }
      },
      showTryAgain,
    });
  };

  const placeOrderHandler = async (retry = false) => {
    setLoading(true);
    setConfirmOnDismiss(true);
    try {
      let response: any;
      if (retry) {
        response = await initializeCart();
        if (!response?.orderNumber) {
          response = await placeOrder();
        }
      } else {
        response = await placeOrder();
      }
      if (cart) trackEvent(EVENT_CATEGORY.PURCHASE, CHECKOUT.PLACE_ORDER(cart, response.orderNumber));
      setConfirmOnDismiss(false);
      setLoading(false);
      await resetCartSession();
      router.push({
        pathname: '/thank-you',
        query: { orderNumber: response.orderNumber },
      });
    } catch (error) {
      setOpen(true);
      const parsePlaceOrderError = parseNGCError(error);
      trackEvent(EVENT_CATEGORY.ERROR, CHECKOUT.PLACE_ORDER_API_ERROR(parsePlaceOrderError));
      setPlaceOrderError(parsePlaceOrderError);
      setLoading(false);
      setConfirmOnDismiss(false);
    }
  };

  const shippingWrapperProps = {
    setPoNumber,
    poNumber,
    poNumberError,
    loadingShippingMethod,
    setLoadingShippingMethod,
  };

  const renderComponent = () => {
    switch (activeStep) {
      case CheckoutStep.SHIPPING:
        return <ShippingWrapper {...shippingWrapperProps} />;
      case CheckoutStep.PAYMENT:
        return (
          <PaymentMethods
            checkoutFieldValidity={checkoutValidity}
            setCheckoutFieldValidity={updateFieldValidity}
            setShowErrorOnCTAClick={setVisibilityOfErrorOnCTAClick}
            showErrorOnCTAClick={showErrorOnCTAClick}
          />
        );
      case CheckoutStep.REVIEW_SUMMARY:
        return ' ';
      default:
        return null;
    }
  };

  const isValidPaymentMethodAddedInCart = () => {
    const paymentMethodAddedInCart = cart?.payments?.paymentMethod;
    if (paymentMethodAddedInCart === PaymentType.creditCard) {
      return checkoutValidity.paymentMethodAddedInCart && checkoutValidity.isCreditCardValid;
    }

    if (paymentMethodAddedInCart === PaymentType.AR) {
      return checkoutValidity.paymentMethodAddedInCart;
    }

    return false;
  };

  const handleCtaEvent = (activeStep: CheckoutStep) => {
    switch (activeStep) {
      case CheckoutStep.SHIPPING:
        if (isShippingSectionValid() && isPoNumberValid()) {
          if (cart?.poNumber !== poNumber) {
            updatePoNumber(poNumber);
          }
          setActiveStep(CheckoutStep.PAYMENT);
          if (cart)
            trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, CHECKOUT.CTA_ACTION(activeStep, cart, EVENT_TYPE.INTERACTION));
        }
        break;
      case CheckoutStep.PAYMENT:
        if (isValidPaymentMethodAddedInCart()) {
          setActiveStep(CheckoutStep.REVIEW_SUMMARY);
          if (cart)
            trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, CHECKOUT.CTA_ACTION(activeStep, cart, EVENT_TYPE.INTERACTION));
        } else {
          setShowErrorOnCTAClick(true);
        }
        break;
      case CheckoutStep.REVIEW_SUMMARY:
        if (checkoutValidity.termsAndConditionsChecked) {
          placeOrderHandler();
          if (cart)
            trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, CHECKOUT.CTA_ACTION(activeStep, cart, EVENT_TYPE.INTERACTION));
        } else {
          trackEvent(EVENT_CATEGORY.ERROR, CHECKOUT.PLACE_ORDER_TC_ERROR());
        }
        break;
    }
  };

  const editOrderSummaryClickHandler = (stepToMove: CheckoutStep) => {
    setActiveStep(stepToMove);
  };
  const placeOrderCtaErrorHandler = (stepToMove: CheckoutStep) => {
    setActiveStep(stepToMove);
  };

  const showOrderSummary = activeStep !== CheckoutStep.SHIPPING;

  return (
    <div className={cs('lll-grid', styles.mainContainer)}>
      {cartLoading ? (
        <div className={styles.checkoutLoaderContainer} data-testid="checkout__loading_test-id">
          <LoadingIndicator color="red" />
        </div>
      ) : !cart ? (
        <div className={styles.pageNotFound} data-testid="checkout__page-not-found_test-id">
          <NotFound isCartError={true} />
        </div>
      ) : hasItems ? (
        <>
          <Heading tag="h1" wrapperClassName={styles.heading} data-testid="checkout__heading_test-id">
            {formatMessage({ id: 'checkout', defaultMessage: 'Checkout' })}
          </Heading>
          <div className={styles.lhs}>
            {isLoading && <OverlayLoader color="red" name="checkout" />}
            {showOrderSummary && (
              <OrderReviewSummary activeStep={activeStep} editOrderSummaryClickHandler={editOrderSummaryClickHandler} />
            )}
            {renderComponent()}
            <CtaWrapper
              activeStep={activeStep}
              onCtaEvent={handleCtaEvent}
              setCheckoutFieldValidity={updateFieldValidity}
              loadingShippingMethod={loadingShippingMethod}
            />
          </div>
          <ConfirmLeave isConfirmDialogOpen={confirmOnDismiss}>
            <CartSummary activeStep={activeStep} />
          </ConfirmLeave>
          <PlaceOrderErrorModal
            isOpen={isOpen}
            setIsOpen={setOpen}
            errorObj={placeOrderError}
            placeOrderCtaErrorHandler={placeOrderCtaErrorHandler}
            placeOrderHandler={placeOrderHandler}
          />
        </>
      ) : (
        <div className={styles.pageNotFound} data-testid="checkout__page-not-found_test-id">
          <EmptyCart />
        </div>
      )}
    </div>
  );
};

export default Checkout;
