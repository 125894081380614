import { useEffect, useState } from 'react';
import { Heading, CTABlock, GridContainer, LinkProvider } from '@lululemon/ecom-pattern-library';
import Skeleton from 'react-loading-skeleton';
import { ProductCard } from 'shared/types/homePage';
import categoryQueryParams from 'helpers/categoryQueryParam';
import { ConditionalLink } from 'helpers/cocoLink';
import { useProduct } from 'helpers/hooks/useProduct';
import { toProductCollectionList } from 'helpers/mappers/toProductCollectionList';
import { HOME_PAGE } from 'helpers/utils/googleAnalyticsEvents';
import { useAnalytics } from 'hooks/useAnalytics';
import { useProductList } from 'ui/cdp/context';
import { useAccount } from 'frontastic/index';
import styles from './quick-links-group.module.scss';

export interface QuickLinkProps {
  heading: string;
  subContent: string;
  product?: ProductCard;
  categorySlug?: string;
  path?: string;
  categoryId?: string;
}

export interface QuickLinksProps {
  quickLinksTitle: string;
  quickLinks: QuickLinkProps[];
}

const QuickLinksGroup: React.FC<QuickLinksProps> = ({ quickLinksTitle, quickLinks }) => {
  const { categories, getProductForQuickLink } = useProduct();
  const [isLoading, setLoading] = useState(false);
  const [quickLinksData, setQuickLinksData] = useState<QuickLinkProps[]>([]);
  const { limitStep } = useProductList();
  const { account } = useAccount();
  const categoryParams = categoryQueryParams(account, limitStep, null);
  //Google Analytics
  const { trackEvent, EVENT_CATEGORY } = useAnalytics();

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      if (quickLinks.length && categories.length) {
        try {
          const categoryIds: string[] = [];
          quickLinks.map((link) => {
            const categoryResult = categories.find((item) => {
              return item.slug == link.categorySlug;
            });
            if (categoryResult?.categoryId) {
              categoryIds.push(categoryResult?.categoryId);
            }
            link.categoryId = categoryResult?.categoryId;
            link.categorySlug = categoryResult?.slug;
            //category link with all query params
            link.path = `${categoryResult?.path}?${categoryParams}`;
          });
          if (categoryIds.length) {
            const response = await getProductForQuickLink(categoryIds);

            if (Array.isArray(response) && response.length > 0) {
              const productMap = new Map(response.map((item) => [item.categoryId, item]));

              quickLinks.forEach((link) => {
                const productList = productMap.get(link.categoryId);
                link.product = productList ? productList.products[0] : {};
              });

              setQuickLinksData(quickLinks);
            }
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    };
    fetchProducts();
  }, [categories.length]);

  return (
    <GridContainer
      layout="2 Columns - Wide Split"
      className={styles.gridContainer}
      heading={
        <Heading className="lll-text-medium" tag="h2" data-testid="quick-group-links__heading_test-id">
          {isLoading ? <Skeleton className={styles.quickLinksHeadingLoading} count={1} /> : quickLinksTitle}
        </Heading>
      }
    >
      {isLoading ? (
        quickLinks?.map((link: QuickLinkProps, index) => (
          <Skeleton key={index} className={styles.quickLinksLoading} count={1} />
        ))
      ) : (
        <LinkProvider component={ConditionalLink}>
          {quickLinksData?.map(
            (link: QuickLinkProps, index) =>
              link.product?.images?.src &&
              link?.path && (
                <CTABlock
                  imageProps={{
                    src: link.product?.images?.src,
                    lazy: false,
                  }}
                  heading={link?.heading}
                  subContent={link?.subContent}
                  data-testid={`quick-group-links__link_${index}_test-id`}
                  className={styles.ctaBlock}
                  href={link?.path}
                  key={`quick-link-${index}`}
                  onClick={() => {
                    trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, HOME_PAGE.QUICK_LINKS_CTA_CLICKED(link?.heading));
                  }}
                />
              ),
          )}
        </LinkProvider>
      )}
    </GridContainer>
  );
};

export default QuickLinksGroup;
