import { createRef, MutableRefObject } from 'react';

export const createRefMap = () => {
  const map = new Map<string, MutableRefObject<HTMLUnknownElement>>();

  const setRef = (key: string) => {
    console.assert(!!key, 'ref: Cannot set ref without key ');
    const ref = createRef<HTMLUnknownElement>() as MutableRefObject<HTMLUnknownElement>;
    map.set(key, ref);
    return ref;
  };

  const getRef = (key: string) => {
    console.assert(!!key, 'ref: Cannot set ref without key ');
    return map.get(key) as MutableRefObject<HTMLElement>;
  };

  return { getRef, setRef };
};
