import React, { useState, useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Icon, rightChevronIcon, closeIcon, LinkGenerator } from '@lululemon/ecom-pattern-library';
import cs from 'classnames';
import { useCart } from 'context';
import { useNavContext } from 'context/nav';
import { useFormat } from 'helpers/hooks/useFormat';
import { ACCOUNT } from 'helpers/utils/googleAnalyticsEvents';
import { createRefMap } from 'helpers/utils/nav-ref-map';
import { useAnalytics } from 'hooks/useAnalytics';
import { useAccount } from 'frontastic';
import styles from './accountMenu.module.scss';
const links = [
  { label: 'Profile', href: '/account#' },
  { label: 'Addresses', href: '/account#addresses' },
  { label: 'Purchase History', href: '/account#orders' },
  { label: 'User List', href: '/account#userlist' },
  { label: 'Billing Information', href: '/account#payment' },
];

interface Props {
  closePopup?: () => void;
}

const AccountDropdown: React.FC<Props> = ({ closePopup = () => null }) => {
  const { getRef, setRef } = createRefMap();
  const [refIndex, setRefIndex] = useState(-1);

  const router = useRouter();
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { logout, account } = useAccount();
  const { resetCart } = useCart();
  const { isAccountMenuListFocused, setSearchFocused } = useNavContext();
  //Google Analytics
  const { trackEvent, EVENT_CATEGORY } = useAnalytics();
  useEffect(() => {
    if (refIndex !== -1 && getRef(`account-item-${refIndex}`)) {
      getRef(`account-item-${refIndex}`).current.focus();
    }
  }, [refIndex]);

  useEffect(() => {
    if (isAccountMenuListFocused && getRef(`account-item-0`)) {
      getRef(`account-item-0`).current.focus();
      setRefIndex(0);
    }
  }, [isAccountMenuListFocused]);

  const handleKeydown = useCallback(
    (evt: any) => {
      const endOfList = links.length; // includes sign out link too
      switch (evt.key) {
        case 'ArrowDown': {
          evt.preventDefault();
          if (refIndex === -1) {
            setRefIndex(0);
          } else if (refIndex === endOfList) {
            setRefIndex(0);
          } else {
            setRefIndex(refIndex + 1);
          }
          break;
        }
        case 'ArrowUp': {
          evt.preventDefault();
          if (refIndex <= 0) {
            setRefIndex(endOfList);
          } else {
            setRefIndex(refIndex - 1);
          }
          break;
        }
        case 'Tab': {
          if (evt.shiftKey) {
            evt.preventDefault();
            setSearchFocused(true);
          }
          break;
        }
        case 'Escape': {
          evt.preventDefault();
          closePopup();
          break;
        }
        case 'Home': {
          evt.preventDefault();
          setRefIndex(0);
          break;
        }
        case 'Enter': {
          evt.preventDefault();
          if (refIndex === endOfList) {
            handleLogout(evt);
          } else {
            const index: number = links.findIndex((elem, index) => index === refIndex);
            if (index !== -1) router.push(links[index]?.href);
          }
          closePopup();
          break;
        }
        case 'End': {
          evt.preventDefault();
          setRefIndex(links.length - 1);
          break;
        }
        default:
          break;
      }
    },
    [refIndex],
  );

  const handleLogout = (evt: any) => {
    trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, ACCOUNT.ACCOUNT_MENU_ICON_CLICK('Sign Out'));
    evt.preventDefault();
    logout().then(() => {
      trackEvent(EVENT_CATEGORY.APP_RESPONSE, ACCOUNT.ACCOUNT_MENU_SIGN_OUT_CLICK);
      resetCart();
      router.replace('login').then(() => router.reload());
    });
  };

  const onClickLink = (href = '', label = '') => {
    trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, ACCOUNT.ACCOUNT_MENU_ICON_CLICK(label));
    closePopup();
    if (href) router.push(href);
  };

  const guestName = account?.firstName ?? '';
  return (
    <div className={styles.accountMenu} onKeyDown={handleKeydown} data-testid="account-menu_test-id">
      <div className={styles.greetingBlock}>
        <p className={styles.greetingMsg}>
          <span>Hi</span>
          {guestName && ` ${guestName}`}, <span>nice to see you.</span>
        </p>
        <Icon className={styles.closeIcon} content={closeIcon} title="close" onClick={closePopup} />
      </div>

      {links.map((link, idx) => {
        return (
          <div
            key={link?.label}
            data-testid="account-menu__list_test-id"
            onClick={() => onClickLink(link?.href, link?.label)}
            className={cs(styles.listItem, { [styles.lastListItem]: idx === links.length - 1 })}
          >
            <LinkGenerator className={styles.accountLink} tabIndex={-1} ref={setRef(`account-item-${idx}`)}>
              <span className={styles.underline}>{link?.label}</span>
              <Icon content={rightChevronIcon} title="rightChevronIcon" className={styles.rightChevron} />
            </LinkGenerator>
          </div>
        );
      })}

      <div className={styles.signOutLink}>
        <LinkGenerator
          className={styles.accountLink}
          tabIndex={-1}
          ref={setRef(`account-item-${links.length}`)}
          onClick={handleLogout}
        >
          <span className={styles.underline}>
            {formatAccountMessage({ id: 'sign.out', defaultMessage: 'Sign Out' })}
          </span>
        </LinkGenerator>
      </div>
    </div>
  );
};

export default AccountDropdown;
