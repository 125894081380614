import React from 'react';
import { ProductCarousel, ProductTileWithSwatches, Card } from '@lululemon/ecom-pattern-library';
import classnames from 'classnames';
import { toColorSwatchesAndImages } from 'helpers/mappers/toColorSwatchesAndImages';
import { randomMockPrice } from 'helpers/mocks/mockData';
import styles from './product-carousel.module.scss';
import { Data } from './types';

const ProductPromotionCarousel: React.FC<Data> = ({ data }) => {
  const productList = data?.data?.dataSource?.items;
  const DEFAULT_TILES_PER_PAGE = 4;
  const {
    title,
    tilesPerPage,
    promotionTitle,
    promotionDescription,
    promotionImage,
    promotionTargetText,
    promotionTarget,
    callToAction,
    webRoute,
    showProductCard,
  } = data;
  const tilePerPage = tilesPerPage ? tilesPerPage : DEFAULT_TILES_PER_PAGE;

  if (productList.length < 1) return null;

  return (
    <div data-testid="product-promotion-carousel_test-id" className={classnames(styles.carousel, 'lll-grid-padding')}>
      {showProductCard ? (
        <ProductCarousel tilesPerPage={tilePerPage} title={title} callToAction={callToAction} webRoute={webRoute}>
          {productList.map((product, index) => (
            <ProductTileWithSwatches
              key={index}
              showColorsOnHover={false}
              colors={toColorSwatchesAndImages(product)}
              href={product?._url}
              name={product?.name}
              priceProps={randomMockPrice(product?.variants)}
            />
          ))}
          <Card
            className={styles.carousel__card}
            ctaText={promotionTargetText}
            ctaURL={promotionTarget}
            heading={promotionTitle}
            imageProps={
              promotionImage && {
                src: promotionImage,
              }
            }
            subContent={promotionDescription}
          />
        </ProductCarousel>
      ) : (
        <ProductCarousel tilesPerPage={tilePerPage} title={title} callToAction={callToAction} webRoute={webRoute}>
          {productList.map((product, index) => (
            <ProductTileWithSwatches
              key={index}
              showColorsOnHover={false}
              colors={toColorSwatchesAndImages(product)}
              href={product?._url}
              name={product?.name}
              priceProps={randomMockPrice(product?.variants)}
            />
          ))}
        </ProductCarousel>
      )}
    </div>
  );
};

export default ProductPromotionCarousel;
