export const ACCESSORIES = 'accessories';
export const FEATURES = 'features';
export const BAGS = 'bags';
export const WOMEN = 'women';
export const MEN = 'men';
export const ROOT_CATEGORY_SLUG = 'lululemon';
export const MEN_SOCKS = 'men-socks';
export const WOMEN_SOCKS = 'women-socks';
export const MEN_PANTS = 'men-pants';
export const MEN_SHIRTS = 'men-tops';
export const WOMEN_PANTS = 'women-pants';
export const WOMEN_SHIRTS = 'women-maintops';
