import React, { FC } from 'react';
import { Notification, Link } from '@lululemon/ecom-pattern-library';
import cx from 'classnames';
import { useFormat } from 'helpers/hooks/useFormat';
import styles from './checkoutNotification.module.scss';

export interface CheckoutNotificationType {
  message?: string | React.JSX.Element;
  show: boolean;
  type?: string;
  showTryAgain?: boolean;
  tryAgainCallback?: (() => void) | null;
  className?: string;
  name?: string;
}

const CheckoutNotification: FC<CheckoutNotificationType> = ({
  message,
  show,
  type,
  showTryAgain,
  tryAgainCallback,
  className,
  name = 'checkout',
}) => {
  const { formatMessage } = useFormat({ name: 'checkout' });

  return (
    <Notification
      type={type || 'error'}
      visible={show}
      className={cx(styles.notification, className)}
      data-testid={`${name}__notification_test-id`}
    >
      {message}

      {showTryAgain && (
        <Link
          tag="button"
          onClick={() => {
            tryAgainCallback?.();
          }}
          data-testid={`${name}__notification-link_test-id`}
        >
          {formatMessage({
            id: 'try.again',
            defaultMessage: 'Try Again',
          })}
        </Link>
      )}
    </Notification>
  );
};

export default CheckoutNotification;
