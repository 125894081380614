import { COMPONENT_TYPE } from 'helpers/constants/analyticsEventType';

export const AUTH = {
  RESET_PASSWORD_BUTTON_CLICKED: {
    component: {
      id: 'reset_password_initiated',
      type: COMPONENT_TYPE.BUTTON,
      text: 'Reset Password',
    },
  },
  SIGN_IN_INITIATED: {
    component: {
      id: 'sign_in_initiated',
      type: COMPONENT_TYPE.BUTTON,
      text: 'Sign In',
    },
  },
  FORGOT_PASSWORD_LINK: {
    component: {
      id: 'forgot_password_link',
      type: COMPONENT_TYPE.LINK,
      text: 'Forgot your password?',
    },
  },
  ACCOUNT_LOCKOUT: {
    component: {
      id: 'account_lockout',
      type: COMPONENT_TYPE.EVENT,
      text: 'Account lockout',
    },
  },
  ACCOUNT_LOCKOUT_ERROR: {
    error: {
      errorType: 'login',
      errorDetails: 'Account locked',
      errorGuestFacing: true,
      errorMessage:
        'Your account has been locked due to multiple failed attempts. Please reset your password by using the "Forgot Password" link.',
    },
  },
  LOGIN_SUCCESS: (role: any) => {
    return {
      component: {
        id: 'login_success',
        type: COMPONENT_TYPE.EVENT,
        text: 'Login success',
      },
      attributes: {
        details: { data: role },
      },
    };
  },
  LOGIN_API_RESPONSE: {
    id: 'sign_in_success',
    event: {
      attributes: {
        details: 'success',
      },
    },
  },
  FORGOT_PASSWORD_API_RESPONSE: {
    event: {
      id: 'reset_password_success',
      attributes: {
        details: 'Api call response',
      },
    },
  },
  FORGOT_PASSWORD_API_ERROR: {
    error: {
      errorType: 'password_reset',
      errorDetails: 'Check your email and try again',
      errorGuestFacing: true,
      errorMessage: 'Check your email and try again',
    },
  },
  SIGN_OUT_API_RESPONSE: {
    event: {
      id: 'logout_api_success',
      attributes: {
        details: 'Logout api call success response',
      },
    },
  },
  LOGIN_API_ERROR: (errorDetails: any) => {
    return {
      error: {
        errorType: 'login',
        errorDetails: 'Check your email or password and try again',
        errorGuestFacing: true,
        errorMessage: 'Check your email or password and try again',
      },
    };
  },
  LOGOUT_API_ERROR: (errorDetails: any) => {
    return {
      error: { errorType: 'logout_api_error', errorMessage: 'Error in calling logout API', errorDetails },
    };
  },
  GET_PASSWORD_RESET_BY_TOKEN_API_ERROR: (errorDetails: any) => {
    return {
      error: {
        errorType: 'get_password_reset_by_token_api_error',
        errorMessage: 'Error in calling get password reset by token API',
        errorDetails,
      },
    };
  },
  GET_PASSWORD_RESET_BY_TOKEN_API_RESPONSE: {
    event: {
      id: 'get_password_reset_by_token_api_success',
      attributes: {
        details: 'Get password reset by token api call success response',
      },
    },
  },

  RESET_PASSWORD_API_ERROR: (errorDetails: any) => {
    return {
      error: {
        errorType: 'reset_password_api_error',
        errorMessage: 'Error in calling reset password api error API',
        errorDetails,
      },
    };
  },

  RESET_PASSWORD_API_RESPONSE: {
    event: {
      id: 'reset_password_api_response',
      attributes: {
        details: 'Reset password api call success response',
      },
    },
  },
};
