import cs from 'classnames';
import Accordion from 'components/commercetools-ui/atoms/accordion';
import { Column } from './column';
import styles from './detailed-footer.module.scss';
import { Legal } from './legal';
import { FooterColumnProps } from './types';

const DetailedFooter = ({ columns }: { columns: FooterColumnProps[] }) => {
  return (
    <footer aria-labelledby="footer-heading" data-testid="footer_test-id" className={styles.footer}>
      <div className={cs('lll-grid', styles.gridContainer)} data-testid="footer__grid-container_test-id">
        {columns.slice(0, 4).map((column, index) => (
          <Column columnIndex={index} column={column} key={index} />
        ))}
        <Legal columns={columns} />
      </div>

      {/* Accordion will only be displayed on mobile & tablet <992px */}
      <div className={styles.accordion} data-testid="footer__accordion-container_test-id">
        {columns.slice(0, 4).map((column, index) => (
          <Accordion
            variant="arrow"
            closedSectionTitle={column.header}
            openSectionTitle={column.header}
            buttonClassName={styles.accordionButton}
            iconClassName={styles.accordionIcon}
            key={index}
          >
            <Column columnIndex={index} column={column} />
          </Accordion>
        ))}
        <Legal columns={columns} />
      </div>
    </footer>
  );
};

export default DetailedFooter;
