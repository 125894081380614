import { useState, useEffect } from 'react';
import { ProductCarousel, ProductTileWithSwatches, Card, LinkProvider } from '@lululemon/ecom-pattern-library';
import Skeleton from 'react-loading-skeleton';
import { Product } from 'shared/types/product/Product';
import categoryQueryParams from 'helpers/categoryQueryParam';
import { ConditionalLink } from 'helpers/cocoLink';
import { useProduct } from 'helpers/hooks/useProduct';
import { toColorSwatchesAndImages } from 'helpers/mappers/toColorSwatchesAndImages';
import { toProductCollectionList } from 'helpers/mappers/toProductCollectionList';
import { getPrice } from 'helpers/priceHelper';
import { HOME_PAGE } from 'helpers/utils/googleAnalyticsEvents';
import { useValidLocale } from 'hooks';
import { useAnalytics } from 'hooks/useAnalytics';
import { useProductList } from 'ui/cdp/context';
import { useAccount } from 'frontastic/index';
import styles from './category-carousel.module.scss';

export interface CategoryData {
  data: CategoryProductCarousel;
}

export interface CategoryProductCarousel {
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
  title: string;
  callToAction: string;
  webRoute: string;
  tilesPerPage: number;
  categorySlug: string;
  totalProducts: number;
  promotionTitle: string;
  promotionDescription: string;
  promotionImage: string;
  promotionTargetText: string;
  promotionTarget: string;
  showPromotionCard: boolean;
}

interface CardProducts extends Product {
  retailPriceRange: string[];
  wholesalePriceRange: string[];
}

const CategoryPromotionCarousel = ({ data }: CategoryData) => {
  const DEFAULT_TILES_PER_PAGE = 4;
  const {
    title,
    tilesPerPage,
    categorySlug,
    totalProducts,
    promotionTitle,
    promotionDescription,
    promotionImage,
    promotionTargetText,
    promotionTarget,
    callToAction,
    webRoute,
    showPromotionCard,
  } = data;
  const tilePerPage = tilesPerPage || DEFAULT_TILES_PER_PAGE;
  const { categories, getCategoryPromotionCarouselProducts } = useProduct();
  const [items, setItems] = useState<CardProducts[]>([]);
  const [isLoading, setLoading] = useState(false);
  const { account } = useAccount();
  const { limitStep } = useProductList();
  const locale = useValidLocale();

  //category link with all query params
  const categoryParams = categoryQueryParams(account, limitStep, null);
  const CategoryTarget = webRoute ? `${webRoute}?${categoryParams}` : webRoute;
  //Google Analytics
  const { trackEvent, EVENT_CATEGORY } = useAnalytics();

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      if (categorySlug && categories.length) {
        try {
          const categoryResult = categories.filter((item) => {
            return item.slug === categorySlug;
          });
          if (categoryResult?.length) {
            const categoryId: string = categoryResult[0]?.categoryId ?? '';
            const response: any = await getCategoryPromotionCarouselProducts(categoryId, totalProducts);
            setItems(response?.items || []);
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
        }
      }
    };
    fetchProducts();
  }, [categories.length]);

  if (!categorySlug || !items?.length) {
    return null;
  }

  const carouselItems = items.map((product, index) => (
    <ProductTileWithSwatches
      key={`category-promotion-${product?.name}-${index}`}
      showColorsOnHover={false}
      data-testid={`category-promotion-carousel_product-${index}_test-id`}
      colors={toColorSwatchesAndImages(product)}
      href={product?._url}
      name={product?.name}
      priceProps={getPrice(product, locale)}
      onClick={() => {
        trackEvent(
          EVENT_CATEGORY.PRODUCT_INTERACTION,
          HOME_PAGE.PROMOTION_CAROUSEL_TILE_CLICKED(
            toProductCollectionList([product], 'product-carousal', 'inventory_grid'),
            product?.name ?? '',
          ),
        );
      }}
    />
  ));

  if (showPromotionCard) {
    carouselItems.push(
      <Card
        className={styles.carousel__card}
        ctaText={promotionTargetText}
        ctaURL={promotionTarget}
        heading={promotionTitle}
        data-testid={`category-promotion-carousel_card_test-id`}
        key={'promotion-card'}
        imageProps={
          promotionImage && {
            src: promotionImage,
          }
        }
        subContent={promotionDescription}
        onClick={() => {
          trackEvent(
            EVENT_CATEGORY.COMPONENT_EVENT,
            HOME_PAGE.PROMOTION_CAROUSEL_CARD_CLICKED(promotionTargetText, promotionTarget),
          );
        }}
      />,
    );
  }

  return (
    <div className={styles.carousel}>
      {isLoading ? (
        <Skeleton count={1} className={styles.loading} />
      ) : (
        <LinkProvider component={ConditionalLink}>
          <ProductCarousel
            tilesPerPage={tilePerPage}
            title={title}
            callToAction={
              <h1
                onClick={(event) => {
                  trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, HOME_PAGE.SHOP_ALL_BUTTON(callToAction));
                }}
              >
                {callToAction}
              </h1>
            }
            webRoute={CategoryTarget}
          >
            {carouselItems}
          </ProductCarousel>
        </LinkProvider>
      )}
    </div>
  );
};

export default CategoryPromotionCarousel;
