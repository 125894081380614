import React, { useState, FC, Dispatch, SetStateAction } from 'react';
import { useRouter } from 'next/router';
import sortBy from 'lodash/sortBy';
import { Address } from 'shared/types/account';
import { NgcErrorType } from 'shared/types/error';
import { ShippingMethod } from 'shared/types/ngcCart';
import { useCart } from 'context';
import { parseNGCError } from 'helpers/ngc';
import { NgcErrorCodes } from 'helpers/utils/ngcResponseUtil';
import { useBusinessUnit } from 'frontastic/hooks';
import OrderReference from './order-reference';
import ShippingAddress from './shipping-address';
import ShippingMethods from './shipping-methods';
import styles from './shipping-wrapper.module.scss';
import { CheckoutError, ShippingMethodError } from '../../index';
import { StatusCode } from 'ui/checkout/utils/constant';

export interface ShippingWrapperProps {
  poNumber: string;
  setPoNumber: Dispatch<SetStateAction<string>>;
  poNumberError: CheckoutError;
  loadingShippingMethod: boolean;
  setLoadingShippingMethod: (value: boolean) => void;
}

export const sortData = (data: ShippingMethod[]) => {
  return sortBy(data, (shippingMethod) => shippingMethod.shippingPrice?.total);
};

const ShippingWrapper: FC<ShippingWrapperProps> = ({
  poNumber,
  setPoNumber,
  poNumberError,
  loadingShippingMethod,
  setLoadingShippingMethod,
}) => {
  const initialError = {
    isError: false,
    callBack: null,
    showTryAgain: false,
    errorType: null,
  };
  const router = useRouter();
  const { cart, getShippingMethods, updateShippingAddress, updateShippingMethod, resetCart, initializeCart } =
    useCart();
  const [shippingAddressError, setShippingAddressError] = useState<CheckoutError>(initialError);
  const [shippingMethodError, setShippingMethodError] = useState<ShippingMethodError>(initialError);

  const { shippingAddresses } = useBusinessUnit();
  const [shippingMethodsListData, setShippingMethodsListData] = useState<ShippingMethod[]>();

  const [loadingShippingAddress, setLoadingShippingAddress] = useState(false);

  const [selectedShippingAddress, setSelectedShippingAddress] = useState<Address>();
  const [selectedShippingMethod, setSelectedShippingMethod] = useState<ShippingMethod>();
  const [apiFailureMethod, setApiFailureMethod] = useState<ShippingMethod>();

  const handleFetchShippingMethodsError = (error: NgcErrorType, callback: () => void) => {
    setLoadingShippingMethod(false);
    let showTryAgain = false;
    if (
      error?.code === NgcErrorCodes.CART_NOT_FOUND ||
      error?.code === NgcErrorCodes.OPERATION_NOT_PERMITTED ||
      error?.code === NgcErrorCodes.SHIPPING_ADDRESS_NULL
    ) {
      showTryAgain = true;
    } else if (error?.code === NgcErrorCodes.CART_ALREADY_ORDERED) {
      router.push('/something?isCartAlreadyOrdered=true');
    }
    setShippingMethodError({
      isError: true,
      callBack: () => {
        if (callback) {
          callback();
        }
      },
      showTryAgain: true,
      errorType: 'getShippingMethod',
    });
  };

  // copy changes for this error, try again
  const fetchShippingMethods = async () => {
    setLoadingShippingMethod(true);
    setShippingMethodError(initialError);
    try {
      const shippingMethodsResponse = await getShippingMethods();
      const sortedData = sortData(shippingMethodsResponse);
      setShippingMethodsListData(sortedData);
      setLoadingShippingMethod(false);
    } catch (error) {
      const parsedError = parseNGCError(error);
      handleFetchShippingMethodsError(parsedError, () => fetchShippingMethods());
    }
  };

  const handleUpdateShippingMethodError = (callback: () => void) => {
    setLoadingShippingMethod(false);
    setShippingMethodError({
      isError: true,
      callBack: () => {
        if (callback) {
          callback();
        }
      },
      showTryAgain: true,
      errorType: 'updateShippingMethod',
    });
  };

  const updateShippingMethodToCartWrapper = async (shippingMethod: ShippingMethod, retry = false) => {
    setLoadingShippingMethod(true);
    setShippingMethodError(initialError);
    if (shippingMethod !== undefined) {
      const shippingMethodId = shippingMethod.id as string;
      const apiFailureMethodId = apiFailureMethod?.id as string;

      try {
        const updatedCart = await updateShippingMethod(retry ? apiFailureMethodId : shippingMethodId, retry);
        setSelectedShippingMethod(shippingMethod);
        setLoadingShippingMethod(false);
        return updatedCart;
      } catch (error) {
        const parsedError = parseNGCError(error);
        if (parsedError?.status === StatusCode.TIMEOUT) {
          const updatedCart = await updateShippingMethodToCartWrapper(shippingMethod, true);
          if (updatedCart && cart) {
            const isMethodUpdated =
              updatedCart?.shippingInfo?.id === shippingMethod.id &&
              updatedCart.cartTotal?.taxStatus === 'S' &&
              updatedCart.cartVersion > cart?.cartVersion &&
              new Date(updatedCart.lastModifiedAt).getTime() > new Date(cart?.lastModifiedAt).getTime();
            if (isMethodUpdated) {
              setSelectedShippingMethod(shippingMethod);
              setLoadingShippingMethod(false);
            } else {
              setApiFailureMethod(shippingMethod);
              handleUpdateShippingMethodError(() => updateShippingMethodToCartWrapper(shippingMethod, false));
            }
          }
        } else {
          // This is the case when update shipping still fails but it's not timeout
          // We handle it the same way
          setApiFailureMethod(shippingMethod);
          handleUpdateShippingMethodError(() => updateShippingMethodToCartWrapper(shippingMethod, false));
        }
      }
    }
  };

  const updateShippingAddressErrorHandler = (addressId: string, error: NgcErrorType) => {
    setLoadingShippingAddress(false);
    let showTryAgain = false;
    if (
      error?.code === NgcErrorCodes.CART_NOT_FOUND ||
      error?.code === NgcErrorCodes.OPERATION_NOT_PERMITTED ||
      error?.code === NgcErrorCodes.SHIPPING_ADDRESS_NULL
    ) {
      showTryAgain = true;
    } else if (error?.code === NgcErrorCodes.CART_ALREADY_ORDERED) {
      router.push('/something?isCartAlreadyOrdered=true');
    }
    setShippingAddressError({
      isError: true,
      callBack: () => {
        updateShippingAddressHandler(addressId, true);
      },
      showTryAgain,
    });
  };

  const updateShippingAddressHandler = async (addressId: string, isRetry = false, initialLoad = false) => {
    setLoadingShippingAddress(true);
    setShippingAddressError(initialError);
    if (initialLoad) {
      setShippingAddressOnInitialRender();
      return;
    }
    try {
      await updateShippingAddress(addressId, isRetry);
      setShippingAddressOnInitialRender();
    } catch (error) {
      const parsedError = parseNGCError(error);
      updateShippingAddressErrorHandler(addressId, parsedError);
    }
  };

  const setShippingAddressOnInitialRender = () => {
    setLoadingShippingAddress(false);
    setSelectedShippingAddress(shippingAddresses.find((val) => val.addressId === cart?.shippingAddress?.addressId));
    fetchShippingMethods();
  };

  return (
    <div className={styles.shippingWrapperContainer}>
      <div data-testid="shipping-information__address_test-id">
        <ShippingAddress
          onChangeHandler={updateShippingAddressHandler}
          isLoading={loadingShippingAddress}
          setSelectedShippingAddress={setSelectedShippingAddress}
          shippingAddressError={shippingAddressError}
          setShippingAddressError={setShippingAddressError}
        />
      </div>

      <div data-testid="shipping-information__methods_test-id">
        <ShippingMethods
          fetchShippingMethods={fetchShippingMethods}
          onChangeHandler={updateShippingMethodToCartWrapper}
          shippingMethodsListData={shippingMethodsListData || []}
          isLoading={loadingShippingMethod}
          selectedShippingMethod={selectedShippingMethod as ShippingMethod}
          setSelectedShippingMethod={setSelectedShippingMethod}
          shippingMethodError={shippingMethodError}
        />
      </div>
      <OrderReference setPoNumber={setPoNumber} poNumber={poNumber} poNumberError={poNumberError} />
    </div>
  );
};

export default ShippingWrapper;
