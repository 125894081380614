import { FooterLinkProps, LocaleSpecific } from './types';
export const filteredLocaleSpecificFooterData = (footerData: FooterLinkProps[], locale?: string) => {
  return footerData.filter(({ localeSpecific }) => {
    if (!localeSpecific) {
      return true;
    }
    if (localeSpecific === LocaleSpecific.BOTH) {
      return true;
    }
    if (localeSpecific === LocaleSpecific.EN_US && locale === LocaleSpecific.EN_US) {
      return true;
    }
    if (
      (localeSpecific === LocaleSpecific.EN_CA && locale === LocaleSpecific.EN_CA) ||
      locale === LocaleSpecific.FR_CA
    ) {
      return true;
    }
    return false;
  });
};
