import { FEATURE_FLAG_LIST, useFeatureFlags } from 'hooks';
import React from 'react';
import DetailedFooter from 'ui/navigation/components/detailed-footer';
import { FooterLinkProps } from 'ui/navigation/components/detailed-footer/types';

interface Props {
  data: {
    linksCol1: FooterLinkProps[];
    linksCol2: FooterLinkProps[];
    linksCol3: FooterLinkProps[];
    linksCol4: FooterLinkProps[];
    linksCol5: FooterLinkProps[];
    headerCol1: string;
    headerCol2: string;
    headerCol3: string;
    headerCol4: string;
    headerCol5: string;
  };
}

const DetailedFooterTastic = ({ data }: Props) => {
  const { isFeatureEnabled } = useFeatureFlags();

  const columns = [
    {
      header: data.headerCol1,
      links: data.linksCol1,
    },
    {
      header: data.headerCol2,
      links: data.linksCol2,
    },
    {
      header: data.headerCol3,
      links: data.linksCol3,
    },
    {
      header: data.headerCol4,
      links: data.linksCol4,
    },
    {
      header: data.headerCol5,
      links: data.linksCol5,
    },
  ];

  return isFeatureEnabled(FEATURE_FLAG_LIST.HOME_PAGE) ? <DetailedFooter columns={columns} /> : null;
};

export default DetailedFooterTastic;
