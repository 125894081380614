import NextLink from 'next/link';
import { Link } from '@lululemon/ecom-pattern-library';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic/index';
import styles from './legal.module.scss';
import { FooterColumnProps, LocaleByPartnerCountry } from './types';
import { filteredLocaleSpecificFooterData } from './utils';

export const Legal = ({ columns }: { columns: FooterColumnProps[] }) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const legalColumns = columns.find((column) => column.header === 'Legal')?.links || [];

  const { account } = useAccount();

  const partnerCountry = account?.companyProfile?.partnerCountry;

  const localeValue = partnerCountry
    ? LocaleByPartnerCountry[partnerCountry as keyof typeof LocaleByPartnerCountry]
    : '';

  const filteredLocaleSpecificLinks = filteredLocaleSpecificFooterData(legalColumns, localeValue);

  return (
    <div className={styles.legalContainer} data-testid="footer__legal-footer_test-id">
      <div className={styles.copyright}>
        {formatMessage({
          id: 'copyright',
          defaultMessage: '© lululemon athletica 1818 Cornwall Ave, Vancouver BC V6J 1C7',
        })}
      </div>
      <div className={styles.legalLinksContainer}>
        {filteredLocaleSpecificLinks.map(({ name, reference }, index) => (
          <Link
            className={styles.legalLink}
            data-testid={`footer__legal-link-${index}_test-id`}
            tag="div"
            textStyle="body-3"
            variant="underlineAnimated"
            key={index}
          >
            <NextLink href={reference.type === 'link' ? reference.link : reference.pageFolder._url}>
              <a
                rel={reference.openInNewWindow ? 'noopener' : undefined}
                target={reference.openInNewWindow ? '_blank' : undefined}
              >
                {name}
              </a>
            </NextLink>
          </Link>
        ))}
      </div>
    </div>
  );
};
