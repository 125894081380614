import { useState } from 'react';
import { getScrollbarWidth } from 'helpers/utils/getScrollbarWidth';
import useTouchDevice from './useTouchDevice';

const useScrollBlock = () => {
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const { isTouchDevice } = useTouchDevice();

  const blockScrolling = () => {
    const scrollbarWidth = getScrollbarWidth();

    if (!isTouchDevice) {
      document.body.style.paddingRight = `${scrollbarWidth}px`;
      const fixedHeader = document?.getElementById('fixedHeader');
      if (fixedHeader) fixedHeader.style.width = `calc(100% - ${scrollbarWidth}px)`;
    }

    document.body.style.overflowY = 'hidden';
  };

  const allowScrolling = () => {
    document.body.style.overflowY = 'auto';

    if (!isTouchDevice) {
      document.body.style.paddingRight = '0px';
      const elem = document?.getElementById('fixedHeader');
      if (elem) elem.style.width = '100%';
    }
  };

  const blockScroll = (status: boolean) => {
    if (status) blockScrolling();
    else allowScrolling();

    setIsBlocked(status);
  };

  return { isBlocked, blockScroll };
};

export default useScrollBlock;
