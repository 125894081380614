import { Price } from '@lululemon/ecom-pattern-library';
import { ShippingMethod } from 'shared/types/ngcCart';

type ShippingCost = number | string | undefined;

export const renderShippingPrice = (shippingCost: ShippingCost, freeText: string): string | React.ReactElement => {
  if (shippingCost === undefined) return '-';

  return shippingCost === 0 ? freeText : <Price prices={Number(shippingCost)} showDecimals />;
};

export const formatShippingInfo = (shippingInfo: ShippingMethod, freeText: string) => {
  return (
    <div>
      {shippingInfo.description && <div className="lll-font-weight-medium">{shippingInfo.description}</div>}
      <div>
        {shippingInfo.name} ({renderShippingPrice(shippingInfo.shippingPrice?.subTotal, freeText)})
      </div>
    </div>
  );
};
