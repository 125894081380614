export class ArrayHelpers {
  static chunk = <T>(input: T[], size: number): T[][] => {
    return input.reduce((array, item, index) => {
      return index % size === 0 ? [...array, [item]] : [...array.slice(0, -1), [...array.slice(-1)[0], item]];
    }, [] as T[][]);
  };
}

// Function to split elements into column having of max column size of specified parameter value
// and to create some equal sizes columns
export function splitItemsIntoColumns(items: Array<any>, maxColumnSize: number) {
  const columnCount = Math.ceil(items.length / maxColumnSize); // Calculate the number of columns needed
  const itemsPerColumn = Math.ceil(items.length / columnCount); // Calculate the number of items per column
  const columns = [];

  for (let i = 0; i < columnCount; i++) {
    const columnItems = items.slice(i * itemsPerColumn, (i + 1) * itemsPerColumn);
    columns.push(columnItems);
  }

  return columns;
}

// Function to check if arrays are equal
export function areArraysEqualSet(arr1: string[], arr2: string[]): boolean {
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
}
