import { COMPONENT_TYPE } from 'helpers/constants/analyticsEventType';

export const BREADCRUMB_EVENT = {
  BREADCRUMB_LINK_CLICK: (linkText: string | undefined) => {
    return {
      eventSubType: 'interaction',
      component: {
        id: 'breadcrumb_link',
        type: COMPONENT_TYPE.LINK,
        text: linkText,
      },
    };
  },
};
