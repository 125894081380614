import { Address } from 'shared/types/account';
import { NgcAddress } from 'shared/types/account/Address';

export const cleanValue = (value: string | undefined): string => value || '';

export const cleanAddress = (address: NgcAddress): Record<keyof NgcAddress, string> => {
  const cleanedAddress: Record<keyof NgcAddress, string> = {} as Record<keyof NgcAddress, string>;

  Object.keys(address).forEach((key) => {
    const typedKey = key as keyof NgcAddress;
    const value = address[typedKey] as string | undefined;
    cleanedAddress[typedKey] = cleanValue(value);
  });

  return cleanedAddress;
};

export const formatAddress = (address: NgcAddress, isUsernameDisplayFlag: boolean): string => {
  const cleanedAddress = cleanAddress(address);

  const namePart = isUsernameDisplayFlag
    ? [cleanedAddress.firstName, cleanedAddress.lastName].filter(Boolean).join(' ')
    : '';

  const streetPart = [cleanedAddress.streetNumber, cleanedAddress.streetName].filter(Boolean).join(' ');

  const addressParts: string[] = [
    namePart,
    streetPart,
    cleanedAddress.addressLine1,
    cleanedAddress.addressLine2,
    cleanedAddress.city,
    cleanedAddress.state,
    cleanedAddress.postalCode,
    cleanedAddress.country,
  ].filter(Boolean);

  const formattedAddressParts = addressParts.join(', ');

  const maxLength = 80;
  return formattedAddressParts.length > maxLength
    ? formattedAddressParts.slice(0, maxLength - 1) + '...'
    : formattedAddressParts;
};

export const formatAddressDisplay = (address: Address | NgcAddress, isUsernameDisplayFlag = true): string => {
  return formatAddress(address, isUsernameDisplayFlag);
};

export const getFormattedAddress = (address: Address | NgcAddress): JSX.Element => {
  const formattedAddress = formatAddress(address, true);
  return <div>{formattedAddress}</div>;
};

export const getFormattedAddressinMultiline = (address: NgcAddress) => {
  const cleanedAddress = cleanAddress(address);

  const userName = (
    <div>
      {cleanedAddress.firstName || cleanedAddress.lastName
        ? `${[cleanedAddress.firstName, cleanedAddress.lastName].filter(Boolean).join(' ')}, `
        : ''}
    </div>
  );

  const addressLine1 = (
    <div>
      {cleanedAddress.addressLine1 ? `${cleanedAddress.addressLine1} ` : ''}
      {cleanedAddress.addressLine2 ? `${cleanedAddress.addressLine2}, ` : ''}
      {cleanedAddress.streetNumber || cleanedAddress.streetName
        ? `${[cleanedAddress.streetNumber, cleanedAddress.streetName].filter(Boolean).join(' ')}, `
        : ''}
    </div>
  );

  const addressLine2 = (
    <div>
      {[cleanedAddress.city, cleanedAddress.state, cleanedAddress.postalCode].filter(Boolean).join(', ')}
      {cleanedAddress.country ? `, ${cleanedAddress.country}` : ''}
    </div>
  );

  return (
    <div>
      {userName}
      {addressLine1}
      {addressLine2}
    </div>
  );
};

export const getAddressFilter = (inputValue: string) => {
  const lowerCasedInputValue = inputValue.toLowerCase();
  const searchTerms = lowerCasedInputValue.split(/[\s,]+/);
  return function addressFilter(address: Address) {
    for (const term of searchTerms) {
      if (!Object.values(address).some((value) => typeof value === 'string' && value.toLowerCase().includes(term))) {
        return false;
      }
    }
    return true;
  };
};
