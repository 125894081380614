import { Heading } from '@lululemon/ecom-pattern-library';
import cs from 'classnames';
import styles from './legal-page.module.scss';
import { renderedText } from './utils';

export type LegalPageProps = {
  heading: string;
  lastUpdatedDate?: string;
  mainContentSection?: {
    mainSectionContent: string;
  }[];
  legalSection?: {
    legalSectionId?: string;
    legalSectionHeading: string;
    legalSectionContent?: string;
    legalSubsection?: {
      legalSubsectionId?: string;
      legalSubsectionHeading: string;
      legalSubsectionContent?: string;
    }[];
  }[];
};

const LegalPage = (data: LegalPageProps) => {
  const { heading, lastUpdatedDate, mainContentSection, legalSection } = data;

  return (
    <div className={styles.container} data-testid="legal-page_test-id">
      <div
        className={
          heading === 'n/a'
            ? cs('lll-grid lll-grid-padding', styles.gridContainer)
            : cs('lll-grid lll-grid-padding', styles.gridContainer, styles.headerMargin)
        }
      >
        {heading !== 'n/a' && (
          <Heading
            data-testid="legal-page__heading_test-id"
            wrapperClassName={styles.heading}
            className="lll-text-large"
            tag="h1"
          >
            {heading}
          </Heading>
        )}
        {lastUpdatedDate && (
          <div data-testid="legal-page__date_test-id" className={styles.lastUpdatedDate}>
            {lastUpdatedDate}
          </div>
        )}
        <div className={styles.contentContainer}>
          {mainContentSection && mainContentSection[0].mainSectionContent && (
            <div data-testid="legal-page__main-content_test-id" className={styles.contentText}>
              {renderedText(mainContentSection[0].mainSectionContent)}
            </div>
          )}
          {legalSection?.map(({ legalSectionHeading, legalSectionContent, legalSectionId, legalSubsection }, index) => {
            return (
              <div id={legalSectionId} key={index} className={styles.contentSection}>
                {legalSectionHeading && (
                  <Heading
                    data-testid={`legal-page__section-heading-${index}_test-id`}
                    wrapperClassName={styles.sectionHeading}
                    className="lll-text-button"
                    tag="h2"
                  >
                    {legalSectionHeading}
                  </Heading>
                )}
                {legalSectionContent && (
                  <div data-testid={`legal-page__section-content-${index}_test-id`} className={styles.contentText}>
                    {renderedText(legalSectionContent)}
                  </div>
                )}
                {legalSubsection?.map(
                  ({ legalSubsectionHeading, legalSubsectionContent, legalSubsectionId }, index) => {
                    return (
                      <div id={legalSubsectionId} key={index}>
                        <Heading
                          data-testid={`legal-page__subsection-heading-${index}_test-id`}
                          className="lll-text-subtitle"
                          tag="h3"
                        >
                          {legalSubsectionHeading}
                        </Heading>
                        {legalSubsectionContent && (
                          <div
                            data-testid={`legal-page__subsection-content-${index}_test-id`}
                            className={styles.contentText}
                          >
                            {renderedText(legalSubsectionContent)}
                          </div>
                        )}
                      </div>
                    );
                  },
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LegalPage;
