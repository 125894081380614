import React, { useEffect, useState, useCallback } from 'react';
import { useRouter } from 'next/router';
import { Link } from '@lululemon/ecom-pattern-library';
import { Level3NavItem } from 'shared/types/nav';
import { useNavContext } from 'context/nav';
import categoryQueryParams from 'helpers/categoryQueryParam';
import { HEADER } from 'helpers/utils/googleAnalyticsEvents/header';
import { createRefMap } from 'helpers/utils/nav-ref-map';
import { useAnalytics } from 'hooks/useAnalytics';
import { useProductList } from 'ui/cdp/context';
import { useAccount } from 'frontastic/index';
import styles from './features.module.scss';

const Features = () => {
  const { getRef, setRef } = createRefMap();
  const [featureItemIndex, setFeatureItemIndex] = useState(0);
  const router = useRouter();
  const { account } = useAccount();
  const { limitStep } = useProductList();

  const { trackEvent, EVENT_CATEGORY } = useAnalytics();

  const {
    setActiveL2Index,
    activeL2Index,
    handleTabPress,
    handleEscapePress,
    featuresActivated,
    setFeaturesActivated,
    setSubcategoriesActivated,
    activeCategory,
    handleCloseDetailedNav,
  } = useNavContext();
  const totalMainColumnCount = activeCategory?.totalMainColumnCount || 1;
  const featureLinks = activeCategory?.featureLinks;
  const featuresLength = featureLinks?.length || 0;

  const handleKeyDown = useCallback(
    (evt) => {
      if (!featuresActivated) return;
      switch (evt.key) {
        case 'ArrowDown': {
          evt.preventDefault();

          if (featureItemIndex === featuresLength - 1) {
            setFeatureItemIndex(0);
          } else {
            setFeatureItemIndex(featureItemIndex + 1);
          }
          break;
        }
        case 'ArrowUp': {
          evt.preventDefault();
          if (featureItemIndex === 0) {
            setFeatureItemIndex(featuresLength - 1);
          } else {
            setFeatureItemIndex(featureItemIndex - 1);
          }
          break;
        }
        case 'ArrowRight': {
          evt.preventDefault();
          setFeaturesActivated(false);
          setSubcategoriesActivated(true);
          setActiveL2Index(activeL2Index + 1);
          setFeatureItemIndex(0);
          break;
        }
        case 'ArrowLeft': {
          evt.preventDefault();
          setFeaturesActivated(false);
          setSubcategoriesActivated(true);
          setActiveL2Index(totalMainColumnCount - 1);
          setFeatureItemIndex(0);
          break;
        }
        case 'Tab': {
          evt.preventDefault();
          handleTabPress(evt.shiftKey);
          setFeatureItemIndex(0);
          break;
        }
        case 'Escape': {
          evt.preventDefault();
          handleEscapePress();
          setFeatureItemIndex(0);
          break;
        }
        default:
          break;
      }
    },
    [activeL2Index, featureItemIndex, featuresActivated],
  );

  useEffect(() => {
    if (featuresActivated) {
      getRef(`features-${featureItemIndex}`)?.current.focus();
    }
  }, [featureItemIndex, featuresActivated]);

  const onClickLink = (featureLink: Level3NavItem) => {
    trackEvent(EVENT_CATEGORY.COMPONENT_EVENT, HEADER.HEADER_SUB_NAV_CLICK(featureLink.name));

    const { path = '', gender } = featureLink;
    const queryParams = new URLSearchParams();
    if (gender) {
      queryParams.append('gender', gender);
    }
    //category link with all query params
    const categoryParams = categoryQueryParams(account, limitStep, queryParams);
    const link = `${path}?${categoryParams}`;
    router.push(link);
    handleCloseDetailedNav();
  };

  return (
    <div
      className={styles.featuresWrapper}
      role="feature-item"
      data-testid="feature__wrapper_test-id"
      onKeyDown={handleKeyDown}
    >
      <ul className={styles.featuresList} data-testid="feature__list_test-id">
        {featureLinks?.map(
          (item: Level3NavItem, index: number) =>
            (item?.gender === undefined ||
              (item?.gender === 'men' && item?.isMenCategory) ||
              (item?.gender === 'women' && item?.isWomenCategory)) && (
              <li key={item?.l3SubcategoryLinkSlug}>
                <Link
                  tag="button"
                  variant="underlineAnimated"
                  onClick={() => onClickLink(item)}
                  className={styles.linkContentWrapper}
                >
                  <span className={styles.linkText} tabIndex={-1} ref={setRef(`features-${index}`)}>
                    {item?.l3SubcategoryLinkDisplayName}
                  </span>
                </Link>
              </li>
            ),
        )}
      </ul>
    </div>
  );
};

export default Features;
