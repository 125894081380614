import { Reference } from 'types/reference';

export enum LocaleSpecific {
  EN_US = 'en_US',
  EN_CA = 'en_CA',
  FR_CA = 'fr_CA',

  BOTH = 'both',
}

export enum LocaleByPartnerCountry {
  CA = 'en_CA',
  US = 'en_US',
}

export type FooterLinkProps = {
  name: string;
  reference: Reference;
  showLinkInSimpleFooter?: boolean;
  localeSpecific?: LocaleSpecific;
};

export type FooterColumnProps = {
  header: string;
  links: FooterLinkProps[];
};
