import { Heading, Image } from '@lululemon/ecom-pattern-library';
import cs from 'classnames';
import styles from './under-maintenance.module.scss';

type MediaData = {
  mediaId: string;
  name: string;
  filename: string;
  file: string;
};

export type Data = {
  underMaintenanceHeadline: string;
  underMaintenanceMessage: string;
  bgImage: any;
};

type Props = {
  data: Data;
};

const UnderMaintenance = ({ data }: Props) => {
  const { underMaintenanceHeadline, underMaintenanceMessage, bgImage } = data;
  const bgImageMedia: MediaData = bgImage.media;

  return (
    <>
      <div className={styles.bg}>
        <img src={bgImageMedia.file} />
      </div>
      <div className={styles.contentwrapper}>
        <Heading
          data-testid="customer-support__under-maintenance-message_test-id"
          wrapperClassName={styles.headline}
          tag="p"
        >
          {underMaintenanceHeadline}
        </Heading>
        <p className={styles.message}>{underMaintenanceMessage}</p>
      </div>
    </>
  );
};

export default UnderMaintenance;
