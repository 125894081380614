import classnames from 'classnames';
// import MarketButton from 'components/commercetools-ui/organisms/market-button/market-button';
import { MarketProvider } from 'context/market';
import styles from './region-selector.module.scss';

const RegionSelectorBar = () => {
  return (
    <MarketProvider>
      <div className={classnames(styles.regionSelectorBar)}>{/* <MarketButton /> */}</div>
    </MarketProvider>
  );
};

export default RegionSelectorBar;
