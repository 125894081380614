import React from 'react';
import CategoryPromotionCarousel, {
  CategoryData,
} from 'components/commercetools-ui/organisms/home/category-promotion-carousel';
import { FEATURE_FLAG_LIST, useFeatureFlags } from 'hooks';

const CaegoryPromotionCarouselTastic = ({ data }: CategoryData) => {
  const { isFeatureEnabled } = useFeatureFlags();

  return isFeatureEnabled(FEATURE_FLAG_LIST.HOME_PAGE) ? <CategoryPromotionCarousel data={data} /> : null;
};

export default CaegoryPromotionCarouselTastic;
