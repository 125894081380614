import { COMPONENT_TYPE } from 'helpers/constants/analyticsEventType';

export const HOME_PAGE = {
  QUICK_LINKS_CTA_CLICKED: (text: string) => {
    return {
      eventSubType: 'interaction',
      component: {
        id: 'quick_links',
        type: COMPONENT_TYPE.LINK,
        text: text,
      },
    };
  },

  WHATS_NEW_CARD_CLICKED: (data: any, text: string) => {
    return {
      component: {
        id: 'whats_new_card',
        type: COMPONENT_TYPE.BUTTON,
        text: text,
      },
      collectionList: data,
    };
  },

  // TODO: Not being used, remove it later.
  PRODUCT_TILE_CLICKED: (data: any, text: string) => {
    return {
      eventSubType: 'interaction',
      component: {
        id: 'product_tile',
        type: COMPONENT_TYPE.BUTTON,
        text: text,
      },
      collectionList: data,
    };
  },

  WHATS_NEW_CARD_LINK_CLICKED: (text: string) => {
    return {
      eventSubType: 'interaction',
      component: {
        id: 'whats_new_card',
        type: COMPONENT_TYPE.LINK,
        text: text,
      },
    };
  },

  PROMOTION_CAROUSEL_CARD_CLICKED: (text: string, url: string) => {
    return {
      eventSubType: 'interaction',
      component: {
        id: 'homepage_promo_carousel_card',
        type: COMPONENT_TYPE.LINK,
        text: 'Homepage promotion carousel card clicked',
      },
      attributes: {
        details: { data: { url, text } },
      },
    };
  },

  PROMOTION_CAROUSEL_TILE_CLICKED: (data: any, text: string) => {
    return {
      component: {
        id: 'promo_carousel_product',
        type: COMPONENT_TYPE.PRODUCT_CAROUSEL,
        text: text,
      },
      collectionList: data,
    };
  },

  GET_PRODUCTS_FOR_WHATS_NEW_CARD_API_RESPONSE: {
    event: {
      id: 'get_products_for_whats_new_card_api_response',
      attributes: {
        details: 'Get Products For Whats New Card call success response',
      },
    },
  },

  GET_PRODUCTS_FOR_WHATS_NEW_CARD_API_ERROR: (errorDetails: any) => {
    return {
      error: {
        errorType: 'get_products_for_whats_new_card_api_error',
        errorMessage: 'Error in calling Get Products For Whats New Card API',
        errorDetails,
      },
    };
  },

  GET_THE_PRODUCT_FOR_QUICK_LINK_API_RESPONSE: {
    event: {
      id: 'get_the_product_for_quick_link_api_response',
      attributes: {
        details: 'Get the product for quick link call success response',
      },
    },
  },

  GET_THE_PRODUCT_FOR_QUICK_LINK_API_ERROR: (errorDetails: any) => {
    return {
      error: {
        errorType: 'get_the_product_for_quick_link_api_error',
        errorMessage: 'Error in calling get the product for quick link API',
        errorDetails,
      },
    };
  },

  GET_PRODUCTS_FROM_CATEGORY_SLUG_API_RESPONSE: {
    event: {
      id: 'get_products_from_category_slug_api_response',
      attributes: {
        details: 'Get products from the category slug call success response',
      },
    },
  },

  GET_PRODUCTS_FROM_CATEGORY_SLUG_API_ERROR: (errorDetails: any) => {
    return {
      error: {
        errorType: 'get_products_from_category_slug_api_error',
        errorMessage: 'Error in calling get products from the category slug API',
        errorDetails,
      },
    };
  },
  SHOP_ALL_BUTTON: (textStr: string) => {
    return {
      eventSubType: 'interaction',
      component: {
        id: 'shop_all_button',
        type: COMPONENT_TYPE.BUTTON,
        text: textStr,
      },
    };
  },
};
