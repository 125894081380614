export const parseNGCError = (error: unknown) => {
  try {
    if (
      error &&
      typeof error === 'object' &&
      'message' in error &&
      typeof (error as { message?: string }).message === 'string'
    ) {
      const errorMessage = (error as { message: string }).message.replace('Error: ', '');
      return JSON.parse(errorMessage);
    }
  } catch (parseError) {
    console.error('Error parsing NGC error:', parseError);
  }
};
