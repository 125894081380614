import React, { FC, createContext, useState, useContext } from 'react';
import { NavItem } from 'shared/types/nav';
import useNav from 'helpers/hooks/useNav';

export interface NavContextProps {
  activeCategory: NavItem | null;
  handleCloseDetailedNav: () => void;
  handleMouseEnter: (category: NavItem, index: number, isFocused?: boolean) => void;
  isDetailedNavOpen: boolean;
  setActiveCategory: (category: NavItem | null) => void;
  activeL1Index: number;
  setActiveL1Index: (index: number) => void;
  activeL2Index: number;
  setActiveL2Index: (index: number) => void;
  featuresActivated: boolean;
  setFeaturesActivated: (is: boolean) => void;
  subcategoriesActivated: boolean;
  setSubcategoriesActivated: (is: boolean) => void;
  menuLength: number;
  setMenuLength: (is: number) => void;
  navActivable: any;
  handleTabPress: (shiftkey: boolean) => void;
  handleEscapePress: () => void;
  tabActivated: boolean;

  // for search field - types start
  isSearchFocused: boolean;
  setSearchFocused: (is: boolean) => void;
  // for search field - types end

  // for account popup - types start
  isAccountButtonFocused: boolean;
  setAccountButtonFocused: (is: boolean) => void;
  isAccountMenuVisible: boolean;
  setAccountMenuVisible: (is: boolean) => void;
  isAccountMenuListFocused: boolean;
  setAccountMenuListFocused: (is: boolean) => void;
  // for account popup - types end
}

const NavContext = createContext<NavContextProps | undefined>(undefined);

const NavProvider: FC = ({ children }) => {
  const value = useNav();

  return <NavContext.Provider value={value}>{children}</NavContext.Provider>;
};

export const useNavContext = (): NavContextProps => {
  const context = useContext(NavContext);
  if (context === undefined) {
    throw new Error('useNavContext must be used within a NavProvider');
  }
  return context;
};

export { NavContext, NavProvider };
