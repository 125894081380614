export enum StatusCode {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  INTERNAL_SERVER_ERROR = 500,
  SERVICE_UNAVAILABLE = 503,
  BAD_REQUEST = 400,
  SUCCESS = 200,
  NOT_FOUND = 404,
  VERSION_MISMATCH = 409,
  TIMEOUT = 599,
}
