import { Account } from 'shared/types/account';
import { AccountUpdateReturn } from 'shared/types/account/Account';
import { AccountToken } from 'shared/types/account/AccountToken';
import { Address } from 'shared/types/account/Address';
import { Group } from 'shared/types/account/Group';
import { BusinessUnit } from 'shared/types/businessUnit';
import { LineItem as CartLineItem } from 'shared/types/cart/LineItem';
import { ShippingInfo } from 'shared/types/cart/ShippingInfo';
import { Variant } from 'shared/types/product';
import { Category } from 'shared/types/product/Category';
import { Money } from 'shared/types/product/Money';
import { LineItem } from 'shared/types/wishlist/LineItem';
import { Wishlist } from 'shared/types/wishlist/Wishlist';
import { ModalCyberSourceRequestMapper, CreditCardType } from 'frontastic/hooks/useCybersource/types';
import { NextFrontasticImage } from 'frontastic/lib/image';
import { CreditCardLabelType } from 'frontastic/hooks/useCybersource/types';

export const categories: Category[] = [
  {
    categoryId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
    parentId: '0df65d87-9812-409e-912b-e1b2e8b4d2fc',
    name: 'women',
    slug: 'women',
    depth: 1,
    subCategories: [
      {
        categoryId: 'f58a9800-5639-42b8-9249-6afe95155399',
        parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
        name: 'Jumpsuits & Rompers',
        slug: 'jumpsuits-rompers',
        depth: 2,
        subCategories: [],
        path: '/lululemon/women/jumpsuits-rompers',
      },
      {
        categoryId: 'b87ca665-3c4e-4be0-a3a8-d09f3b5e683f',
        parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
        name: 'Hoodies & Sweatshirts',
        slug: 'womens-outerwear',
        depth: 2,
        subCategories: [],
        path: '/lululemon/women/womens-outerwear',
      },
      {
        categoryId: 'ffcffa02-a7cc-42d7-9898-55475e59d65e',
        parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
        name: 'Shirts',
        slug: 'women-maintops',
        depth: 2,
        subCategories: [
          {
            categoryId: '5f8857fb-6bda-45e6-a85b-6386670d588b',
            parentId: 'ffcffa02-a7cc-42d7-9898-55475e59d65e',
            name: 'T-Shirts',
            slug: 'womens-t-shirts',
            depth: 3,
            subCategories: [],
            path: '/lululemon/women/women-maintops/womens-t-shirts',
          },
          {
            categoryId: '8e26815d-487e-496f-b809-1005e24794d7',
            parentId: 'ffcffa02-a7cc-42d7-9898-55475e59d65e',
            name: 'Tank Tops',
            slug: 'women-tanks',
            depth: 3,
            subCategories: [],
            path: '/lululemon/women/women-maintops/women-tanks',
          },
          {
            categoryId: '7b078db1-6f6e-45c2-ad6e-a7a929f4c0ed',
            parentId: 'ffcffa02-a7cc-42d7-9898-55475e59d65e',
            name: 'Short Sleeve Shirts',
            slug: 'tops-short-sleeve',
            depth: 3,
            subCategories: [],
            path: '/lululemon/women/women-maintops/tops-short-sleeve',
          },
          {
            categoryId: '234f444d-5dbb-4c0d-b730-e3cc5bdfe723',
            parentId: 'ffcffa02-a7cc-42d7-9898-55475e59d65e',
            name: 'Bodysuits',
            slug: 'women-bodysuits',
            depth: 3,
            subCategories: [],
            path: '/lululemon/women/women-maintops/women-bodysuits',
          },
        ],
        path: '/lululemon/women/women-maintops',
      },
      {
        categoryId: '7ee013f6-2e7f-40ec-9b44-16ea812be853',
        parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
        name: 'Coats & Jackets',
        slug: 'jackets-and-hoodies-jackets',
        depth: 2,
        subCategories: [],
        path: '/lululemon/women/jackets-and-hoodies-jackets',
      },
      {
        categoryId: '05006a10-e691-4144-996f-55d7003d94dd',
        parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
        name: 'Shorts',
        slug: 'women-shorts',
        depth: 2,
        subCategories: [],
        path: '/lululemon/women/women-shorts',
      },
      {
        categoryId: 'dc134ea1-a918-479b-9da2-7065c0554d56',
        parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
        name: 'Sweaters',
        slug: 'women-sweaters-and-wraps',
        depth: 2,
        subCategories: [],
        path: '/lululemon/women/women-sweaters-and-wraps',
      },
      {
        categoryId: 'a94cedce-f955-472c-91c8-700f7b79edaa',
        parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
        name: 'Dresses',
        slug: 'skirts-and-dresses-dresses',
        depth: 2,
        subCategories: [],
        path: '/lululemon/women/skirts-and-dresses-dresses',
      },
      {
        categoryId: '55476bed-44d3-4169-9a9a-75c5780a69df',
        parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
        name: 'Socks',
        slug: 'women-socks',
        depth: 2,
        subCategories: [],
        path: '/lululemon/women/women-socks',
      },
      {
        categoryId: '0e840105-7920-488d-bd9d-e70d1c898fb7',
        parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
        name: 'Pants',
        slug: 'women-pants',
        depth: 2,
        subCategories: [],
        path: '/lululemon/women/women-pants',
      },
      {
        categoryId: 'aec44c5a-6bb4-45e6-9652-b8e5884427cf',
        parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
        name: 'Swimsuits',
        slug: 'tops-swim',
        depth: 2,
        subCategories: [],
        path: '/lululemon/women/tops-swim',
      },
      {
        categoryId: '3847eae4-fa52-4398-a23e-2d1264398eaa',
        parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
        name: 'Bras & Underwear',
        slug: 'women-bras-and-underwear',
        depth: 2,
        subCategories: [],
        path: '/lululemon/women/women-bras-and-underwear',
      },
    ],
    path: '/lululemon/women',
  },
  {
    categoryId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
    parentId: '0df65d87-9812-409e-912b-e1b2e8b4d2fc',
    name: 'men',
    slug: 'men',
    depth: 1,
    subCategories: [
      {
        categoryId: 'b9705667-618f-4024-9b49-7f4365742c24',
        parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
        name: 'Hoodies & Sweatshirts',
        slug: 'mens-jackets-and-hoodies-hoodies',
        depth: 2,
        subCategories: [],
        path: '/lululemon/men/mens-jackets-and-hoodies-hoodies',
      },
      {
        categoryId: '7056ddcb-46ef-4d0b-ac52-d7d362761505',
        parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
        name: 'Shorts',
        slug: 'men-shorts',
        depth: 2,
        subCategories: [],
        path: '/lululemon/men/men-shorts',
      },
      {
        categoryId: '85dca7e0-1f1f-4c53-968a-bec98c8e04d9',
        parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
        name: 'Swim Trunks',
        slug: 'mens-swim',
        depth: 2,
        subCategories: [],
        path: '/lululemon/men/mens-swim',
      },
      {
        categoryId: '32ce40dd-314d-489f-8979-81ebf1b1a544',
        parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
        name: 'Underwear',
        slug: 'men-underwear',
        depth: 2,
        subCategories: [],
        path: '/lululemon/men/men-underwear',
      },
      {
        categoryId: '92757376-6482-4cc6-94ac-8bf37c262c4c',
        parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
        name: 'Shirts',
        slug: 'men-tops',
        depth: 2,
        subCategories: [
          {
            categoryId: '5d10a41b-b4b6-4927-ace8-d9e4267fbb2d',
            parentId: '92757376-6482-4cc6-94ac-8bf37c262c4c',
            name: 'Long Sleeve Shirts',
            slug: 'men-ls-tops',
            depth: 3,
            subCategories: [],
            path: '/lululemon/men/men-tops/men-ls-tops',
          },
          {
            categoryId: '90dbf9f2-a907-4f17-aa15-052b5fb27c76',
            parentId: '92757376-6482-4cc6-94ac-8bf37c262c4c',
            name: 'Short Sleeve Shirts',
            slug: 'men-ss-tops',
            depth: 3,
            subCategories: [],
            path: '/lululemon/men/men-tops/men-ss-tops',
          },
          {
            categoryId: '81040327-4a41-41d6-9f5e-2a87961f9be5',
            parentId: '92757376-6482-4cc6-94ac-8bf37c262c4c',
            name: 'Tank Tops',
            slug: 'mens-tanks',
            depth: 3,
            subCategories: [],
            path: '/lululemon/men/men-tops/mens-tanks',
          },
          {
            categoryId: 'dc9fe9da-fdf0-4e94-aa2a-5632eeaaf3a2',
            parentId: '92757376-6482-4cc6-94ac-8bf37c262c4c',
            name: 'Button Down Shirts',
            slug: 'mens-button-down-shirts',
            depth: 3,
            subCategories: [],
            path: '/lululemon/men/men-tops/mens-button-down-shirts',
          },
        ],
        path: '/lululemon/men/men-tops',
      },
      {
        categoryId: '79b9e1b8-1059-4cc8-b8d1-0cd343ee0c85',
        parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
        name: 'Socks',
        slug: 'men-socks',
        depth: 2,
        subCategories: [],
        path: '/lululemon/men/men-socks',
      },
      {
        categoryId: 'd08adcba-3af1-42ed-af2f-c5e775e4d35c',
        parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
        name: 'Coats & Jackets',
        slug: 'mens-jackets-and-outerwear',
        depth: 2,
        subCategories: [],
        path: '/lululemon/men/mens-jackets-and-outerwear',
      },
      {
        categoryId: '247b1258-9bb7-4a6f-b252-69b303f319aa',
        parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
        name: 'Pants',
        slug: 'men-pants',
        depth: 2,
        subCategories: [],
        path: '/lululemon/men/men-pants',
      },
      {
        categoryId: '0b528a67-e210-4081-92eb-74ee71a36d55',
        parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
        name: 'Sweaters',
        slug: 'mens-sweaters',
        depth: 2,
        subCategories: [],
        path: '/lululemon/men/mens-sweaters',
      },
      {
        categoryId: 'c1600452-c428-4017-8b39-be2b0e76c2f7',
        parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
        name: 'Best Sellers',
        slug: 'best-sellers',
        depth: 2,
        subCategories: [],
        path: '/lululemon/men/best-sellers',
      },
      {
        categoryId: '429f0984-1726-46d6-bd83-188dcc1f5a8b',
        parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
        name: 'Button Down Shirts',
        slug: 'button-down-shirts',
        depth: 2,
        subCategories: [],
        path: '/lululemon/men/button-down-shirts',
      },
      {
        categoryId: '63c9eb93-a455-4c08-87bf-7c9f93af232d',
        parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
        name: 'Half Zip Sweatshirts',
        slug: 'half-zip-sweatshirts',
        depth: 2,
        subCategories: [],
        path: '/lululemon/men/half-zip-sweatshirts',
      },
    ],
    path: '/lululemon/men',
  },
  {
    categoryId: '6288628d-e734-44fa-879c-9516168ba421',
    parentId: '0df65d87-9812-409e-912b-e1b2e8b4d2fc',
    name: 'Accessories',
    slug: 'accessories',
    depth: 1,
    subCategories: [
      {
        categoryId: 'fe3b5a2d-0003-4ca0-bb4c-b4d6f3c82817',
        parentId: '6288628d-e734-44fa-879c-9516168ba421',
        name: 'Belt Bags',
        slug: 'belt-bags',
        depth: 2,
        subCategories: [],
        path: '/lululemon/accessories/belt-bags',
      },
      {
        categoryId: '887e4da2-fdcc-4d08-99a8-6fc2fa5f94dd',
        parentId: '6288628d-e734-44fa-879c-9516168ba421',
        name: 'Scarves & Wraps',
        slug: 'scarves-wraps',
        depth: 2,
        subCategories: [],
        path: '/lululemon/accessories/scarves-wraps',
      },
      {
        categoryId: '83d09b5a-9be7-42f4-8044-1a3ffe7081a6',
        parentId: '6288628d-e734-44fa-879c-9516168ba421',
        name: 'Water Bottles',
        slug: 'water-bottles',
        depth: 2,
        subCategories: [],
        path: '/lululemon/accessories/water-bottles',
      },
      {
        categoryId: 'd7f0b0b3-5930-4dc6-b342-b4d163204425',
        parentId: '6288628d-e734-44fa-879c-9516168ba421',
        name: 'Hair Accessories',
        slug: 'hair-accessories',
        depth: 2,
        subCategories: [],
        path: '/lululemon/accessories/hair-accessories',
      },
      {
        categoryId: '1238cf40-7290-4345-9abc-0095440fc965',
        parentId: '6288628d-e734-44fa-879c-9516168ba421',
        name: 'Gloves & Mittens',
        slug: 'gloves-mittens',
        depth: 2,
        subCategories: [],
        path: '/lululemon/accessories/gloves-mittens',
      },
      {
        categoryId: 'fc99a09a-9cc8-4953-aec0-5e290d12f775',
        parentId: '6288628d-e734-44fa-879c-9516168ba421',
        name: 'Equipment',
        slug: 'equipment',
        depth: 2,
        subCategories: [],
        path: '/lululemon/accessories/equipment',
      },
      {
        categoryId: '81bdd708-dc93-446a-a8d2-6a0ab44eb777',
        parentId: '6288628d-e734-44fa-879c-9516168ba421',
        name: 'Yoga Mats',
        slug: 'yoga-mats',
        depth: 2,
        subCategories: [],
        path: '/lululemon/accessories/yoga-mats',
      },
      {
        categoryId: '0ceb570e-9ad6-49bf-94e2-1fdfd0a9a804',
        parentId: '6288628d-e734-44fa-879c-9516168ba421',
        name: 'Hats',
        slug: 'hats',
        depth: 2,
        subCategories: [],
        path: '/lululemon/accessories/hats',
      },
      {
        categoryId: '78516225-0a98-48ff-a01e-ba5dddfa100d',
        parentId: '6288628d-e734-44fa-879c-9516168ba421',
        name: 'Backpacks',
        slug: 'backpacks',
        depth: 2,
        subCategories: [],
        path: '/lululemon/accessories/backpacks',
      },
      {
        categoryId: '9513254b-9cb9-450f-bd7e-a22523405be5',
        parentId: '6288628d-e734-44fa-879c-9516168ba421',
        name: 'Beanies',
        slug: 'beanies',
        depth: 2,
        subCategories: [],
        path: '/lululemon/accessories/beanies',
      },
    ],
    path: '/lululemon/accessories',
  },
  {
    categoryId: 'fe3b5a2d-0003-4ca0-bb4c-b4d6f3c82817',
    parentId: '6288628d-e734-44fa-879c-9516168ba421',
    name: 'Belt Bags',
    slug: 'belt-bags',
    depth: 2,
    subCategories: [],
    path: '/lululemon/accessories/belt-bags',
  },
  {
    categoryId: '9ea8ce66-09dd-4482-a27e-80794e31fe52',
    parentId: '0df65d87-9812-409e-912b-e1b2e8b4d2fc',
    name: 'Shoes',
    slug: 'shoes',
    depth: 1,
    subCategories: [],
    path: '/lululemon/shoes',
  },
  {
    categoryId: 'cdd09d0c-a2fd-4d40-9562-6b27781796a5',
    parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
    name: "What's New",
    slug: 'whats-new',
    depth: 2,
    subCategories: [],
    path: '/lululemon/features/whats-new',
  },
  {
    categoryId: 'aae9a177-403f-4190-b9ad-79c8a7da8850',
    parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
    name: 'Core Products',
    slug: 'core-products',
    depth: 2,
    subCategories: [],
    path: '/lululemon/features/core-products',
  },
  {
    categoryId: 'ae0fda05-4b43-4e88-bf3c-ee85a3731860',
    parentId: '0df65d87-9812-409e-912b-e1b2e8b4d2fc',
    name: 'Supplies',
    slug: 'supplies',
    depth: 1,
    subCategories: [],
    path: '/lululemon/supplies',
  },
  {
    categoryId: '0df65d87-9812-409e-912b-e1b2e8b4d2fc',
    name: 'Commerce Root',
    slug: 'lululemon',
    depth: 0,
    subCategories: [
      {
        categoryId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
        parentId: '0df65d87-9812-409e-912b-e1b2e8b4d2fc',
        name: 'women',
        slug: 'women',
        depth: 1,
        subCategories: [
          {
            categoryId: 'f58a9800-5639-42b8-9249-6afe95155399',
            parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
            name: 'Jumpsuits & Rompers',
            slug: 'jumpsuits-rompers',
            depth: 2,
            subCategories: [],
            path: '/lululemon/women/jumpsuits-rompers',
          },
          {
            categoryId: 'b87ca665-3c4e-4be0-a3a8-d09f3b5e683f',
            parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
            name: 'Hoodies & Sweatshirts',
            slug: 'womens-outerwear',
            depth: 2,
            subCategories: [],
            path: '/lululemon/women/womens-outerwear',
          },
          {
            categoryId: 'ffcffa02-a7cc-42d7-9898-55475e59d65e',
            parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
            name: 'Shirts',
            slug: 'women-maintops',
            depth: 2,
            subCategories: [
              {
                categoryId: '5f8857fb-6bda-45e6-a85b-6386670d588b',
                parentId: 'ffcffa02-a7cc-42d7-9898-55475e59d65e',
                name: 'T-Shirts',
                slug: 'womens-t-shirts',
                depth: 3,
                subCategories: [],
                path: '/lululemon/women/women-maintops/womens-t-shirts',
              },
              {
                categoryId: '8e26815d-487e-496f-b809-1005e24794d7',
                parentId: 'ffcffa02-a7cc-42d7-9898-55475e59d65e',
                name: 'Tank Tops',
                slug: 'women-tanks',
                depth: 3,
                subCategories: [],
                path: '/lululemon/women/women-maintops/women-tanks',
              },
              {
                categoryId: '7b078db1-6f6e-45c2-ad6e-a7a929f4c0ed',
                parentId: 'ffcffa02-a7cc-42d7-9898-55475e59d65e',
                name: 'Short Sleeve Shirts',
                slug: 'tops-short-sleeve',
                depth: 3,
                subCategories: [],
                path: '/lululemon/women/women-maintops/tops-short-sleeve',
              },
              {
                categoryId: '234f444d-5dbb-4c0d-b730-e3cc5bdfe723',
                parentId: 'ffcffa02-a7cc-42d7-9898-55475e59d65e',
                name: 'Bodysuits',
                slug: 'women-bodysuits',
                depth: 3,
                subCategories: [],
                path: '/lululemon/women/women-maintops/women-bodysuits',
              },
            ],
            path: '/lululemon/women/women-maintops',
          },
          {
            categoryId: '7ee013f6-2e7f-40ec-9b44-16ea812be853',
            parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
            name: 'Coats & Jackets',
            slug: 'jackets-and-hoodies-jackets',
            depth: 2,
            subCategories: [],
            path: '/lululemon/women/jackets-and-hoodies-jackets',
          },
          {
            categoryId: '05006a10-e691-4144-996f-55d7003d94dd',
            parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
            name: 'Shorts',
            slug: 'women-shorts',
            depth: 2,
            subCategories: [],
            path: '/lululemon/women/women-shorts',
          },
          {
            categoryId: 'dc134ea1-a918-479b-9da2-7065c0554d56',
            parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
            name: 'Sweaters',
            slug: 'women-sweaters-and-wraps',
            depth: 2,
            subCategories: [],
            path: '/lululemon/women/women-sweaters-and-wraps',
          },
          {
            categoryId: 'a94cedce-f955-472c-91c8-700f7b79edaa',
            parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
            name: 'Dresses',
            slug: 'skirts-and-dresses-dresses',
            depth: 2,
            subCategories: [],
            path: '/lululemon/women/skirts-and-dresses-dresses',
          },
          {
            categoryId: '55476bed-44d3-4169-9a9a-75c5780a69df',
            parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
            name: 'Socks',
            slug: 'women-socks',
            depth: 2,
            subCategories: [],
            path: '/lululemon/women/women-socks',
          },
          {
            categoryId: '0e840105-7920-488d-bd9d-e70d1c898fb7',
            parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
            name: 'Pants',
            slug: 'women-pants',
            depth: 2,
            subCategories: [],
            path: '/lululemon/women/women-pants',
          },
          {
            categoryId: 'aec44c5a-6bb4-45e6-9652-b8e5884427cf',
            parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
            name: 'Swimsuits',
            slug: 'tops-swim',
            depth: 2,
            subCategories: [],
            path: '/lululemon/women/tops-swim',
          },
          {
            categoryId: '3847eae4-fa52-4398-a23e-2d1264398eaa',
            parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
            name: 'Bras & Underwear',
            slug: 'women-bras-and-underwear',
            depth: 2,
            subCategories: [],
            path: '/lululemon/women/women-bras-and-underwear',
          },
        ],
        path: '/lululemon/women',
      },
      {
        categoryId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
        parentId: '0df65d87-9812-409e-912b-e1b2e8b4d2fc',
        name: 'men',
        slug: 'men',
        depth: 1,
        subCategories: [
          {
            categoryId: 'b9705667-618f-4024-9b49-7f4365742c24',
            parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
            name: 'Hoodies & Sweatshirts',
            slug: 'mens-jackets-and-hoodies-hoodies',
            depth: 2,
            subCategories: [],
            path: '/lululemon/men/mens-jackets-and-hoodies-hoodies',
          },
          {
            categoryId: '7056ddcb-46ef-4d0b-ac52-d7d362761505',
            parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
            name: 'Shorts',
            slug: 'men-shorts',
            depth: 2,
            subCategories: [],
            path: '/lululemon/men/men-shorts',
          },
          {
            categoryId: '85dca7e0-1f1f-4c53-968a-bec98c8e04d9',
            parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
            name: 'Swim Trunks',
            slug: 'mens-swim',
            depth: 2,
            subCategories: [],
            path: '/lululemon/men/mens-swim',
          },
          {
            categoryId: '32ce40dd-314d-489f-8979-81ebf1b1a544',
            parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
            name: 'Underwear',
            slug: 'men-underwear',
            depth: 2,
            subCategories: [],
            path: '/lululemon/men/men-underwear',
          },
          {
            categoryId: '92757376-6482-4cc6-94ac-8bf37c262c4c',
            parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
            name: 'Shirts',
            slug: 'men-tops',
            depth: 2,
            subCategories: [
              {
                categoryId: '5d10a41b-b4b6-4927-ace8-d9e4267fbb2d',
                parentId: '92757376-6482-4cc6-94ac-8bf37c262c4c',
                name: 'Long Sleeve Shirts',
                slug: 'men-ls-tops',
                depth: 3,
                subCategories: [],
                path: '/lululemon/men/men-tops/men-ls-tops',
              },
              {
                categoryId: '90dbf9f2-a907-4f17-aa15-052b5fb27c76',
                parentId: '92757376-6482-4cc6-94ac-8bf37c262c4c',
                name: 'Short Sleeve Shirts',
                slug: 'men-ss-tops',
                depth: 3,
                subCategories: [],
                path: '/lululemon/men/men-tops/men-ss-tops',
              },
              {
                categoryId: '81040327-4a41-41d6-9f5e-2a87961f9be5',
                parentId: '92757376-6482-4cc6-94ac-8bf37c262c4c',
                name: 'Tank Tops',
                slug: 'mens-tanks',
                depth: 3,
                subCategories: [],
                path: '/lululemon/men/men-tops/mens-tanks',
              },
              {
                categoryId: 'dc9fe9da-fdf0-4e94-aa2a-5632eeaaf3a2',
                parentId: '92757376-6482-4cc6-94ac-8bf37c262c4c',
                name: 'Button Down Shirts',
                slug: 'mens-button-down-shirts',
                depth: 3,
                subCategories: [],
                path: '/lululemon/men/men-tops/mens-button-down-shirts',
              },
            ],
            path: '/lululemon/men/men-tops',
          },
          {
            categoryId: '79b9e1b8-1059-4cc8-b8d1-0cd343ee0c85',
            parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
            name: 'Socks',
            slug: 'men-socks',
            depth: 2,
            subCategories: [],
            path: '/lululemon/men/men-socks',
          },
          {
            categoryId: 'd08adcba-3af1-42ed-af2f-c5e775e4d35c',
            parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
            name: 'Coats & Jackets',
            slug: 'mens-jackets-and-outerwear',
            depth: 2,
            subCategories: [],
            path: '/lululemon/men/mens-jackets-and-outerwear',
          },
          {
            categoryId: '247b1258-9bb7-4a6f-b252-69b303f319aa',
            parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
            name: 'Pants',
            slug: 'men-pants',
            depth: 2,
            subCategories: [],
            path: '/lululemon/men/men-pants',
          },
          {
            categoryId: '0b528a67-e210-4081-92eb-74ee71a36d55',
            parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
            name: 'Sweaters',
            slug: 'mens-sweaters',
            depth: 2,
            subCategories: [],
            path: '/lululemon/men/mens-sweaters',
          },
          {
            categoryId: 'c1600452-c428-4017-8b39-be2b0e76c2f7',
            parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
            name: 'Best Sellers',
            slug: 'best-sellers',
            depth: 2,
            subCategories: [],
            path: '/lululemon/men/best-sellers',
          },
          {
            categoryId: '429f0984-1726-46d6-bd83-188dcc1f5a8b',
            parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
            name: 'Button Down Shirts',
            slug: 'button-down-shirts',
            depth: 2,
            subCategories: [],
            path: '/lululemon/men/button-down-shirts',
          },
          {
            categoryId: '63c9eb93-a455-4c08-87bf-7c9f93af232d',
            parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
            name: 'Half Zip Sweatshirts',
            slug: 'half-zip-sweatshirts',
            depth: 2,
            subCategories: [],
            path: '/lululemon/men/half-zip-sweatshirts',
          },
        ],
        path: '/lululemon/men',
      },
      {
        categoryId: '6288628d-e734-44fa-879c-9516168ba421',
        parentId: '0df65d87-9812-409e-912b-e1b2e8b4d2fc',
        name: 'Accessories',
        slug: 'accessories',
        depth: 1,
        subCategories: [
          {
            categoryId: 'fe3b5a2d-0003-4ca0-bb4c-b4d6f3c82817',
            parentId: '6288628d-e734-44fa-879c-9516168ba421',
            name: 'Belt Bags',
            slug: 'belt-bags',
            depth: 2,
            subCategories: [],
            path: '/lululemon/accessories/belt-bags',
          },
          {
            categoryId: '887e4da2-fdcc-4d08-99a8-6fc2fa5f94dd',
            parentId: '6288628d-e734-44fa-879c-9516168ba421',
            name: 'Scarves & Wraps',
            slug: 'scarves-wraps',
            depth: 2,
            subCategories: [],
            path: '/lululemon/accessories/scarves-wraps',
          },
          {
            categoryId: '83d09b5a-9be7-42f4-8044-1a3ffe7081a6',
            parentId: '6288628d-e734-44fa-879c-9516168ba421',
            name: 'Water Bottles',
            slug: 'water-bottles',
            depth: 2,
            subCategories: [],
            path: '/lululemon/accessories/water-bottles',
          },
          {
            categoryId: 'd7f0b0b3-5930-4dc6-b342-b4d163204425',
            parentId: '6288628d-e734-44fa-879c-9516168ba421',
            name: 'Hair Accessories',
            slug: 'hair-accessories',
            depth: 2,
            subCategories: [],
            path: '/lululemon/accessories/hair-accessories',
          },
          {
            categoryId: '1238cf40-7290-4345-9abc-0095440fc965',
            parentId: '6288628d-e734-44fa-879c-9516168ba421',
            name: 'Gloves & Mittens',
            slug: 'gloves-mittens',
            depth: 2,
            subCategories: [],
            path: '/lululemon/accessories/gloves-mittens',
          },
          {
            categoryId: 'fc99a09a-9cc8-4953-aec0-5e290d12f775',
            parentId: '6288628d-e734-44fa-879c-9516168ba421',
            name: 'Equipment',
            slug: 'equipment',
            depth: 2,
            subCategories: [],
            path: '/lululemon/accessories/equipment',
          },
          {
            categoryId: '81bdd708-dc93-446a-a8d2-6a0ab44eb777',
            parentId: '6288628d-e734-44fa-879c-9516168ba421',
            name: 'Yoga Mats',
            slug: 'yoga-mats',
            depth: 2,
            subCategories: [],
            path: '/lululemon/accessories/yoga-mats',
          },
          {
            categoryId: '0ceb570e-9ad6-49bf-94e2-1fdfd0a9a804',
            parentId: '6288628d-e734-44fa-879c-9516168ba421',
            name: 'Hats',
            slug: 'hats',
            depth: 2,
            subCategories: [],
            path: '/lululemon/accessories/hats',
          },
          {
            categoryId: '78516225-0a98-48ff-a01e-ba5dddfa100d',
            parentId: '6288628d-e734-44fa-879c-9516168ba421',
            name: 'Backpacks',
            slug: 'backpacks',
            depth: 2,
            subCategories: [],
            path: '/lululemon/accessories/backpacks',
          },
          {
            categoryId: '9513254b-9cb9-450f-bd7e-a22523405be5',
            parentId: '6288628d-e734-44fa-879c-9516168ba421',
            name: 'Beanies',
            slug: 'beanies',
            depth: 2,
            subCategories: [],
            path: '/lululemon/accessories/beanies',
          },
        ],
        path: '/lululemon/accessories',
      },
      {
        categoryId: '9ea8ce66-09dd-4482-a27e-80794e31fe52',
        parentId: '0df65d87-9812-409e-912b-e1b2e8b4d2fc',
        name: 'Shoes',
        slug: 'shoes',
        depth: 1,
        subCategories: [],
        path: '/lululemon/shoes',
      },
      {
        categoryId: 'ae0fda05-4b43-4e88-bf3c-ee85a3731860',
        parentId: '0df65d87-9812-409e-912b-e1b2e8b4d2fc',
        name: 'Supplies',
        slug: 'supplies',
        depth: 1,
        subCategories: [],
        path: '/lululemon/supplies',
      },
      {
        categoryId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
        parentId: '0df65d87-9812-409e-912b-e1b2e8b4d2fc',
        name: 'Features',
        slug: 'features',
        depth: 1,
        subCategories: [
          {
            categoryId: 'cdd09d0c-a2fd-4d40-9562-6b27781796a5',
            parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
            name: "What's New",
            slug: 'whats-new',
            depth: 2,
            subCategories: [],
            path: '/lululemon/features/whats-new',
          },
          {
            categoryId: 'aae9a177-403f-4190-b9ad-79c8a7da8850',
            parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
            name: 'Core Products',
            slug: 'core-products',
            depth: 2,
            subCategories: [],
            path: '/lululemon/features/core-products',
          },
          {
            categoryId: '498d5717-0f1a-4036-8ab4-22a65a58d9dc',
            parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
            name: 'Featured Styles',
            slug: 'featured-styles',
            depth: 2,
            subCategories: [],
            path: '/lululemon/features/featured-styles',
          },
          {
            categoryId: 'd501e924-c99f-4b85-8c76-f1dd50ae22f5',
            parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
            name: 'VariantsUpto100',
            slug: 'variantsupto100',
            depth: 2,
            subCategories: [],
            path: '/lululemon/features/variantsupto100',
          },
          {
            categoryId: '5838e9cb-3e4e-4590-8237-03ba5be38731',
            parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
            name: 'VariantsUpto50',
            slug: 'variantsupto50',
            depth: 2,
            subCategories: [],
            path: '/lululemon/features/variantsupto50',
          },
          {
            categoryId: '68d6e6dd-2bd8-435f-9937-3031a56a4320',
            parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
            name: 'VariantsUpto300',
            slug: 'variantsupto300',
            depth: 2,
            subCategories: [],
            path: '/lululemon/features/variantsupto300',
          },
          {
            categoryId: '61ab1b5a-bf6f-4ff0-bc1e-67cc6a7afdf6',
            parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
            name: 'VariantsUpto200',
            slug: 'variantsupto200',
            depth: 2,
            subCategories: [],
            path: '/lululemon/features/variantsupto200',
          },
          {
            categoryId: 'd6c76845-5f6f-42e5-907f-ba7afd1b2f1b',
            parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
            name: 'Variants300AndMore',
            slug: 'variants300andmore',
            depth: 2,
            subCategories: [],
            path: '/lululemon/features/variants300andmore',
          },
          {
            categoryId: '4ae77c77-bc44-44f2-b0aa-28c816858b2f',
            parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
            name: "Mother's Day",
            slug: 'mothers-day',
            depth: 2,
            subCategories: [],
            path: '/lululemon/features/mothers-day',
          },
          {
            categoryId: '2ce97bd7-9278-43b2-bc13-8de1c578752a',
            parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
            name: 'Valentines Day',
            slug: 'valentine-day',
            depth: 2,
            subCategories: [],
            path: '/lululemon/features/valentine-day',
          },
        ],
        path: '/lululemon/features',
      },
    ],
    path: '/lululemon',
  },
  {
    categoryId: 'f0c2ba2c-6994-4cb3-9d4e-958bf96a3298',
    parentId: '0e840105-7920-488d-bd9d-e70d1c898fb7',
    name: 'Joggers',
    slug: 'womens-joggers',
    depth: 3,
    subCategories: [],
    path: '/lululemon/women/women-pants/womens-joggers',
  },
  {
    categoryId: '3d533600-e7cb-402c-9e9a-51801a9fa5bf',
    parentId: '92757376-6482-4cc6-94ac-8bf37c262c4c',
    name: 'Polo Shirts',
    slug: 'mens-polos',
    depth: 3,
    subCategories: [],
    path: '/lululemon/men/men-tops/mens-polos',
  },
  {
    categoryId: 'b9705667-618f-4024-9b49-7f4365742c24',
    parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
    name: 'Hoodies & Sweatshirts',
    slug: 'mens-jackets-and-hoodies-hoodies',
    depth: 2,
    subCategories: [],
    path: '/lululemon/men/mens-jackets-and-hoodies-hoodies',
  },
  {
    categoryId: '62b45db1-4d79-4685-8f6e-b6c4166be09a',
    parentId: '0e840105-7920-488d-bd9d-e70d1c898fb7',
    name: 'Leggings',
    slug: 'womens-leggings',
    depth: 3,
    subCategories: [],
    path: '/lululemon/women/women-pants/womens-leggings',
  },
  {
    categoryId: '7e63e17d-4c65-484b-8450-6225f5a82173',
    parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
    name: 'Skirts',
    slug: 'skirts-and-dresses-skirts',
    depth: 2,
    subCategories: [],
    path: '/lululemon/women/skirts-and-dresses-skirts',
  },
  {
    categoryId: '86ffaa0e-3fe9-4ecc-a66d-0341dc9aaff5',
    parentId: '0e840105-7920-488d-bd9d-e70d1c898fb7',
    name: 'Trousers',
    slug: 'womens-trousers',
    depth: 3,
    subCategories: [],
    path: '/lululemon/women/women-pants/womens-trousers',
  },
  {
    categoryId: 'f58a9800-5639-42b8-9249-6afe95155399',
    parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
    name: 'Jumpsuits & Rompers',
    slug: 'jumpsuits-rompers',
    depth: 2,
    subCategories: [],
    path: '/lululemon/women/jumpsuits-rompers',
  },
  {
    categoryId: 'f01be47f-bdd2-4b2e-b3f9-4181088bc2a2',
    parentId: '92757376-6482-4cc6-94ac-8bf37c262c4c',
    name: 'T-Shirts',
    slug: 'mens-t-shirts',
    depth: 3,
    subCategories: [],
    path: '/lululemon/men/men-tops/mens-t-shirts',
  },
  {
    categoryId: '96610cb3-d46b-4b43-bee0-227e6ffe7222',
    parentId: '0e840105-7920-488d-bd9d-e70d1c898fb7',
    name: 'Sweatpants',
    slug: 'womens-sweatpants',
    depth: 3,
    subCategories: [],
    path: '/lululemon/women/women-pants/womens-sweatpants',
  },
  {
    categoryId: '7056ddcb-46ef-4d0b-ac52-d7d362761505',
    parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
    name: 'Shorts',
    slug: 'men-shorts',
    depth: 2,
    subCategories: [],
    path: '/lululemon/men/men-shorts',
  },
  {
    categoryId: '60cf3a7f-6b4f-49b7-9555-fd45944689ec',
    parentId: '3847eae4-fa52-4398-a23e-2d1264398eaa',
    name: 'Underwear',
    slug: 'women-underwear',
    depth: 3,
    subCategories: [],
    path: '/lululemon/women/women-bras-and-underwear/women-underwear',
  },
  {
    categoryId: '85dca7e0-1f1f-4c53-968a-bec98c8e04d9',
    parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
    name: 'Swim Trunks',
    slug: 'mens-swim',
    depth: 2,
    subCategories: [],
    path: '/lululemon/men/mens-swim',
  },
  {
    categoryId: 'da6b073f-4615-4c61-841f-b2d282cf01e8',
    parentId: '247b1258-9bb7-4a6f-b252-69b303f319aa',
    name: 'Sweatpants',
    slug: 'mens-sweatpants',
    depth: 3,
    subCategories: [],
    path: '/lululemon/men/men-pants/mens-sweatpants',
  },
  {
    categoryId: '4ac8134a-6c59-4c0a-bad6-62b29f1b77c0',
    parentId: 'ffcffa02-a7cc-42d7-9898-55475e59d65e',
    name: 'Long Sleeve Shirts',
    slug: 'tops-long-sleeve',
    depth: 3,
    subCategories: [],
    path: '/lululemon/women/women-maintops/tops-long-sleeve',
  },
  {
    categoryId: '83774b23-606f-4b3f-aaeb-dbd8836261fc',
    parentId: '0e840105-7920-488d-bd9d-e70d1c898fb7',
    name: 'Capris',
    slug: 'women-crops',
    depth: 3,
    subCategories: [],
    path: '/lululemon/women/women-pants/women-crops',
  },
  {
    categoryId: 'b87ca665-3c4e-4be0-a3a8-d09f3b5e683f',
    parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
    name: 'Hoodies & Sweatshirts',
    slug: 'womens-outerwear',
    depth: 2,
    subCategories: [],
    path: '/lululemon/women/womens-outerwear',
  },
  {
    categoryId: 'be79ec7e-b555-4d8f-8a7a-207b50dd8568',
    parentId: '3847eae4-fa52-4398-a23e-2d1264398eaa',
    name: 'Sports Bras',
    slug: 'women-sports-bras',
    depth: 3,
    subCategories: [],
    path: '/lululemon/women/women-bras-and-underwear/women-sports-bras',
  },
  {
    categoryId: 'ffcffa02-a7cc-42d7-9898-55475e59d65e',
    parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
    name: 'Shirts',
    slug: 'women-maintops',
    depth: 2,
    subCategories: [
      {
        categoryId: '5f8857fb-6bda-45e6-a85b-6386670d588b',
        parentId: 'ffcffa02-a7cc-42d7-9898-55475e59d65e',
        name: 'T-Shirts',
        slug: 'womens-t-shirts',
        depth: 3,
        subCategories: [],
        path: '/lululemon/women/women-maintops/womens-t-shirts',
      },
      {
        categoryId: '8e26815d-487e-496f-b809-1005e24794d7',
        parentId: 'ffcffa02-a7cc-42d7-9898-55475e59d65e',
        name: 'Tank Tops',
        slug: 'women-tanks',
        depth: 3,
        subCategories: [],
        path: '/lululemon/women/women-maintops/women-tanks',
      },
      {
        categoryId: '7b078db1-6f6e-45c2-ad6e-a7a929f4c0ed',
        parentId: 'ffcffa02-a7cc-42d7-9898-55475e59d65e',
        name: 'Short Sleeve Shirts',
        slug: 'tops-short-sleeve',
        depth: 3,
        subCategories: [],
        path: '/lululemon/women/women-maintops/tops-short-sleeve',
      },
      {
        categoryId: '234f444d-5dbb-4c0d-b730-e3cc5bdfe723',
        parentId: 'ffcffa02-a7cc-42d7-9898-55475e59d65e',
        name: 'Bodysuits',
        slug: 'women-bodysuits',
        depth: 3,
        subCategories: [],
        path: '/lululemon/women/women-maintops/women-bodysuits',
      },
    ],
    path: '/lululemon/women/women-maintops',
  },
  {
    categoryId: '8abb2def-9e7b-4fb4-9cad-a1830e8845a1',
    parentId: '6288628d-e734-44fa-879c-9516168ba421',
    name: 'Bags',
    slug: 'bags',
    depth: 2,
    subCategories: [],
    path: '/lululemon/accessories/bags',
  },
  {
    categoryId: '32ce40dd-314d-489f-8979-81ebf1b1a544',
    parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
    name: 'Underwear',
    slug: 'men-underwear',
    depth: 2,
    subCategories: [],
    path: '/lululemon/men/men-underwear',
  },
  {
    categoryId: '92757376-6482-4cc6-94ac-8bf37c262c4c',
    parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
    name: 'Shirts',
    slug: 'men-tops',
    depth: 2,
    subCategories: [
      {
        categoryId: '5d10a41b-b4b6-4927-ace8-d9e4267fbb2d',
        parentId: '92757376-6482-4cc6-94ac-8bf37c262c4c',
        name: 'Long Sleeve Shirts',
        slug: 'men-ls-tops',
        depth: 3,
        subCategories: [],
        path: '/lululemon/men/men-tops/men-ls-tops',
      },
      {
        categoryId: '90dbf9f2-a907-4f17-aa15-052b5fb27c76',
        parentId: '92757376-6482-4cc6-94ac-8bf37c262c4c',
        name: 'Short Sleeve Shirts',
        slug: 'men-ss-tops',
        depth: 3,
        subCategories: [],
        path: '/lululemon/men/men-tops/men-ss-tops',
      },
      {
        categoryId: '81040327-4a41-41d6-9f5e-2a87961f9be5',
        parentId: '92757376-6482-4cc6-94ac-8bf37c262c4c',
        name: 'Tank Tops',
        slug: 'mens-tanks',
        depth: 3,
        subCategories: [],
        path: '/lululemon/men/men-tops/mens-tanks',
      },
      {
        categoryId: 'dc9fe9da-fdf0-4e94-aa2a-5632eeaaf3a2',
        parentId: '92757376-6482-4cc6-94ac-8bf37c262c4c',
        name: 'Button Down Shirts',
        slug: 'mens-button-down-shirts',
        depth: 3,
        subCategories: [],
        path: '/lululemon/men/men-tops/mens-button-down-shirts',
      },
    ],
    path: '/lululemon/men/men-tops',
  },
  {
    categoryId: '5d10a41b-b4b6-4927-ace8-d9e4267fbb2d',
    parentId: '92757376-6482-4cc6-94ac-8bf37c262c4c',
    name: 'Long Sleeve Shirts',
    slug: 'men-ls-tops',
    depth: 3,
    subCategories: [],
    path: '/lululemon/men/men-tops/men-ls-tops',
  },
  {
    categoryId: '79b9e1b8-1059-4cc8-b8d1-0cd343ee0c85',
    parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
    name: 'Socks',
    slug: 'men-socks',
    depth: 2,
    subCategories: [],
    path: '/lululemon/men/men-socks',
  },
  {
    categoryId: 'd08adcba-3af1-42ed-af2f-c5e775e4d35c',
    parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
    name: 'Coats & Jackets',
    slug: 'mens-jackets-and-outerwear',
    depth: 2,
    subCategories: [],
    path: '/lululemon/men/mens-jackets-and-outerwear',
  },
  {
    categoryId: '887e4da2-fdcc-4d08-99a8-6fc2fa5f94dd',
    parentId: '6288628d-e734-44fa-879c-9516168ba421',
    name: 'Scarves & Wraps',
    slug: 'scarves-wraps',
    depth: 2,
    subCategories: [],
    path: '/lululemon/accessories/scarves-wraps',
  },
  {
    categoryId: 'bee19bc4-d206-4689-b420-e4d6d5b14d2c',
    parentId: '247b1258-9bb7-4a6f-b252-69b303f319aa',
    name: 'Trousers',
    slug: 'mens-trousers',
    depth: 3,
    subCategories: [],
    path: '/lululemon/men/men-pants/mens-trousers',
  },
  {
    categoryId: '5f8857fb-6bda-45e6-a85b-6386670d588b',
    parentId: 'ffcffa02-a7cc-42d7-9898-55475e59d65e',
    name: 'T-Shirts',
    slug: 'womens-t-shirts',
    depth: 3,
    subCategories: [],
    path: '/lululemon/women/women-maintops/womens-t-shirts',
  },
  {
    categoryId: '7ee013f6-2e7f-40ec-9b44-16ea812be853',
    parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
    name: 'Coats & Jackets',
    slug: 'jackets-and-hoodies-jackets',
    depth: 2,
    subCategories: [],
    path: '/lululemon/women/jackets-and-hoodies-jackets',
  },
  {
    categoryId: '05006a10-e691-4144-996f-55d7003d94dd',
    parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
    name: 'Shorts',
    slug: 'women-shorts',
    depth: 2,
    subCategories: [],
    path: '/lululemon/women/women-shorts',
  },
  {
    categoryId: '8e26815d-487e-496f-b809-1005e24794d7',
    parentId: 'ffcffa02-a7cc-42d7-9898-55475e59d65e',
    name: 'Tank Tops',
    slug: 'women-tanks',
    depth: 3,
    subCategories: [],
    path: '/lululemon/women/women-maintops/women-tanks',
  },
  {
    categoryId: '90dbf9f2-a907-4f17-aa15-052b5fb27c76',
    parentId: '92757376-6482-4cc6-94ac-8bf37c262c4c',
    name: 'Short Sleeve Shirts',
    slug: 'men-ss-tops',
    depth: 3,
    subCategories: [],
    path: '/lululemon/men/men-tops/men-ss-tops',
  },
  {
    categoryId: '7b078db1-6f6e-45c2-ad6e-a7a929f4c0ed',
    parentId: 'ffcffa02-a7cc-42d7-9898-55475e59d65e',
    name: 'Short Sleeve Shirts',
    slug: 'tops-short-sleeve',
    depth: 3,
    subCategories: [],
    path: '/lululemon/women/women-maintops/tops-short-sleeve',
  },
  {
    categoryId: '661b4d7a-ab75-4dea-b035-b68325e23ac9',
    parentId: '247b1258-9bb7-4a6f-b252-69b303f319aa',
    name: 'Tights',
    slug: 'men-tights',
    depth: 3,
    subCategories: [],
    path: '/lululemon/men/men-pants/men-tights',
  },
  {
    categoryId: 'dc134ea1-a918-479b-9da2-7065c0554d56',
    parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
    name: 'Sweaters',
    slug: 'women-sweaters-and-wraps',
    depth: 2,
    subCategories: [],
    path: '/lululemon/women/women-sweaters-and-wraps',
  },
  {
    categoryId: '725411ea-b944-46d3-820c-8a206c137ba5',
    parentId: '247b1258-9bb7-4a6f-b252-69b303f319aa',
    name: 'Joggers',
    slug: 'men-joggers',
    depth: 3,
    subCategories: [],
    path: '/lululemon/men/men-pants/men-joggers',
  },
  {
    categoryId: '83d09b5a-9be7-42f4-8044-1a3ffe7081a6',
    parentId: '6288628d-e734-44fa-879c-9516168ba421',
    name: 'Water Bottles',
    slug: 'water-bottles',
    depth: 2,
    subCategories: [],
    path: '/lululemon/accessories/water-bottles',
  },
  {
    categoryId: 'a94cedce-f955-472c-91c8-700f7b79edaa',
    parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
    name: 'Dresses',
    slug: 'skirts-and-dresses-dresses',
    depth: 2,
    subCategories: [],
    path: '/lululemon/women/skirts-and-dresses-dresses',
  },
  {
    categoryId: '247b1258-9bb7-4a6f-b252-69b303f319aa',
    parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
    name: 'Pants',
    slug: 'men-pants',
    depth: 2,
    subCategories: [],
    path: '/lululemon/men/men-pants',
  },
  {
    categoryId: '234f444d-5dbb-4c0d-b730-e3cc5bdfe723',
    parentId: 'ffcffa02-a7cc-42d7-9898-55475e59d65e',
    name: 'Bodysuits',
    slug: 'women-bodysuits',
    depth: 3,
    subCategories: [],
    path: '/lululemon/women/women-maintops/women-bodysuits',
  },
  {
    categoryId: 'd7f0b0b3-5930-4dc6-b342-b4d163204425',
    parentId: '6288628d-e734-44fa-879c-9516168ba421',
    name: 'Hair Accessories',
    slug: 'hair-accessories',
    depth: 2,
    subCategories: [],
    path: '/lululemon/accessories/hair-accessories',
  },
  {
    categoryId: '0b528a67-e210-4081-92eb-74ee71a36d55',
    parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
    name: 'Sweaters',
    slug: 'mens-sweaters',
    depth: 2,
    subCategories: [],
    path: '/lululemon/men/mens-sweaters',
  },
  {
    categoryId: '55476bed-44d3-4169-9a9a-75c5780a69df',
    parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
    name: 'Socks',
    slug: 'women-socks',
    depth: 2,
    subCategories: [],
    path: '/lululemon/women/women-socks',
  },
  {
    categoryId: '81040327-4a41-41d6-9f5e-2a87961f9be5',
    parentId: '92757376-6482-4cc6-94ac-8bf37c262c4c',
    name: 'Tank Tops',
    slug: 'mens-tanks',
    depth: 3,
    subCategories: [],
    path: '/lululemon/men/men-tops/mens-tanks',
  },
  {
    categoryId: '0e840105-7920-488d-bd9d-e70d1c898fb7',
    parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
    name: 'Pants',
    slug: 'women-pants',
    depth: 2,
    subCategories: [],
    path: '/lululemon/women/women-pants',
  },
  {
    categoryId: 'aec44c5a-6bb4-45e6-9652-b8e5884427cf',
    parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
    name: 'Swimsuits',
    slug: 'tops-swim',
    depth: 2,
    subCategories: [],
    path: '/lululemon/women/tops-swim',
  },
  {
    categoryId: '1238cf40-7290-4345-9abc-0095440fc965',
    parentId: '6288628d-e734-44fa-879c-9516168ba421',
    name: 'Gloves & Mittens',
    slug: 'gloves-mittens',
    depth: 2,
    subCategories: [],
    path: '/lululemon/accessories/gloves-mittens',
  },
  {
    categoryId: 'c1600452-c428-4017-8b39-be2b0e76c2f7',
    parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
    name: 'Best Sellers',
    slug: 'best-sellers',
    depth: 2,
    subCategories: [],
    path: '/lululemon/men/best-sellers',
  },
  {
    categoryId: 'fc99a09a-9cc8-4953-aec0-5e290d12f775',
    parentId: '6288628d-e734-44fa-879c-9516168ba421',
    name: 'Equipment',
    slug: 'equipment',
    depth: 2,
    subCategories: [],
    path: '/lululemon/accessories/equipment',
  },
  {
    categoryId: '81bdd708-dc93-446a-a8d2-6a0ab44eb777',
    parentId: '6288628d-e734-44fa-879c-9516168ba421',
    name: 'Yoga Mats',
    slug: 'yoga-mats',
    depth: 2,
    subCategories: [],
    path: '/lululemon/accessories/yoga-mats',
  },
  {
    categoryId: '0ceb570e-9ad6-49bf-94e2-1fdfd0a9a804',
    parentId: '6288628d-e734-44fa-879c-9516168ba421',
    name: 'Hats',
    slug: 'hats',
    depth: 2,
    subCategories: [],
    path: '/lululemon/accessories/hats',
  },
  {
    categoryId: 'dc9fe9da-fdf0-4e94-aa2a-5632eeaaf3a2',
    parentId: '92757376-6482-4cc6-94ac-8bf37c262c4c',
    name: 'Button Down Shirts',
    slug: 'mens-button-down-shirts',
    depth: 3,
    subCategories: [],
    path: '/lululemon/men/men-tops/mens-button-down-shirts',
  },
  {
    categoryId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
    parentId: '0df65d87-9812-409e-912b-e1b2e8b4d2fc',
    name: 'Features',
    slug: 'features',
    depth: 1,
    subCategories: [
      {
        categoryId: 'cdd09d0c-a2fd-4d40-9562-6b27781796a5',
        parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
        name: "What's New",
        slug: 'whats-new',
        depth: 2,
        subCategories: [],
        path: '/lululemon/features/whats-new',
      },
      {
        categoryId: 'aae9a177-403f-4190-b9ad-79c8a7da8850',
        parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
        name: 'Core Products',
        slug: 'core-products',
        depth: 2,
        subCategories: [],
        path: '/lululemon/features/core-products',
      },
      {
        categoryId: '498d5717-0f1a-4036-8ab4-22a65a58d9dc',
        parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
        name: 'Featured Styles',
        slug: 'featured-styles',
        depth: 2,
        subCategories: [],
        path: '/lululemon/features/featured-styles',
      },
      {
        categoryId: 'd501e924-c99f-4b85-8c76-f1dd50ae22f5',
        parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
        name: 'VariantsUpto100',
        slug: 'variantsupto100',
        depth: 2,
        subCategories: [],
        path: '/lululemon/features/variantsupto100',
      },
      {
        categoryId: '5838e9cb-3e4e-4590-8237-03ba5be38731',
        parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
        name: 'VariantsUpto50',
        slug: 'variantsupto50',
        depth: 2,
        subCategories: [],
        path: '/lululemon/features/variantsupto50',
      },
      {
        categoryId: '68d6e6dd-2bd8-435f-9937-3031a56a4320',
        parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
        name: 'VariantsUpto300',
        slug: 'variantsupto300',
        depth: 2,
        subCategories: [],
        path: '/lululemon/features/variantsupto300',
      },
      {
        categoryId: '61ab1b5a-bf6f-4ff0-bc1e-67cc6a7afdf6',
        parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
        name: 'VariantsUpto200',
        slug: 'variantsupto200',
        depth: 2,
        subCategories: [],
        path: '/lululemon/features/variantsupto200',
      },
      {
        categoryId: 'd6c76845-5f6f-42e5-907f-ba7afd1b2f1b',
        parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
        name: 'Variants300AndMore',
        slug: 'variants300andmore',
        depth: 2,
        subCategories: [],
        path: '/lululemon/features/variants300andmore',
      },
      {
        categoryId: '4ae77c77-bc44-44f2-b0aa-28c816858b2f',
        parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
        name: "Mother's Day",
        slug: 'mothers-day',
        depth: 2,
        subCategories: [],
        path: '/lululemon/features/mothers-day',
      },
      {
        categoryId: '2ce97bd7-9278-43b2-bc13-8de1c578752a',
        parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
        name: 'Valentines Day',
        slug: 'valentine-day',
        depth: 2,
        subCategories: [],
        path: '/lululemon/features/valentine-day',
      },
    ],
    path: '/lululemon/features',
  },
  {
    categoryId: '498d5717-0f1a-4036-8ab4-22a65a58d9dc',
    parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
    name: 'Featured Styles',
    slug: 'featured-styles',
    depth: 2,
    subCategories: [],
    path: '/lululemon/features/featured-styles',
  },
  {
    categoryId: 'd501e924-c99f-4b85-8c76-f1dd50ae22f5',
    parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
    name: 'VariantsUpto100',
    slug: 'variantsupto100',
    depth: 2,
    subCategories: [],
    path: '/lululemon/features/variantsupto100',
  },
  {
    categoryId: '5838e9cb-3e4e-4590-8237-03ba5be38731',
    parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
    name: 'VariantsUpto50',
    slug: 'variantsupto50',
    depth: 2,
    subCategories: [],
    path: '/lululemon/features/variantsupto50',
  },
  {
    categoryId: '68d6e6dd-2bd8-435f-9937-3031a56a4320',
    parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
    name: 'VariantsUpto300',
    slug: 'variantsupto300',
    depth: 2,
    subCategories: [],
    path: '/lululemon/features/variantsupto300',
  },
  {
    categoryId: '61ab1b5a-bf6f-4ff0-bc1e-67cc6a7afdf6',
    parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
    name: 'VariantsUpto200',
    slug: 'variantsupto200',
    depth: 2,
    subCategories: [],
    path: '/lululemon/features/variantsupto200',
  },
  {
    categoryId: 'd6c76845-5f6f-42e5-907f-ba7afd1b2f1b',
    parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
    name: 'Variants300AndMore',
    slug: 'variants300andmore',
    depth: 2,
    subCategories: [],
    path: '/lululemon/features/variants300andmore',
  },
  {
    categoryId: '3847eae4-fa52-4398-a23e-2d1264398eaa',
    parentId: 'a51764a9-0f96-47d5-9c01-abbca76774f7',
    name: 'Bras & Underwear',
    slug: 'women-bras-and-underwear',
    depth: 2,
    subCategories: [],
    path: '/lululemon/women/women-bras-and-underwear',
  },
  {
    categoryId: '78516225-0a98-48ff-a01e-ba5dddfa100d',
    parentId: '6288628d-e734-44fa-879c-9516168ba421',
    name: 'Backpacks',
    slug: 'backpacks',
    depth: 2,
    subCategories: [],
    path: '/lululemon/accessories/backpacks',
  },
  {
    categoryId: '429f0984-1726-46d6-bd83-188dcc1f5a8b',
    parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
    name: 'Button Down Shirts',
    slug: 'button-down-shirts',
    depth: 2,
    subCategories: [],
    path: '/lululemon/men/button-down-shirts',
  },
  {
    categoryId: '63c9eb93-a455-4c08-87bf-7c9f93af232d',
    parentId: '419f7e2a-525f-4ebc-b06d-29c303978d76',
    name: 'Half Zip Sweatshirts',
    slug: 'half-zip-sweatshirts',
    depth: 2,
    subCategories: [],
    path: '/lululemon/men/half-zip-sweatshirts',
  },
  {
    categoryId: '9513254b-9cb9-450f-bd7e-a22523405be5',
    parentId: '6288628d-e734-44fa-879c-9516168ba421',
    name: 'Beanies',
    slug: 'beanies',
    depth: 2,
    subCategories: [],
    path: '/lululemon/accessories/beanies',
  },
  {
    categoryId: '4ae77c77-bc44-44f2-b0aa-28c816858b2f',
    parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
    name: "Mother's Day",
    slug: 'mothers-day',
    depth: 2,
    subCategories: [],
    path: '/lululemon/features/mothers-day',
  },
  {
    categoryId: '2ce97bd7-9278-43b2-bc13-8de1c578752a',
    parentId: '619413ac-fb0a-4a6f-8dc2-5ff3110f1bb2',
    name: 'Valentines Day',
    slug: 'valentine-day',
    depth: 2,
    subCategories: [],
    path: '/lululemon/features/valentine-day',
  },
];

export const billingAddress: Address = {
  addressId: 'F7dbZ3iR',
  city: 'New York',
  country: 'DE',
  firstName: 'Peter',
  lastName: 'Parker',
  phone: '+2 01124206669',
  postalCode: '10010',
  streetName: 'Queens',
  streetNumber: 'st.48',
};

export const shippingAddress: Address = {
  addressId: 'AaiPltT3',
  firstName: 'Priya',
  lastName: 'Richard',
  streetName: '50Th St',
  streetNumber: '64Th Ave',
  postalCode: '98004',
  city: 'Bellevue',
  country: 'US',
  state: 'Washington',
  isDefaultBillingAddress: false,
  isBillingAddress: false,
  isDefaultShippingAddress: false,
  isShippingAddress: true,
};

export const shippingAddresses = [
  {
    addressId: 'AaiPltT3',
    firstName: 'Priya',
    lastName: 'Richard',
    streetName: ' 50Th St ',
    streetNumber: '64Th Ave',
    postalCode: '98004',
    city: 'Bellevue',
    country: 'US',
    state: 'Washington',
    isDefaultBillingAddress: false,
    isBillingAddress: false,
    isDefaultShippingAddress: false,
    isShippingAddress: true,
  },
  {
    addressId: 'bYPwRwPY',
    firstName: 'Nikita',
    lastName: 'Shiva',
    streetName: 'Winterburn Road',
    streetNumber: '43',
    postalCode: '98030',
    city: 'Seattle',
    country: 'US',
    state: 'Washington',
    isDefaultBillingAddress: false,
    isBillingAddress: false,
    isDefaultShippingAddress: false,
    isShippingAddress: true,
  },
  {
    addressId: 'QqgGu5iB',
    firstName: 'Praveen',
    lastName: 'Joshi',
    streetName: 'Everitt Dri St',
    streetNumber: '160',
    postalCode: '98001',
    city: 'Redmond',
    country: 'US',
    state: 'Washington',
    isDefaultBillingAddress: false,
    isBillingAddress: false,
    isDefaultShippingAddress: true,
    isShippingAddress: true,
  },
];

export const defaultBillingAddress = {
  addressId: 'bYPwRwPY',
  firstName: 'Nikita',
  lastName: 'Shiva',
  streetName: 'Winterburn Road',
  streetNumber: '43',
  postalCode: '98030',
  city: 'Seattle',
  country: 'US',
  state: 'Washington',
  isDefaultBillingAddress: true,
  isBillingAddress: true,
  isDefaultShippingAddress: false,
  isShippingAddress: false,
};

export const billingAddresses = [
  {
    addressId: 'billingId1',
    firstName: 'Priya',
    lastName: 'Richard',
    streetName: ' 50Th St ',
    streetNumber: '64Th Ave',
    postalCode: '98004',
    city: 'Bellevue',
    country: 'US',
    state: 'Washington',
    isDefaultBillingAddress: false,
    isBillingAddress: true,
    isDefaultShippingAddress: false,
    isShippingAddress: false,
  },
  {
    addressId: 'bYPwRwPY',
    firstName: 'Nikita',
    lastName: 'Shiva',
    streetName: 'Winterburn Road',
    streetNumber: '43',
    postalCode: '98030',
    city: 'Seattle',
    country: 'US',
    state: 'Washington',
    isDefaultBillingAddress: true,
    isBillingAddress: true,
    isDefaultShippingAddress: false,
    isShippingAddress: false,
  },
  {
    addressId: 'QqgGu5iB',
    firstName: 'Praveen',
    lastName: 'Joshi',
    streetName: 'Everitt Dri St',
    streetNumber: '160',
    postalCode: '98001',
    city: 'Redmond',
    country: 'US',
    state: 'Washington',
    isDefaultBillingAddress: false,
    isBillingAddress: true,
    isDefaultShippingAddress: false,
    isShippingAddress: false,
  },
];

export const creditCards = {
  value: [
    {
      partnerIdUUID: 'testUUID1',
      cardBillingAddressId: 'billingId1',
      expires: '10/30',
      cardToken: '5798128711714291214001',
      maskedPan: '515531XXXXXX1211',
      cardType: CreditCardLabelType.visa,
      isCardPrimary: true,
      nameOnCard: 'Anshita',
      isExpired: false,
    },
    {
      partnerIdUUID: 'testUUID2',
      cardBillingAddressId: 'dM3LI4Kg',
      expires: '10/27',
      cardToken: '5798128711714291214002',
      maskedPan: '411131XXXXXX1267',
      cardType: CreditCardLabelType.masterCard,
      isCardPrimary: false,
      nameOnCard: 'XXX',
      isExpired: false,
    },
    {
      partnerIdUUID: 'PCA12145115-1q2w1e097',
      cardBillingAddressId: 'dM3LI4Kg',
      expires: '10/27',
      cardToken: '5798128711714291214003',
      maskedPan: '411131XXXXXX1234',
      cardType: CreditCardLabelType.visa,
      isCardPrimary: false,
      nameOnCard: 'XXX',
      isExpired: false,
    },
    {
      partnerIdUUID: 'PCA12145116-1q2w1e098',
      cardBillingAddressId: 'dM3LI4Kg',
      expires: '10/27',
      cardToken: '5798128711714291214004',
      maskedPan: '411131XXXXXX1245',
      cardType: CreditCardLabelType.masterCard,
      isCardPrimary: false,
      nameOnCard: 'XXX',
      isExpired: false,
    },
  ],
};

export const variant: Variant = {
  groupId: '78695',
  id: '1',
  images: [{ src: 'https://s3-eu-west-1.amazonaws.com/commercetools-maximilian/products/078695_1_large.jpg' }],
  sku: 'M0E20000000DSDJ',
  price: { centAmount: 71820, currencyCode: 'EUR', fractionDigits: 2 },
  isOnStock: true,
  attributes: { articleNumberManufacturer: '621540423 V0065', articleNumberMax: '78695', baseId: '78695', size: 'XXS' },
};

export const lineItemsOrderHistory: CartLineItem[] = [
  {
    lineItemId: '2b7427e1-d00b-4069-9731-a93aa92869c1',
    name: 'Freizeitjacke Stone Island schwarz',
    type: 'variant',
    _url: '/slug/p/M0E20000000DSDJ',
    count: 2,
    variant: variant,
    isGift: false,
    totalPrice: { centAmount: 71820, currencyCode: 'EUR', fractionDigits: 2 },
    price: { centAmount: 71820, currencyCode: 'EUR', fractionDigits: 2 },
    taxedPrice: {},
  },
  {
    lineItemId: '2b7427e1-d00b-4069-9731-a93aa92869c1',
    name: 'Freizeitjacke Stone Island schwarz',
    type: 'variant',
    _url: '/slug/p/M0E20000000DSDJ',
    count: 2,
    variant: variant,
    isGift: false,
    totalPrice: { centAmount: 71820, currencyCode: 'EUR', fractionDigits: 2 },
    price: { centAmount: 71820, currencyCode: 'EUR', fractionDigits: 2 },
    taxedPrice: {},
  },
];

export const moneyMock: Required<Money> = { centAmount: 71820, currencyCode: 'EUR', fractionDigits: 2 };

export const shippingInfoMock: ShippingInfo = {
  shippingMethodId: 'shipping-method-mock-id',
  price: moneyMock,
  discountedPrice: moneyMock,
};

const lineItems: LineItem[] = [
  {
    lineItemId: '0',
    _url: '/',
    addedAt: new Date(),
    count: 5,
    name: 'Some Article of clothing 1',
    type: 'Clothes',
    variant: {
      sku: 'SKU',
      images: [{ src: 'https://tailwindui.com/img/ecommerce-images/checkout-page-03-product-04.jpg' }],
    },
  },
  {
    lineItemId: '1',
    _url: '/',
    addedAt: new Date(),
    count: 5,
    name: 'Some Article of clothing 2',
    type: 'Clothes',
    variant: {
      sku: 'SKU',
      images: [{ src: 'https://tailwindui.com/img/ecommerce-images/shopping-cart-page-01-product-02.jpg' }],
    },
  },
  {
    lineItemId: '2',
    _url: '/',
    addedAt: new Date(),
    count: 5,
    name: 'Some Article of clothing 3',
    type: 'Clothes',
    variant: {
      sku: 'SKU',
      images: [{ src: 'https://tailwindui.com/img/ecommerce-images/shopping-cart-page-01-product-01.jpg' }],
    },
  },
];
export const wishlist: Wishlist = {
  wishlistId: '0',
  anonymousId: 'an',
  name: 'Wishlist Items',
  accountId: 'Acc-12-43',
  wishlistVersion: 'v 1.0',
  lineItems: lineItems,
};

export const accountToken: AccountToken = {
  token: 'token',
};

export const groups: Group[] = [
  {
    groupId: 'grp-1',
    groupNameAll: '__SYSTEM_ALL',
    name: 'Name',
  },
  {
    groupId: 'grp-2',
    groupNameAll: '__SYSTEM_ALL',
    name: 'Name 2',
  },
];
export const account: Account = {
  email: 'spiderman@friendlyNeighbourhood.com',
  id: 'usr-12345',
  firstName: 'Peter',
  lastName: 'Parker',
  password: '123456789',
  confirmationToken: accountToken,
  confirmed: true,
  groups: groups,
  phoneNumber: '9994343665',
  addresses: [
    {
      addressId: 'AaiPltT3',
      firstName: 'Priya',
      lastName: 'Richard',
      streetName: ' 50Th St ',
      streetNumber: '64Th Ave',
      postalCode: '98004',
      city: 'Bellevue',
      country: 'US',
      state: 'Washington',
      isDefaultBillingAddress: false,
      isBillingAddress: false,
      isDefaultShippingAddress: false,
      isShippingAddress: true,
    },
    {
      addressId: 'bYPwRwPY',
      firstName: 'Nikita',
      lastName: 'Shiva',
      streetName: 'Winterburn Road',
      streetNumber: '43',
      postalCode: '98030',
      city: 'Seattle',
      country: 'US',
      state: 'Washington',
      isDefaultBillingAddress: true,
      isBillingAddress: true,
      isDefaultShippingAddress: false,
      isShippingAddress: false,
    },
    {
      addressId: 'QqgGu5iB',
      firstName: 'Praveen',
      lastName: 'Joshi',
      streetName: 'Everitt Dri St',
      streetNumber: '160',
      postalCode: '98001',
      city: 'Redmond',
      country: 'US',
      state: 'Washington',
      isDefaultBillingAddress: false,
      isBillingAddress: false,
      isDefaultShippingAddress: true,
      isShippingAddress: true,
    },
  ],
  role: {
    key: 'partners-buyer',
    name: 'partners-buyer',
  },
  companyProfile: {
    name: 'LF-Test-Unit',
    companyId: 'LF-Test-Key',
  },
  permissions: ['admin'],
};

export const accountUpdateResponse: AccountUpdateReturn = {
  ...account,
  error: undefined,
};

export const frontasticImage: NextFrontasticImage = {
  media: {
    file: 'https://res.cloudinary.com/dlwdq84ig/image/upload/v1646648997/m1qzalyiebn9boow3tth.webp',
    height: 66,
    mediaId: 'm1qzalyiebn9boow3tth',
    name: 'Logo',
    width: 142,
  },
};

export const headerLogo = { media: 'https://cpmr-islands.org/wp-content/uploads/sites/4/2019/07/test.png' };

export const headerButtonLink = {
  type: 'page-folder',
  pageFolder: {
    pageFolderId: '595b5c44de02a8957bc5821913e58fa2',
    name: 'Start',
    _urls: { de_CH: '/', fr_CH: '/', it_CH: '/', de_LI: '/' },
    _url: '/',
  },
  openInNewWindow: false,
};

export const headerAccountLink = {
  type: 'link',
  link: '/account',
  openInNewWindow: false,
};

export const headerLinks = [
  {
    name: 'Men',
    reference: {
      type: 'page-folder',
      pageFolder: {
        pageFolderId: 'b27378fbe05639798e9b23399a373bc0',
        name: 'Men',
        _urls: { de_CH: '/men', fr_CH: '/men', it_CH: '/men', de_LI: '/men' },
        _url: '/men',
      },
      openInNewWindow: false,
    },
  },
  {
    name: 'Women',
    reference: {
      type: 'page-folder',
      pageFolder: {
        pageFolderId: 'b27378fbe05639798e9b23399a373bc0',
        name: 'Men',
        _urls: { de_CH: '/men', fr_CH: '/men', it_CH: '/men', de_LI: '/men' },
        _url: '/men',
      },
      openInNewWindow: false,
    },
  },
];

export const filters = [{ name: 'Color' }, { name: 'Category' }, { name: 'Size' }];
export const pricePropsCDP = {
  prices: '118',
  salePrices: '98',
};
export const colorsSwatchCDP = [
  {
    swatch: { id: '0001', name: 'Black' },
    imageUrl: 'https://images.lululemon.com/is/image/lululemon/LW3ECKS_0001_1',
    hoverImageUrl: 'https://images.lululemon.com/is/image/lululemon/LW3ECKS_0001_2',
    analyticsAttributes: {
      'data-lulu-track': 'color-swatch',
    },
  },
  {
    swatch: { id: '8313', name: 'Pink', isDisabled: true },
    imageUrl: 'https://images.lululemon.com/is/image/lululemon/LW3DM4S_8313_1',
    hoverImageUrl: 'https://images.lululemon.com/is/image/lululemon/LW3DM4S_8313_2',
    analyticsAttributes: {
      'data-lulu-track': 'color-swatch',
    },
  },
  {
    swatch: { id: '32493', name: 'Ice Cave' },
    imageUrl: 'https://images.lululemon.com/is/image/lululemon/LW3DUSS_032493_1',
    hoverImageUrl: 'https://images.lululemon.com/is/image/lululemon/LW3DUSS_032493_2',
    analyticsAttributes: {
      'data-lulu-track': 'color-swatch',
    },
  },
  {
    swatch: { id: '43635', name: 'Tempest Blue/Almost Blue' },
    imageUrl: 'https://images.lululemon.com/is/image/lululemon/LW3DM4S_043635_1',
    hoverImageUrl: 'https://images.lululemon.com/is/image/lululemon/LW3DM4S_043635_2',
    analyticsAttributes: {
      'data-lulu-track': 'color-swatch',
    },
  },
];
export const analyticsAttributesCDP = { 'data-lulu-track': 'pdp-link' };

export const inseams = ['26"', '28"', '30"', '32"', '34"', '36"', '38"'];
export const fitDetails = '25" length is intended to sit above ankle';

export const colors = [
  { id: '38293', name: 'Silver' },
  { id: '0001', name: 'Black' },
  { id: '35956', name: 'Grey' },
  { id: '30699', name: 'Garnet' },
  { id: '0001', name: 'Black' },
  { id: '28948', name: 'Red' },
  { id: '13211', name: 'Pink' },
  { id: '40090', name: 'Ice Cave' },
  { id: '40021', name: 'Blue' },
];
export const collections = [
  { id: 'Align', name: 'Align' },
  { id: 'Fast & Free', name: 'Fast & Free' },
  { id: 'Wunder Train', name: 'Wunder Train' },
  { id: 'Dance Studio', name: 'Dance Studio' },
  { id: 'Groove', name: 'Groove' },
  { id: 'Invigorate', name: 'Invigorate' },
  { id: 'Base Pace', name: 'Base Pace' },
  { id: 'Lululemon Lab', name: 'Lululemon Lab' },
  { id: 'Swift Speed', name: 'Swift Speed' },
];

export const modelInfo = {
  description: 'Aliya is 5’10" and wears a size 6',
};

export const alphaSizes = ['XXS', 'XS', 'S', 'M', 'L', 'XL'];
export const oneSize = ['ONE SIZE'];
export const numericSizes = ['0', '2', '4', '6', '8', '10', '12', '14', '16'];

export const userListData = [
  {
    accountId: 'cb0c9782-e410-451b-9067-e44a305a8b75',
    email: 'asrivastava6@lululemon.com',
    firstName: 'Anshita',
    lastName: 'Srivastava',
    confirmed: false,
    addresses: [
      {
        addressId: 'AaiPltT3',
        firstName: 'Priya',
        lastName: 'Richard',
        streetName: ' 50Th St ',
        streetNumber: '64Th Ave',
        postalCode: '98004',
        city: 'Bellevue',
        country: 'US',
        state: 'Washington',
        isDefaultBillingAddress: false,
        isBillingAddress: false,
        isDefaultShippingAddress: false,
        isShippingAddress: true,
      },
      {
        addressId: 'bYPwRwPY',
        firstName: 'Nikita',
        lastName: 'Shiva',
        streetName: 'Winterburn Road',
        streetNumber: '43',
        postalCode: '98030',
        city: 'Seattle',
        country: 'US',
        state: 'Washington',
        isDefaultBillingAddress: true,
        isBillingAddress: true,
        isDefaultShippingAddress: false,
        isShippingAddress: false,
      },
      {
        addressId: 'QqgGu5iB',
        firstName: 'Praveen',
        lastName: 'Joshi',
        streetName: 'Everitt Dri St',
        streetNumber: '160',
        postalCode: '98001',
        city: 'Redmond',
        country: 'US',
        state: 'Washington',
        isDefaultBillingAddress: false,
        isBillingAddress: false,
        isDefaultShippingAddress: true,
        isShippingAddress: true,
      },
    ],
    role: {
      key: 'partners-buyer',
      name: 'partners-buyer',
    },
    companyProfile: {
      name: 'LF-Test-Unit',
      companyId: 'LF-Test-Key',
    },
  },
  {
    accountId: 'a7031de5-da81-450a-95b0-1ca76a5d892b',
    email: 'bsingh38@llm.com',
    firstName: 'Baljinder',
    lastName: 'Singh',
    confirmed: false,
    addresses: [],
    phoneNumber: '9999190451',
    role: {
      key: 'partners-admin',
      name: 'partners-admin',
    },
    companyProfile: {
      name: 'LF-Test-Unit',
      companyId: 'LF-Test-Key',
    },
  },
  {
    accountId: '4289a42f-238f-4636-87ca-1304894ff77b',
    email: 'natchiar@lllm.com',
    salutation: '',
    firstName: 'Nat',
    lastName: 'chiar',
    confirmed: false,
    addresses: [
      {
        addressId: 'qZaaUgSx',
        streetName: 'Dallas Street',
        streetNumber: '45',
        country: 'CA',
        isDefaultBillingAddress: false,
        isBillingAddress: false,
        isDefaultShippingAddress: false,
        isShippingAddress: true,
      },
    ],
    phoneNumber: '9942084699',
    role: {
      key: 'partners-buyer',
      name: 'partners-buyer',
    },
    companyProfile: {
      name: 'LF-Test-Unit',
      companyId: 'LF-Test-Key',
    },
  },
];

export const randomMockPrice = (variants: any) => {
  const range = { min: 19, max: 120 };
  const delta = range.max - range.min;
  const priceArray = [];
  const variantCount = variants.length;
  if (variantCount > 1) {
    for (let i = 0; i < variantCount; i++) {
      priceArray.push(Math.round(range.min + Math.random() * delta));
    }
    return {
      prices: priceArray,
    };
  } else {
    return {
      prices: Math.round(range.min * delta),
      salePrices: Math.round(range.min + Math.random() * delta),
    };
  }
};

export const variantPrice = {
  priceData: {
    prices: ['86.24', '94.26'],
    salePrices: ['42.68', '68.24'],
    code: 'USD',
    locale: 'en-US',
  },
};

export const priceDataMockData = {
  fractionDigits: 12345,
  centAmount: 1234,
  currencyCode: '123',
};

export const pdpColorArray = [
  {
    swatch: {
      name: 'DRMA',
      description: 'Dramatic Magenta',
      id: '30722',
      sku: '148251191',
    },
    imageUrl: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_030722_1',
    hoverImageUrl: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_030722_2',
  },
  {
    swatch: {
      name: 'PRAS',
      description: 'Purple Ash',
      id: '55841',
      sku: '148234836',
    },
    imageUrl: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_055841_1',
    hoverImageUrl: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_055841_2',
  },
];
export const pdpVariants = [
  {
    id: '1',
    sku: '148251191',
    images: [
      { src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_030722_1' },
      { src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_030722_2' },
      { src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_030722_3' },
      { src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_030722_4' },
      { src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_030722_5' },
    ],
    attributes: {
      style_id: 'LW7BJ8T-030722',
      style_name: 'Speed Up High-Rise Lined Short 4"',
      care_instructions: 'icon-machine-wash-cold',
      colourCode: '30722',
      colourDescription: 'Dramatic Magenta',
      gender: 'Women',
      size: '2',
      style_option: 'LW7BJ8T',
      collection_type: 'Dance Studio',
    },
  },
  {
    id: '10',
    sku: '148234836',
    images: [
      { src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_055841_1' },
      { src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_055841_2' },
      { src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_055841_3' },
      { src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_055841_4' },
      { src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_055841_5' },
    ],
    attributes: {
      style_id: 'LW7BJ8T-055841',
      colourCode: '55841',
      colourDescription: 'Purple Ash',
      gender: 'Women',
      style_option: 'LW7BJ8T',
      size: '4',
      style_name: 'Speed Up High-Rise Lined Short 4"',
    },
  },
];
export const pdpCurrentVariant = {
  id: '1',
  sku: '148251191',
  images: [
    { src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_030722_1' },
    { src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_030722_2' },
    { src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_030722_3' },
    { src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_030722_4' },
    { src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_030722_5' },
  ],
  attributes: {
    style_id: 'LW7BJ8T-030722',
    style_name: 'Speed Up High-Rise Lined Short 4"',
    care_instructions: 'icon-machine-wash-cold',
    colourCode: '30722',
    colourDescription: 'Dramatic Magenta',
    gender: 'Women',
    size: '2',
    style_option: 'LW7BJ8T',
    collection_type: 'Dance Studio',
  },
  accordions: [],
};

export const pdpImages = [
  { src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_030722_1' },
  { src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_030722_2' },
  { src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_030722_3' },
  { src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_030722_4' },
  { src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_030722_5' },
];

export const pdpMappedProduct = {
  productId: 'b8978485-29a4-4810-b03a-09b17759619f',
  name: 'Speed Up High-Rise Lined Short 4"',
  variants: [
    {
      id: '1',
      sku: '148251191',
      images: [
        { src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_030722_1' },
        { src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_030722_2' },
        { src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_030722_3' },
        { src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_030722_4' },
        { src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_030722_5' },
      ],
      attributes: {
        style_id: 'LW7BJ8T-030722',
        style_name: 'Speed Up High-Rise Lined Short 4"',
        care_instructions: 'icon-machine-wash-cold',
        colourCode: '30722',
        colourDescription: 'Dramatic Magenta',
        gender: 'Women',
        size: '2',
        style_option: 'LW7BJ8T',
        collection_type: 'Dance Studio',
      },
      accordions: [],
    },
    {
      id: '10',
      sku: '148234836',
      images: [{ src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_055841_1', alt: '' }],
      attributes: {
        style_id: 'LW7BJ8T-055841',
        colourCode: '55841',
        colourDescription: 'Purple Ash',
        gender: 'Women',
        style_option: 'LW7BJ8T',
        size: '4',
        style_name: 'Speed Up High-Rise Lined Short 4"',
      },
      accordions: [],
    },
  ],
  price: {},
  categories: [
    {
      categoryId: '8e2a4cbf-d907-42c3-9ee9-7d047ca6ff64',
      subCategories: [],
    },
  ],
  images: [
    {
      id: '148251191-0',
      src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_030722_1',
      alt: '148251191',
    },
  ],
  colours: [
    {
      code: '30722',
      name: 'DRMA',
      description: 'Dramatic Magenta',
      sku: '148251191',
      images: [
        {
          src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_030722_1',
        },
      ],
    },
    {
      code: '55841',
      name: 'PRAS',
      description: 'Purple Ash',
      sku: '148234836',
      images: [
        {
          src: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_55841_1',
        },
      ],
    },
  ],
  colors: [
    {
      swatch: {
        name: 'DRMA',
        description: 'Dramatic Magenta',
        id: '30722',
        sku: '148251191',
      },
      imageUrl: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_030722_1',
      hoverImageUrl: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_030722_2',
    },
    {
      swatch: {
        name: 'PRAS',
        description: 'Purple Ash',
        id: '55841',
        sku: '148234836',
      },
      imageUrl: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_055841_1',
      hoverImageUrl: 'https://images.lululemon.com/is/image/lululemon/LW7BJ8T_055841_2',
    },
  ],
  inseams: [
    {
      label: '25',
      key: '3732238',
      description: '25” length is intended to sit above ankle',
      url: '/deep-cove-tee/p/3732238',
    },
    {
      label: '32',
      key: '1044522688',
      description: '32” length is intended to sit above ankle',
      url: '/train-to-conquer-sock-*silver/p/104452268',
    },
    {
      label: '34',
      key: '104451947',
      description: '34” length is intended to sit above ankle',
      url: '/to-the-beat-tight-24"-*lululemon-x-soulcycle/p/104451947',
    },
  ],
  sizes: ['2'],
  description:
    '\n          <p>Make it a long one. The Speed Up collection features a lightweight waistband and easy-access pockets so you can focus on your run, not your gear.</p>\n        ',
  details: [
    {
      name: 'Features',
      items: [null, 'Collection: undefined', null],
    },
  ],
};

export const pdpProductUrl = '/speed-up-high-rise-lined-short-4/p/148251191';

export const refinementsData = [
  { attribute: 'variants.attributes.size', label: 'O/S' },
  { attribute: 'variants.attributes.size', label: '6' },
  { attribute: 'variants.attributes.size', label: 'XXL' },
];
export const facetsConfiguration = {
  'variants.attributes.colourFamilies': {
    type: 'color',
    identifier: 'variants.attributes.colourFamilies',
    label: 'Color',
    key: 'variants.attributes.colourFamilies',
    selected: false,
    terms: [
      { identifier: 'black_swatch', label: 'Black', count: 16, key: 'Black', selected: false },
      { identifier: 'grey_swatch', label: 'Grey', count: 7, key: 'Grey', selected: false },
      { identifier: 'blue_swatch', label: 'Blue', count: 3, key: 'Blue', selected: false },
      { identifier: '32664', label: 'Navy', count: 3, key: 'Navy', selected: false },
    ],
  },
  'variants.attributes.inseam': {
    type: 'color',
    identifier: 'variants.attributes.inseam',
    label: 'Inseam',
    key: 'variants.attributes.inseam',
    selected: false,
    terms: [
      { identifier: '20', label: '20', count: 2, key: '20', selected: false },
      { identifier: '22', label: '22', count: 1, key: '22', selected: false },
      { identifier: '25', label: '25', count: 1, key: '25', selected: false },
    ],
  },
  'variants.attributes.size': {
    type: 'term',
    identifier: 'variants.attributes.size',
    label: 'Size',
    key: 'variants.attributes.size',
    selected: false,
    terms: [
      { identifier: 'O/S', label: 'O/S', count: 80, key: 'O/S', selected: false, group: 3 },
      { identifier: '0', label: '0', count: 218, key: '0', selected: false, group: 1 },
      { identifier: '2', label: '2', count: 472, key: '2', selected: false, group: 1 },
      { identifier: '4', label: '4', count: 501, key: '4', selected: false, group: 1 },
      { identifier: '5', label: '5', count: 2, key: '5', selected: false, group: 1 },
    ],
  },
  'variants.attributes.franchise': {
    type: 'term',
    identifier: 'variants.attributes.franchise',
    label: 'Collection Type',
    key: 'variants.attributes.franchise',
    selected: false,
    terms: [
      { identifier: 'Fast & Free', label: 'Fast & Free', count: 1, key: 'Fast & Free', selected: false },
      { identifier: 'Everywhere', label: 'Everywhere', count: 1, key: 'Everywhere', selected: false },
      { identifier: 'Wundermost', label: 'Wundermost', count: 2, key: 'Wundermost', selected: false },
      { identifier: 'Groove', label: 'Groove', count: 1, key: 'Groove', selected: false },
      { identifier: 'Invigorate', label: 'Invigorate', count: 1, key: 'Invigorate', selected: false },
    ],
  },
  'variants.scopedPriceDiscounted': {
    type: 'boolean',
    identifier: 'variants.scopedPriceDiscounted',
    label: 'Sale',
    key: 'variants.scopedPriceDiscounted',
    selected: false,
    terms: [
      { identifier: 'Fast & Free', label: 'Fast & Free', count: 1, key: 'T', selected: false },
      { identifier: 'Everywhere', label: 'Everywhere', count: 1, key: 'Everywhere', selected: false },
      { identifier: 'Wundermost', label: 'Wundermost', count: 2, key: 'Wundermost', selected: false },
      { identifier: 'Groove', label: 'Groove', count: 1, key: 'Groove', selected: false },
      { identifier: 'Invigorate', label: 'Invigorate', count: 1, key: 'Invigorate', selected: false },
    ],
  },
  'variants.price': {
    type: 'range',
    identifier: 'variants.price',
    label: 'Price',
    key: 'variants.price',
    min: 0,
    max: 0,
    selected: false,
    ranges: [
      {
        min: 1,
        max: 9,
      },
    ],
  },
};
export const filterMockData = {
  'variants.price': {
    type: 'range',
    identifier: 'variants.price',
    label: 'Price',
    key: 'variants.price',
    min: 0,
    max: 0,
    selected: false,
  },
  'variants.attributes.colourDescription': {
    type: 'color',
    identifier: 'variants.attributes.colourDescription',
    label: 'Color',
    key: 'variants.attributes.colourDescription',
    selected: false,
    terms: [
      { identifier: 'True Navy', label: 'True Navy', count: 2, key: 'True Navy', selected: false },
      { identifier: 'Sage/Dark Olive', label: 'Sage/Dark Olive', count: 1, key: 'Sage/Dark Olive', selected: false },
      { identifier: 'Midnight Navy', label: 'Midnight Navy', count: 1, key: 'Midnight Navy', selected: false },
      {
        identifier: 'Heathered Core Light Grey',
        label: 'Heathered Core Light Grey',
        count: 1,
        key: 'Heathered Core Light Grey',
        selected: false,
      },
      { identifier: 'Blazer Blue', label: 'Blazer Blue', count: 1, key: 'Blazer Blue', selected: false },
      { identifier: 'Black/Black', label: 'Black/Black', count: 1, key: 'Black/Black', selected: false },
      { identifier: 'Black Cherry', label: 'Black Cherry', count: 1, key: 'Black Cherry', selected: false },
    ],
  },
  'variants.scopedPriceDiscounted': {
    type: 'boolean',
    identifier: 'variants.scopedPriceDiscounted',
    label: 'Sale',
    key: 'variants.scopedPriceDiscounted',
    selected: false,
    terms: [],
  },
  'variants.attributes.size': {
    type: 'term',
    identifier: 'variants.attributes.size',
    label: 'Size',
    key: 'variants.attributes.size',
    selected: false,
    terms: [
      {
        identifier: '0',
        label: '0',
        count: 201,
        key: '0',
        selected: false,
        group: 1,
      },
      {
        identifier: '4XL',
        label: '4XL',
        count: 132,
        key: '4XL',
        selected: false,
        group: 4,
      },
      {
        identifier: '5XL',
        label: '5XL',
        count: 135,
        key: '5XL',
        selected: false,
        group: 4,
      },
    ],
  },
  'variants.attributes.gender': {
    type: 'term',
    identifier: 'variants.attributes.gender',
    label: 'Gender',
    key: 'variants.attributes.gender',
    selected: false,
    terms: [
      { identifier: 'Womens', label: 'Womens', count: 6, key: 'Womens', selected: false },
      { identifier: 'Women', label: 'Women', count: 2, key: 'Women', selected: false },
    ],
  },
  'variants.attributes.style_id': {
    type: 'term',
    identifier: 'variants.attributes.style_id',
    label: 'Collection',
    key: 'variants.attributes.style_id',
    selected: false,
    terms: [
      { identifier: 'LW7BJ8T-031382', label: 'LW7BJ8T-031382', count: 1, key: 'LW7BJ8T-031382', selected: false },
      { identifier: 'LW7AKDS', label: 'LW7AKDS', count: 1, key: 'LW7AKDS', selected: false },
      { identifier: 'LW6AHJS', label: 'LW6AHJS', count: 1, key: 'LW6AHJS', selected: false },
      { identifier: 'LW5BJHS', label: 'LW5BJHS', count: 1, key: 'LW5BJHS', selected: false },
      { identifier: 'LW3BFCS', label: 'LW3BFCS', count: 1, key: 'LW3BFCS', selected: false },
      { identifier: 'LW3AJES', label: 'LW3AJES', count: 1, key: 'LW3AJES', selected: false },
      { identifier: 'LW1AHVS', label: 'LW1AHVS', count: 1, key: 'LW1AHVS', selected: false },
      { identifier: 'LW1AHPS', label: 'LW1AHPS', count: 1, key: 'LW1AHPS', selected: false },
    ],
  },
  'variants.attributes.collection_type': {
    type: 'term',
    identifier: 'variants.attributes.collection_type',
    label: 'Collection Type',
    key: 'variants.attributes.collection_type',
    selected: false,
    terms: [],
  },
};
export const markets = [
  {
    locale: 'en_US',
    currencyCode: '$',
    region: 'United States',
    flag: 'US',
    currency: 'USD',
    language: 'English',
  },
  {
    locale: 'fr_CA',
    currencyCode: '$',
    region: 'Canada',
    flag: 'CAN',
    currency: 'CAD',
    language: 'Français',
  },
  {
    locale: 'en_CA',
    currencyCode: '$',
    region: 'Canada',
    flag: 'CAN',
    currency: 'CAD',
    language: 'English',
  },
];

export const ProductCarouselData = {
  mobile: true,
  tablet: true,
  desktop: true,
  data: {
    dataSourceId: '6e6d0077-e68c-4a15-a4cf-0e277d92b043',
    dataSource: {
      total: 6,
      items: [
        {
          productId: '766a1553-1af3-44ca-a0e6-04143d775436',
          version: '4',
          name: 'Align Crop 21"',
          slug: '104400014',
          categories: [
            {
              categoryId: 'e6e41214-d070-404f-a2cf-73cc778f6b6c',
            },
          ],
          variants: [
            {
              id: '1',
              sku: '104400014',
              images: ['https://images.lululemon.com/is/image/lululemon/LW6AXOS_033261_5'],
              attributes: {
                style_id: 'LW6AXOS',
                style_name: 'Align Crop 21"',
                care_instructions: 'careWarnings',
                colourCode: '33261',
                colourDescription: 'Monochromic Black Emboss',
                gender: 'Womens',
                size: '18',
                style_option: 'LW6AXOS-033261',
              },
            },
          ],
          _url: '/align-crop-21"/p/104400014',
        },
        {
          productId: '753b1f6a-d6c8-4b6c-9284-512d71c5417a',
          version: '9',
          name: 'All for It Beanie',
          slug: '122000229',
          description:
            'From crisp mornings to cool evenings, this lightweight beanie delivers soft warmth for every day.',
          categories: [
            {
              categoryId: 'e6e41214-d070-404f-a2cf-73cc778f6b6c',
            },
          ],
          variants: [
            {
              id: '1',
              sku: '122000229',
              images: ['https://images.lululemon.com/is/image/lululemon/LU9AEHS_032493_1'],
              attributes: {
                style_id: 'LU9AEHS',
                style_name: 'All for It Beanie',
                care_instructions: 'icon-hand-wash',
                colourCode: '32493',
                colourDescription: 'Heathered Core Ultra Light Grey',
                gender: 'Unisex',
                size: 'O/S',
                style_option: 'LU9AEHS-032493',
              },
            },
          ],
          _url: '/all-for-it-beanie/p/122000229',
        },
      ],
      count: 6,
      facets: [
        {
          type: 'term',
          identifier: 'variants.attributes.short_size',
          label: 'variants.attributes.short_size',
          key: 'variants.attributes.short_size',
          selected: false,
          terms: [],
        },
      ],
    },
  },
  title: 'Shop this collection',
  callToAction: 'Shop all mens',
  webRoute: '',
  tilesPerPage: 4,
  showColorsOnHover: true,
  showColorsSwatches: true,
};

// It is used in tastics/products/list/list.test.tsx
export const facets = [
  {
    type: 'term',
    identifier: 'variants.attributes.size',
    label: 'variants.attributes.size',
    key: 'variants.attributes.size',
    selected: false,
    terms: [],
  },
];

export const ProductCarouselEmptyData = {
  mobile: true,
  tablet: true,
  desktop: true,
  data: {
    dataSourceId: '6e6d0077-e68c-4a15-a4cf-0e277d92b043',
    dataSource: {
      total: 6,
      items: [],
    },
  },
  title: 'Shop this collection',
  callToAction: 'Shop all mens',
  webRoute: '',
  tilesPerPage: 4,
  showColorsOnHover: true,
  showColorsSwatches: true,
};

export const businessUnit: BusinessUnit = {
  id: '1efa09b3-e779-4bb1-9ad2-4e9e33cab016',
  version: 157,
  key: 'LF-Test-Key',
  name: 'LF-Test-Unit',
  addresses: [
    {
      addressId: 'rSDWKl53',
      firstName: 'Anshita ',
      lastName: 'Srivastava',
      streetName: '123 Street',
      streetNumber: '8765',
      additionalStreetInfo: '',
      additionalAddressInfo: '',
      postalCode: '98101',
      city: 'Seattle',
      country: 'US',
      state: 'ren',
      phone: '9876543210',
      isDefaultBillingAddress: false,
      isBillingAddress: false,
      isDefaultShippingAddress: false,
      isShippingAddress: true,
    },
    {
      addressId: 'iU-1ANLR',
      streetName: 'stname',
      streetNumber: '156',
      country: 'GE',
      isDefaultBillingAddress: false,
      isBillingAddress: false,
      isDefaultShippingAddress: false,
      isShippingAddress: true,
    },
    {
      addressId: 'MsFAOsiB',
      firstName: 'test',
      lastName: 'user1',
      streetName: 'unit B11111',
      streetNumber: '8500 148th AVE, NE',
      additionalStreetInfo: '',
      additionalAddressInfo: '',
      postalCode: '98052',
      city: 'REDMOND',
      country: 'US',
      state: '',
      phone: '5555555555',
      isDefaultBillingAddress: false,
      isBillingAddress: false,
      isDefaultShippingAddress: false,
      isShippingAddress: true,
    },
    {
      addressId: 'Gckt4-6V',
      firstName: 'Baljinder',
      lastName: 'Singh',
      streetName: 'sample',
      streetNumber: 'b1007',
      additionalStreetInfo: '',
      additionalAddressInfo: '',
      postalCode: '98052',
      city: 'redmond',
      country: 'US',
      state: 'ren',
      phone: '',
      isDefaultBillingAddress: false,
      isBillingAddress: true,
      isDefaultShippingAddress: false,
      isShippingAddress: false,
    },
    {
      addressId: '-hRoYEHi',
      streetName: 'Any Street',
      streetNumber: '1337',
      postalCode: '11111',
      city: 'Any City',
      country: 'US',
      isDefaultBillingAddress: false,
      isBillingAddress: false,
      isDefaultShippingAddress: false,
      isShippingAddress: false,
    },
    {
      addressId: 'lBe1lWNX',
      firstName: 'bill',
      lastName: 'add',
      streetName: 'Anywayyyyy street',
      streetNumber: 'c1337',
      postalCode: '11111',
      city: 'Any City',
      country: 'US',
      state: 'Washington',
      isDefaultBillingAddress: true,
      isBillingAddress: true,
      isDefaultShippingAddress: false,
      isShippingAddress: false,
    },
    {
      addressId: 'ko_MiMEl',
      firstName: 'test',
      lastName: 'user',
      streetName: 'Havanna Crescent',
      streetNumber: 'd10',
      postalCode: 'L6P3Y1',
      city: 'Toronto',
      country: 'CA',
      isDefaultBillingAddress: false,
      isBillingAddress: true,
      isDefaultShippingAddress: false,
      isShippingAddress: false,
    },
    {
      addressId: 'VcdIwYZx',
      firstName: 'Baljinder',
      lastName: 'Singh',
      streetName: 'Havanna Crescent',
      streetNumber: '10',
      postalCode: 'L6P3Y1',
      city: 'Toronto',
      country: 'CA',
      state: 'Ontario',
      isDefaultBillingAddress: false,
      isBillingAddress: false,
      isDefaultShippingAddress: false,
      isShippingAddress: true,
    },
    {
      addressId: 'kKNt5Ylt',
      firstName: 'Shipping ',
      lastName: 'User',
      streetName: 'Test Street',
      streetNumber: '10',
      postalCode: '98101',
      city: 'Seattle',
      country: 'US',
      state: 'Washington',
      isDefaultBillingAddress: false,
      isBillingAddress: false,
      isDefaultShippingAddress: true,
      isShippingAddress: true,
    },
  ],
  shippingAddressIds: ['rSDWKl53', 'iU-1ANLR', 'MsFAOsiB', 'VcdIwYZx', 'kKNt5Ylt'],
  billingAddressIds: ['Gckt4-6V', 'lBe1lWNX', 'ko_MiMEl'],
  defaultShippingAddressId: 'kKNt5Ylt',
  defaultBillingAddressId: 'lBe1lWNX',

  associates: [
    {
      customer: {
        typeId: 'customer',
        id: 'a6960ede-4b24-4e5f-907f-f2a9ef5024c0',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'partners-admin',
          },
          inheritance: 'Disabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: '1e8e60cf-22b3-4653-a92e-7e032baee40d',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'Junior-Partnerrole-key',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: 'cb0c9782-e410-451b-9067-e44a305a8b75',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: 'b02394de-0e69-47ff-9ea8-c9f74b77db70',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'finance',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: 'd4a9ce89-fc17-473a-aac5-ed8cd516e12e',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: '2aaf742b-a0e6-4d4c-bcad-c41790a2668e',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: 'eb9fc13b-9545-463d-a177-60f6358816d3',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: 'a7031de5-da81-450a-95b0-1ca76a5d892b',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: '9a06e513-0f84-4fe1-babf-bafe886dafee',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: 'bca077d5-f665-4637-8c9e-eec1349008f3',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'finance',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: '29b8d5d9-935e-41d5-895f-7c5765ac2c03',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: 'e57a1b89-d0b0-4232-8d0b-57a7e3831d03',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: '0bb5f7df-b745-4a1b-aa1d-c33df0085932',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: '13b88d01-8afb-4267-a668-f884d724c14e',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: '0d5ce016-ef97-4299-9870-54e59248f15f',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: '4e6b9148-490e-4509-95a3-51ab5df53c75',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: 'bbf6ef28-fa51-4314-8c59-893e757cb62d',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: 'c7671a8a-0ac5-4099-8347-a8ac3e2b0c30',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: '00363f7b-b471-46b9-9baa-5d204d7073f1',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'buyer',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: 'baa0e5c6-f38a-401a-a727-60b902aeebae',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'finance',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: '422031ab-ae3a-49ac-9d18-b042ec6eacfa',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
  ],
  custom: {
    type: {
      typeId: 'type',
      id: '6d2428ce-efa8-4ffd-b3f9-7e4c9a9a1cab',
    },
    fields: {
      credit_card_flag: true,
      partner_country: 'us',
      key_accounts_pre_buy_flag: true,
      invoice_terms: '30',
      invoice_flag: true,
      existing_partner_flag: false,
      partner_key: 'LF-Test-Key',
    },
  },
};

export const inseamFilters = {
  type: 'term',
  identifier: 'variants.attributes.inseam',
  label: 'Inseam',
  key: 'variants.attributes.inseam',
  selected: false,
  terms: [
    {
      identifier: '25',
      label: '25',
      count: 79,
      key: '25',
      selected: false,
    },
    {
      identifier: '28',
      label: '28',
      count: 78,
      key: '28',
      selected: false,
    },
  ],
};

export const canadaBusinessUnit = {
  id: 'test',
  version: 1,
  key: 'Test-Key',
  name: 'Test-Unit',
  custom: {
    type: {
      typeId: 'type',
      id: '123',
    },
    fields: {
      credit_card_flag: true,
      partner_country: 'ca',
      key_accounts_pre_buy_flag: true,
      invoice_terms: '30',
      invoice_flag: true,
      existing_partner_flag: false,
      partner_key: 'Test-Key',
    },
  },
};

export const collectionMockData = {
  type: 'term',
  identifier: 'variants.attributes.collection_type',
  label: 'Collection Type',
  key: 'variants.attributes.collection_type',
  selected: false,
  terms: [
    {
      identifier: 'Trend',
      label: 'Trend',
      count: 1,
      key: 'Trend',
      selected: false,
    },
    {
      identifier: 'Surge',
      label: 'Surge',
      count: 1,
      key: 'Surge',
      selected: false,
    },
    {
      identifier: 'Dance Studio',
      label: 'Dance Studio',
      count: 1,
      key: 'Dance Studio',
      selected: false,
    },
    {
      identifier: 'Breeze By Muscle Tank Top',
      label: 'Breeze By Muscle Tank Top',
      count: 1,
      key: 'Breeze By Muscle Tank Top',
      selected: false,
    },
    {
      identifier: 'Align',
      label: 'Align',
      count: 1,
      key: 'Align',
      selected: false,
    },
    {
      identifier: 'Test',
      label: 'Test',
      count: 1,
      key: 'Test',
      selected: false,
    },
  ],
};

export const collectionMockWithTwoItemsData = {
  type: 'term',
  identifier: 'variants.attributes.collection_type',
  label: 'Collection Type',
  key: 'variants.attributes.collection_type',
  selected: false,
  terms: [
    {
      identifier: 'Trend',
      label: 'Trend',
      count: 1,
      key: 'Trend',
      selected: false,
    },
    {
      identifier: 'Surge',
      label: 'Surge',
      count: 1,
      key: 'Surge',
      selected: false,
    },
  ],
};
export const creditCardInfo: CreditCardType = {
  partnerIdUUID: 'PCA12145115-1q2w1gh97',
  cardBillingAddressId: 'dM3LI4Kg',
  expires: '10/30',
  cardToken: '5798128711714291214001',
  maskedPan: '515531XXXXXX1211',
  cardType: CreditCardLabelType.visa,
  isCardPrimary: true,
  nameOnCard: 'Anshita',
  isExpired: false,
};

export const inValidcreditCardInfo: CreditCardType = {
  partnerIdUUID: 'PCA12145115-1q2w1gh97',
  cardBillingAddressId: 'dM3LI4Kg',
  expires: '10/22',
  cardToken: '5798128711714291214001',
  maskedPan: '515531XXXXXX1211',
  cardType: CreditCardLabelType.visa,
  isCardPrimary: true,
  nameOnCard: '',
};

export const editModalPropsForRemove: ModalCyberSourceRequestMapper = {
  isModalOpen: false,
  isConfirmationModalOpen: false,
  isWarningMessageModalOpen: true,
  creditCardDetails: creditCardInfo,
  listCardsLength: creditCards.value.length,
};

export const editModalPropsForconfirmationModal: ModalCyberSourceRequestMapper = {
  isModalOpen: false,
  isConfirmationModalOpen: true,
  isWarningMessageModalOpen: false,
  creditCardDetails: creditCardInfo,
  listCardsLength: creditCards.value.length,
};

export const editModalProps: ModalCyberSourceRequestMapper = {
  isModalOpen: true,
  isConfirmationModalOpen: false,
  isWarningMessageModalOpen: false,
  creditCardDetails: creditCardInfo,
  listCardsLength: creditCards.value.length,
};

export const editModalPropsForInvalidForm: ModalCyberSourceRequestMapper = {
  isModalOpen: true,
  isConfirmationModalOpen: false,
  isWarningMessageModalOpen: false,
  creditCardDetails: inValidcreditCardInfo,
  listCardsLength: creditCards.value.length,
};

export const creditCardInfoWithNoDefault: CreditCardType = {
  partnerIdUUID: 'PCA12145115-1q2w1gh97',
  cardBillingAddressId: 'dM3LI4Kg',
  expires: '10/30',
  cardToken: '5798128711714291214001',
  maskedPan: '515531XXXXXX1211',
  cardType: CreditCardLabelType.visa,
  isCardPrimary: false,
  nameOnCard: 'Anshita',
  isExpired: false,
};

export const editModalPropsWithNoDefault: ModalCyberSourceRequestMapper = {
  isModalOpen: true,
  isConfirmationModalOpen: false,
  isWarningMessageModalOpen: false,
  creditCardDetails: creditCardInfoWithNoDefault,
  listCardsLength: creditCards.value.length,
};

export const accountDataForCreditCards = {
  id: '29b8d5d9-935e-41d5-895f-7c5765ac2c03',
  accountId: '29b8d5d9-935e-41d5-895f-7c5765ac2c03',
  version: 52,
  email: 'natchiaradmin@lllm.com',
  firstName: 'natchiar',
  lastName: 'venkat',
  phoneNumber: '9942084699',
  isLocked: 'unlocked',
  isCustomFieldAttached: true,
  role: {
    key: 'admin',
    name: 'Admin',
  },
  companyProfile: {
    name: 'LF-Test-Unit',
    companyId: 'LF-Test-Key',
  },
  custom: {
    type: {
      typeId: 'type',
      id: '50a2ea92-5074-4e83-804f-9773f1ce7ac0',
    },
    fields: {
      partner_user_region: 'us',
      customer_login_lock_state: 'unlocked',
      user_phone_number: '9942084699',
      existing_partner_user_flag: true,
      failed_login_count: 0,
      partner_user_status: 'active',
    },
  },
  permissions: [
    'UpdateAssociates',
    'ViewOthersCarts',
    'ViewMyCarts',
    'CreateMyOrdersFromMyCarts',
    'UpdateOthersOrders',
    'UpdateOthersCarts',
    'ViewMyOrders',
    'UpdateParentUnit',
    'DeleteMyCarts',
    'UpdateMyCarts',
    'DeleteOthersCarts',
    'UpdateBusinessUnitDetails',
    'ViewOthersOrders',
    'UpdateMyOrders',
    'AddChildUnits',
    'CreateOrdersFromOthersQuotes',
    'CreateMyOrdersFromMyQuotes',
    'CreateOrdersFromOthersCarts',
    'CreateMyCarts',
    'CreateOthersCarts',
    'edit_credit_cards',
    'edit_phone_numbers',
    'view_user_list',
    'view_shipping_address',
    'view_payment_method',
  ],
};
export const getAccountConfig = {
  myAccount: {
    userName: {
      view: true,
    },
    email: {
      view: true,
    },
    phoneNumber: {
      view: true,
      edit: true,
    },
    password: {
      view: true,
    },
  },
  companyInfo: {
    companyName: {
      view: true,
    },
    companyID: {
      view: true,
    },
  },
  paymentMethod: {
    view: true,
    edit: true,
  },
  orders: {
    view: true,
  },
  addresses: {
    shipping: {
      view: true,
    },
    invoice: {
      view: false,
    },
  },
  userList: {
    view: true,
  },
};

export const creditCardsListData: CreditCardType[] = [
  {
    partnerIdUUID: 'PCA12145115-1q2w1gh97',
    cardBillingAddressId: 'dM3LI4Kg',
    expires: '10/30',
    cardToken: '5798128711714291214001',
    maskedPan: '515531XXXXXX1211',
    cardType: CreditCardLabelType.visa,
    isCardPrimary: true,
    nameOnCard: 'Anshita',
    isExpired: false,
  },
  {
    partnerIdUUID: 'PCA12145115-1q2w1e497',
    cardBillingAddressId: 'dM3LI4Kg',
    expires: '10/27',
    cardToken: '5798128711714291214002',
    maskedPan: '411131XXXXXX1267',
    cardType: CreditCardLabelType.masterCard,
    isCardPrimary: false,
    nameOnCard: 'XXX',
    isExpired: false,
  },
  {
    partnerIdUUID: 'PCA12145115-1q2w1e097',
    cardBillingAddressId: 'dM3LI4Kg',
    expires: '10/27',
    cardToken: '5798128711714291214003',
    maskedPan: '411131XXXXXX1234',
    cardType: CreditCardLabelType.visa,
    isCardPrimary: false,
    nameOnCard: 'XXX',
    isExpired: false,
  },
  {
    partnerIdUUID: 'PCA12145116-1q2w1e098',
    cardBillingAddressId: 'dM3LI4Kg',
    expires: '10/27',
    cardToken: '5798128711714291214004',
    maskedPan: '411131XXXXXX1245',
    cardType: CreditCardLabelType.masterCard,
    isCardPrimary: false,
    nameOnCard: 'XXX',
    isExpired: false,
  },
];

export const ProductPromotionCarouselData = {
  mobile: true,
  tablet: true,
  desktop: true,
  categorySlug: 'men',
  totalProducts: 4,
  data: {
    dataSourceId: '6e6d0077-e68c-4a15-a4cf-0e277d92b043',
    dataSource: {
      total: 6,
      items: [
        {
          productId: '766a1553-1af3-44ca-a0e6-04143d775436',
          version: '4',
          name: 'Align Crop 21"',
          slug: '104400014',
          categories: [
            {
              categoryId: 'e6e41214-d070-404f-a2cf-73cc778f6b6c',
            },
          ],
          variants: [
            {
              id: '1',
              sku: '104400014',
              images: ['https://images.lululemon.com/is/image/lululemon/LW6AXOS_033261_5'],
              attributes: {
                style_id: 'LW6AXOS',
                style_name: 'Align Crop 21"',
                care_instructions: 'careWarnings',
                colourCode: '33261',
                colourDescription: 'Monochromic Black Emboss',
                gender: 'Womens',
                size: '18',
                style_option: 'LW6AXOS-033261',
              },
            },
          ],
          _url: '/align-crop-21"/p/104400014',
        },
        {
          productId: '753b1f6a-d6c8-4b6c-9284-512d71c5417a',
          version: '9',
          name: 'All for It Beanie',
          slug: '122000229',
          description:
            'From crisp mornings to cool evenings, this lightweight beanie delivers soft warmth for every day.',
          categories: [
            {
              categoryId: 'e6e41214-d070-404f-a2cf-73cc778f6b6c',
            },
          ],
          variants: [
            {
              id: '1',
              sku: '122000229',
              images: ['https://images.lululemon.com/is/image/lululemon/LU9AEHS_032493_1'],
              attributes: {
                style_id: 'LU9AEHS',
                style_name: 'All for It Beanie',
                care_instructions: 'icon-hand-wash',
                colourCode: '32493',
                colourDescription: 'Heathered Core Ultra Light Grey',
                gender: 'Unisex',
                size: 'O/S',
                style_option: 'LU9AEHS-032493',
              },
            },
          ],
          _url: '/all-for-it-beanie/p/122000229',
        },
      ],
      count: 6,
      facets: [
        {
          type: 'term',
          identifier: 'variants.attributes.short_size',
          label: 'variants.attributes.short_size',
          key: 'variants.attributes.short_size',
          selected: false,
          terms: [],
        },
      ],
    },
  },
  title: 'Shop this collection',
  callToAction: 'Shop all mens',
  webRoute: '',
  tilesPerPage: 4,
  promotionTitle: 'test',
  promotionDescription: 'test',
  promotionImage: 'test',
  promotionTargetText: 'test',
  promotionTarget: 'test',
  showProductCard: true,
};

export const ProductPromotionCarouselEmptyData = {
  mobile: true,
  tablet: true,
  desktop: true,
  categorySlug: 'men',
  totalProducts: 4,
  data: {
    dataSourceId: '6e6d0077-e68c-4a15-a4cf-0e277d92b043',
    dataSource: {
      total: 6,
      items: [],
    },
  },
  title: 'Shop this collection',
  callToAction: 'Shop all mens',
  webRoute: '',
  tilesPerPage: 4,
  showColorsOnHover: true,
  showColorsSwatches: true,
  promotionTitle: 'test',
  promotionDescription: 'test',
  promotionImage: 'test',
  promotionTargetText: 'test',
  promotionTarget: 'test',
  showProductCard: true,
};
export const query = 'bag';

export const colorFamilyFilter = {
  type: 'color',
  identifier: 'variants.attributes.colourFamilies',
  label: 'Color',
  key: 'variants.attributes.colourFamilies',
  selected: false,
  terms: [
    {
      identifier: 'pink_swatch',
      label: 'Pink',
      count: 2,
      key: 'Pink',
      selected: false,
    },
    {
      identifier: 'blue_swatch',
      label: 'Blue',
      count: 2,
      key: 'Blue',
      selected: false,
    },
    {
      identifier: '43899',
      label: 'Printed',
      count: 1,
      key: 'Printed',
      selected: false,
    },
    {
      identifier: 'black_swatch',
      label: 'Black',
      count: 3,
      key: 'Black',
      selected: false,
    },
  ],
};
export const categoryPromotionCard: any = {
  total: 4,
  count: 4,
  facets: [],
  items: [
    {
      productId: 'd03a6b74-0a77-49ff-98e8-f6a5fd631d2d',
      name: 'Shop this collection',
      slug: 'City-Adventurer-Backpack',
      description: 'City Adventurer Backpack II',
      retailPriceRange: ['50.00', '58.00'],
      wholesalePriceRange: ['40.20', '52.20'],
      inseams: [],
      categories: [
        {
          categoryId: '8abb2def-9e7b-4fb4-9cad-a1830e8845a1',
        },
      ],
      variants: [
        {
          id: '1',
          sku: '3820788',
          images: [
            {
              src: 'https://images.lululemon.com/is/image/lululemon/LW9BLIS_028603_1',
            },
          ],
          attributes: {
            size: 'O/S',
            colourId: 'LW9BLIS-028603',
          },
          retailPrice: '50.00',
          wholesalePrice: '40.20',
          currencyCode: 'USD',
          accordions: {},
        },
      ],
      _url: '/city-adventurer-backpack-20l/p/3820788',
    },
    {
      productId: 'd0c75ad5-8332-42b7-91fc-95590aaf0af2',
      name: 'More Miles Keychain',
      slug: 'More-Miles-Keychain',
      description: 'More Miles Keychain',
      retailPriceRange: ['58.00', '58.00'],
      wholesalePriceRange: ['52.20', '52.20'],
      inseams: [],
      categories: [
        {
          categoryId: '8abb2def-9e7b-4fb4-9cad-a1830e8845a1',
        },
      ],
      variants: [
        {
          id: '1',
          sku: '113125059',
          images: [
            {
              src: 'https://images.lululemon.com/is/image/lululemon/LM9965S_0001_1',
            },
          ],
          attributes: {
            colourDescription: 'Black',
            careInstructions: '',
            size: 'O/S',
          },
        },
      ],
      _url: '/more-miles-keychain/p/113125059',
    },
  ],
  query: {
    productIds: [],
    skus: [],
    categories: ['6288628d-e734-44fa-879c-9516168ba421'],
    priceChannel: 'a424925b-79f2-4a06-bb83-f46159ae2a08',
    categorySlug: '',
    primaryInventoryChannelId: '1349b515-427f-42ed-8495-321fb7d5e4f3',
    filters: [],
    limit: '4',
  },
};

export const categoryCardDataWithProduct = {
  mobile: true,
  tablet: true,
  desktop: true,
  showPromotionCard: true,
  categorySlug: 'accessories',
  title: 'Shop this collection',
  totalProducts: 4,
  showProductCard: false,
  tilesPerPage: 4,
  callToAction: '',
  webRoute: '',
  promotionTitle: '',
  promotionImage: '',
  promotionDescription: '',
  promotionTargetText: '',
  promotionTarget: '',
  items: categoryPromotionCard,
};

export const categoryCardData = {
  mobile: true,
  tablet: true,
  desktop: true,
  categorySlug: '',
  title: '',
  totalProducts: 4,
  showProductCard: false,
  tilesPerPage: '',
  callToAction: '',
  webRoute: '',
  promotionTitle: '',
  promotionImage: '',
  promotionDescription: '',
  promotionTargetText: '',
  promotionTarget: '',
  items: categoryPromotionCard,
  showPromotionCard: false,
};

export const getYearOptions = [
  { label: '2024', value: '2024' },
  { label: '2025', value: '2025' },
  { label: '2026', value: '2026' },
  { label: '2027', value: '2027' },
  { label: '2028', value: '2028' },
  { label: '2029', value: '2029' },
  { label: '2030', value: '2030' },
  { label: '2031', value: '2031' },
  { label: '2032', value: '2032' },
  { label: '2033', value: '2033' },
];

export const listCreditCardsApiResponse = {
  isError: false,
  data: {
    id: 'e18e5fec-4d07-47c2-affd-f08aa4c8f2d5',
    version: 109,
    versionModifiedAt: '2024-05-03T23:57:02.472Z',
    createdAt: '2024-02-23T07:08:06.511Z',
    lastModifiedAt: '2024-05-03T23:57:02.472Z',
    lastModifiedBy: {
      clientId: 'dSgb44MeQGEB2QIJHVpL_ZSj',
      isPlatformClient: false,
    },
    createdBy: {
      clientId: 'rXAtsDbuCcSeK4YA3mfYXd3I',
      isPlatformClient: false,
      customer: {
        typeId: 'customer',
        id: '5810a355-b2f3-4dc8-8c7b-8eaca8fc77f6',
      },
    },
    container: 'lll-partner-tokenized-card',
    key: 'LF-Test-Key',
    value: [
      {
        partnerIdUUID: 'LF-Test-Key-233b49fa83c114bb',
        expires: '01/27',
        cardToken: '7146007815836368003954',
        maskedPan: '411111XXXXXX1111',
        cardType: 'visa',
        cardBillingAddressId: 'ko_MiMEl',
        nameOnCard: 'Nat1',
        isCardPrimary: true,
      },
      {
        partnerIdUUID: 'LF-Test-Key-c5ea44492fa22112',
        expires: '05/2028',
        cardToken: '7147646180276457503954',
        maskedPan: '411111XXXXXX1111',
        cardType: 'visa',
        cardBillingAddressId: 'lBe1lWNX',
        nameOnCard: 'Nat1',
        isCardPrimary: false,
      },
      {
        partnerIdUUID: 'LF-Test-Key-6c2c44a41ec4d596',
        expires: '05/29',
        cardToken: '7147805926566748803954',
        maskedPan: '411111XXXXXX1111',
        cardType: 'visa',
        cardBillingAddressId: 'ko_MiMEl',
        nameOnCard: 'Test QA',
        isCardPrimary: false,
      },
    ],
  },
};
export const newshippingAddresses = [
  {
    addressId: 'bYPwRwPY',
    firstName: 'Nikita',
    lastName: 'Shiva',
    streetName: 'Winterburn Road',
    streetNumber: '43',
    postalCode: '98030',
    city: 'Seattle',
    country: 'US',
    state: 'Washington',
    isDefaultBillingAddress: false,
    isBillingAddress: false,
    isDefaultShippingAddress: false,
    isShippingAddress: true,
  },
  {
    addressId: 'QqgGu5iB',
    firstName: 'Praveen',
    lastName: 'Joshi',
    streetName: 'Everitt Dri St',
    streetNumber: '160',
    postalCode: '98001',
    city: 'Redmond',
    country: 'US',
    state: 'Washington',
    isDefaultBillingAddress: false,
    isBillingAddress: false,
    isDefaultShippingAddress: true,
    isShippingAddress: true,
  },
];

export const mockDataOptionAddress = [
  {
    value: 'g83fnl3W',
    label: '  FirstName LastName 1331 Trumbull STE 100 Detroit Michigan 48216 US',
    isDefaultValue: false,
  },
  {
    value: 'I4nsbfGO',
    label: '  Bob beck 3450 Aviation Ave Anchorage AK 99502 US',
    isDefaultValue: false,
  },
  {
    value: 'ggJiO-gg',
    label: '  ngc-shiipingonly test 375 Hudson St New York NY 10014 US',
    isDefaultValue: true,
  },
  {
    value: 'JYm4VwV4',
    label: '  ngc tester6 6588 Test Drive6 Austin TX 78727 US',
    isDefaultValue: false,
  },
  {
    value: 'YC_nr8hY',
    label: '  ngc-billing tester5 43543 Test Dr5 Plano TX 75024 US',
    isDefaultValue: false,
  },
  {
    value: 'l4-1y0kz',
    label: '  ngc-shipping tester4 356 Test Dr4 GA MC 75063 US',
    isDefaultValue: false,
  },
];

export const businessUnitWithNoPaymentFlags: BusinessUnit = {
  id: '1efa09b3-e779-4bb1-9ad2-4e9e33cab016',
  version: 157,
  key: 'LF-Test-Key',
  name: 'LF-Test-Unit',
  addresses: [
    {
      addressId: 'rSDWKl53',
      firstName: 'Anshita ',
      lastName: 'Srivastava',
      streetName: '123 Street',
      streetNumber: '8765',
      additionalStreetInfo: '',
      additionalAddressInfo: '',
      postalCode: '98101',
      city: 'Seattle',
      country: 'US',
      state: 'ren',
      phone: '9876543210',
      isDefaultBillingAddress: false,
      isBillingAddress: false,
      isDefaultShippingAddress: false,
      isShippingAddress: true,
    },
    {
      addressId: 'iU-1ANLR',
      streetName: 'stname',
      streetNumber: '156',
      country: 'GE',
      isDefaultBillingAddress: false,
      isBillingAddress: false,
      isDefaultShippingAddress: false,
      isShippingAddress: true,
    },
    {
      addressId: 'MsFAOsiB',
      firstName: 'test',
      lastName: 'user1',
      streetName: 'unit B11111',
      streetNumber: '8500 148th AVE, NE',
      additionalStreetInfo: '',
      additionalAddressInfo: '',
      postalCode: '98052',
      city: 'REDMOND',
      country: 'US',
      state: '',
      phone: '5555555555',
      isDefaultBillingAddress: false,
      isBillingAddress: false,
      isDefaultShippingAddress: false,
      isShippingAddress: true,
    },
    {
      addressId: 'Gckt4-6V',
      firstName: 'Baljinder',
      lastName: 'Singh',
      streetName: 'sample',
      streetNumber: 'b1007',
      additionalStreetInfo: '',
      additionalAddressInfo: '',
      postalCode: '98052',
      city: 'redmond',
      country: 'US',
      state: 'ren',
      phone: '',
      isDefaultBillingAddress: false,
      isBillingAddress: true,
      isDefaultShippingAddress: false,
      isShippingAddress: false,
    },
    {
      addressId: '-hRoYEHi',
      streetName: 'Any Street',
      streetNumber: '1337',
      postalCode: '11111',
      city: 'Any City',
      country: 'US',
      isDefaultBillingAddress: false,
      isBillingAddress: false,
      isDefaultShippingAddress: false,
      isShippingAddress: false,
    },
    {
      addressId: 'lBe1lWNX',
      firstName: 'bill',
      lastName: 'add',
      streetName: 'Anywayyyyy street',
      streetNumber: 'c1337',
      postalCode: '11111',
      city: 'Any City',
      country: 'US',
      state: 'Washington',
      isDefaultBillingAddress: true,
      isBillingAddress: true,
      isDefaultShippingAddress: false,
      isShippingAddress: false,
    },
    {
      addressId: 'ko_MiMEl',
      firstName: 'test',
      lastName: 'user',
      streetName: 'Havanna Crescent',
      streetNumber: 'd10',
      postalCode: 'L6P3Y1',
      city: 'Toronto',
      country: 'CA',
      isDefaultBillingAddress: false,
      isBillingAddress: true,
      isDefaultShippingAddress: false,
      isShippingAddress: false,
    },
    {
      addressId: 'VcdIwYZx',
      firstName: 'Baljinder',
      lastName: 'Singh',
      streetName: 'Havanna Crescent',
      streetNumber: '10',
      postalCode: 'L6P3Y1',
      city: 'Toronto',
      country: 'CA',
      state: 'Ontario',
      isDefaultBillingAddress: false,
      isBillingAddress: false,
      isDefaultShippingAddress: false,
      isShippingAddress: true,
    },
    {
      addressId: 'kKNt5Ylt',
      firstName: 'Shipping ',
      lastName: 'User',
      streetName: 'Test Street',
      streetNumber: '10',
      postalCode: '98101',
      city: 'Seattle',
      country: 'US',
      state: 'Washington',
      isDefaultBillingAddress: false,
      isBillingAddress: false,
      isDefaultShippingAddress: true,
      isShippingAddress: true,
    },
  ],
  shippingAddressIds: ['rSDWKl53', 'iU-1ANLR', 'MsFAOsiB', 'VcdIwYZx', 'kKNt5Ylt'],
  billingAddressIds: ['Gckt4-6V', 'lBe1lWNX', 'ko_MiMEl'],
  defaultShippingAddressId: 'kKNt5Ylt',
  defaultBillingAddressId: 'lBe1lWNX',

  associates: [
    {
      customer: {
        typeId: 'customer',
        id: 'a6960ede-4b24-4e5f-907f-f2a9ef5024c0',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'partners-admin',
          },
          inheritance: 'Disabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: '1e8e60cf-22b3-4653-a92e-7e032baee40d',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'Junior-Partnerrole-key',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: 'cb0c9782-e410-451b-9067-e44a305a8b75',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: 'b02394de-0e69-47ff-9ea8-c9f74b77db70',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'finance',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: 'd4a9ce89-fc17-473a-aac5-ed8cd516e12e',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: '2aaf742b-a0e6-4d4c-bcad-c41790a2668e',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: 'eb9fc13b-9545-463d-a177-60f6358816d3',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: 'a7031de5-da81-450a-95b0-1ca76a5d892b',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: '9a06e513-0f84-4fe1-babf-bafe886dafee',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: 'bca077d5-f665-4637-8c9e-eec1349008f3',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'finance',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: '29b8d5d9-935e-41d5-895f-7c5765ac2c03',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: 'e57a1b89-d0b0-4232-8d0b-57a7e3831d03',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: '0bb5f7df-b745-4a1b-aa1d-c33df0085932',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: '13b88d01-8afb-4267-a668-f884d724c14e',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: '0d5ce016-ef97-4299-9870-54e59248f15f',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: '4e6b9148-490e-4509-95a3-51ab5df53c75',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: 'bbf6ef28-fa51-4314-8c59-893e757cb62d',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: 'c7671a8a-0ac5-4099-8347-a8ac3e2b0c30',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: '00363f7b-b471-46b9-9baa-5d204d7073f1',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'buyer',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: 'baa0e5c6-f38a-401a-a727-60b902aeebae',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'finance',
          },
          inheritance: 'Enabled',
        },
      ],
    },
    {
      customer: {
        typeId: 'customer',
        id: '422031ab-ae3a-49ac-9d18-b042ec6eacfa',
      },
      associateRoleAssignments: [
        {
          associateRole: {
            typeId: 'associate-role',
            key: 'admin',
          },
          inheritance: 'Enabled',
        },
      ],
    },
  ],
  custom: {
    type: {
      typeId: 'type',
      id: '6d2428ce-efa8-4ffd-b3f9-7e4c9a9a1cab',
    },
    fields: {
      partner_country: 'us',
      key_accounts_pre_buy_flag: true,
      invoice_terms: '30',
      existing_partner_flag: false,
      partner_key: 'LF-Test-Key',
    },
  },
};
export const products = [
  {
    productId: 'ffa3f1a9-2cfa-4dc7-936f-60e7a5028104',
    productKey: 'ynqnw123456',
    name: 'More Miles Keychain',
    slug: 'Demo',
    description: '',
    retailPriceRange: ['58.00', '58.00'],
    wholesalePriceRange: ['52.20', '52.20'],
    categories: [
      {
        categoryId: '7b078db1-6f6e-45c2-ad6e-a7a929f4c0ed',
      },
    ],
    variants: [
      {
        id: '1',
        sku: '104775245',
        assets: [
          {
            src: 'https://images.lululemon.com/is/image/lululemon/LW3CIPS_039496_1',
            alt: 'LW3CIPS_039496_1',
            assetType: 'PRODUCT_IMAGE',
          },
          {
            src: 'https://images.lululemon.com/is/image/lululemon/LW3CIPS_039496_2',
            alt: 'LW3CIPS_039496_2',
            assetType: 'PRODUCT_IMAGE',
          },
          {
            src: 'https://images.lululemon.com/is/image/lululemon/LW3CIPS_039496_3',
            alt: 'LW3CIPS_039496_3',
            assetType: 'PRODUCT_IMAGE',
          },
          {
            src: 'https://images.lululemon.com/is/image/lululemon/LW3CIPS_039496_4',
            alt: 'LW3CIPS_039496_4',
            assetType: 'PRODUCT_IMAGE',
          },
          {
            src: 'https://images.lululemon.com/is/image/lululemon/LW3CIPS_039496_5',
            alt: 'LW3CIPS_039496_5',
            assetType: 'PRODUCT_IMAGE',
          },
          {
            src: 'https://images.lululemon.com/is/image/lululemon/33093?$swatch$',
            alt: '33093?$swatch$',
            assetType: 'COLOUR_FAMILY_SWATCH_IMAGE',
          },
        ],
        images: [
          {
            src: 'https://images.lululemon.com/is/image/lululemon/LW3CIPS_039496_1',
          },
        ],
        attributes: {
          productFeatures: ['Lightweight'],
          fitFeatures: ['A loose fit with a little extra room', 'Cut above the waist—perfect with high-rise pants'],
          fabricFeatures: [
            'Soft',
            'Four-way stretch',
            'Naturally breathable',
            'Added Lycra® fibre for stretch and shape retention',
          ],
          fitHeader: 'Relaxed Fit, Cropped Length',
          designIntent: 'Feel that post-practice bliss all day long in this soft, lightweight tee.',
          fabricIcon: 'fabric_soft',
          fabricDescription: 'Super-Soft, Pima Cotton Fabric',
          hazmat: false,
          materialAndCare:
            '[{"delete_ind":"false","part_number":"1","content":"92% Pima Cotton, 8% Lycra Elastane,","part":"Body"}]',
          colourCode: '039496',
          fitShortDescription: ['Relaxed Fit'],
          styleId: 'LW3CIPS',
          fabric: 'Naturals Pima Cotton Jersey Solid 130gsm',
          fitIcon: ['icon_silhouette'],
          currentSeasonCode: '19-1SP',
          colourDescription: 'Ocean Mist/Golden Lime/Palm Court',
          careInstructions:
            '[{"icon": "icon-machine-wash-cold", "text": "Machine Wash Cold"}, {"icon": "icon-do-not-bleach", "text": "Do Not Bleach"}, {"icon": "icon-gentle-cycle-tumble-dry-low", "text": "Tumble Dry Low"}, {"icon": "icon-do-not-iron", "text": "Do Not Iron"}, {"icon": "icon-do-not-dry-clean", "text": "Do Not Dry Clean"}, {"icon": null, "text": "Wash With Like Colors"}]',
          size: '2',
          heroBannerHotSpotText: 'Olivia is 5\'9" and wears a size 6.',
          designedFor: ['On the Move'],
          colourName: 'OCMS/GLDL/PLMC',
          taxCode: 200,
          sizeChartLink: 'womens-tops',
          bestSeller: false,
          colourId: 'LW3CIPS-039496',
          activity: ['Casual'],
          categoryBreadcrumb: 'Commerce Root>women>Shirts>Short Sleeve Shirts',
          gender: ['Women'],
          unitWeight: 0.61,
          usStartDate: '2024-03-31T18:30:00.000Z',
          usEndDate: '2024-04-29T18:30:00.000Z',
          canStartDate: '2024-03-31T18:30:00.000Z',
          canEndDate: '2024-04-29T18:30:00.000Z',
          colourFamilies: ['Green'],
          fitLongDescription: 'Relaxed',
          designedForIcon: 'icon_everyday',
        },
        retailPrice: '58.00',
        wholesalePrice: '52.20',
        currencyCode: 'USD',
        modelInfo: 'Olivia is 5\'9" and wears a size 6.',
        designIntent: 'Feel that post-practice bliss all day long in this soft, lightweight tee.',
        accordions: [
          {
            iconId: 'classesIcon',
            title: 'On the Move',
            sections: [
              {
                attributes: [
                  {
                    text: 'Casual',
                  },
                ],
              },
            ],
          },
          {
            iconId: 'genericFabricIcon',
            title: 'Material and care',
            sections: [
              {
                title: 'Materials',
                attributes: [],
              },
              {
                title: 'Care',
                attributes: [],
              },
            ],
          },
          {
            iconId: 'softIcon',
            title: 'Super-Soft, Pima Cotton Fabric',
            sections: [
              {
                title: 'Naturals Pima Cotton Jersey Solid 130gsm',
                attributes: [
                  {
                    text: 'Soft',
                  },
                  {
                    text: 'Four-way stretch',
                  },
                  {
                    text: 'Naturally breathable',
                  },
                  {
                    text: 'Added Lycra® fibre for stretch and shape retention',
                  },
                ],
              },
            ],
          },
          {
            iconId: 'silhouetteIcon',
            title: 'Relaxed Fit, Cropped Length',
            sections: [
              {
                attributes: [
                  {
                    text: 'Relaxed Fit',
                  },
                  {
                    text: 'A loose fit with a little extra room',
                  },
                  {
                    text: 'Cut above the waist—perfect with high-rise pants',
                  },
                ],
              },
            ],
          },
          {
            iconId: 'genericFeaturesIcon',
            title: 'Features',
            sections: [
              {
                attributes: [
                  {
                    text: 'Lightweight',
                  },
                ],
              },
            ],
          },
        ],
        fitDetails: 'fitLongDescription',
      },
    ],
    sizes: {},
    _url: '/p/ynqnwy3ket',
  },
];

export const sizeFacetMock = {
  type: 'term',
  identifier: 'variants.attributes.size',
  label: 'Size',
  key: 'variants.attributes.size',
  selected: false,
  terms: [
    {
      identifier: 'O/S',
      label: 'O/S',
      count: 91,
      key: 'O/S',
      selected: false,
      group: 3,
    },
    {
      identifier: '0',
      label: '0',
      count: 303,
      key: '0',
      selected: false,
      group: 1,
    },
  ],
};

export const colorFacetsData = {
  type: 'color',
  identifier: 'variants.attributes.colourFamilies',
  label: 'Color',
  key: 'variants.attributes.colourFamilies',
  selected: false,
  terms: [
    {
      identifier: 'black_swatch',
      label: 'Black',
      count: 79,
      key: 'Black',
      selected: false,
    },
    {
      identifier: 'blue_swatch',
      label: 'Pink',
      count: 66,
      key: 'Pink',
      selected: false,
    },
  ],
};

export const creditCardsWithExpiredDates = {
  value: [
    {
      partnerIdUUID: 'testUUID1',
      cardBillingAddressId: 'billingId1',
      expires: '10/20',
      cardToken: '5798128711714291214001',
      maskedPan: '515531XXXXXX1211',
      cardType: 'Visa',
      isCardPrimary: true,
      nameOnCard: 'Anshita',
      cardPrimary: true,
      isExpired: true,
    },
    {
      partnerIdUUID: 'testUUID12',
      cardBillingAddressId: 'billingId1',
      expires: '10/27',
      cardToken: '5798128711714291214001',
      maskedPan: '515531XXXXXX1211',
      cardType: 'Visa',
      isCardPrimary: true,
      nameOnCard: 'Anshita',
      cardPrimary: true,
      isExpired: true,
    },
  ],
};
