import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Market, HandleMarketProps } from 'shared/types/header';
import { getLocalizationInfo } from 'project.config';
const noRedirectionPages: Array<string> = [
  '/login',
  '/reset-password',
  '/account',
  '/forgot-password',
  '/session-timeout',
];

const useMarket = () => {
  const [markets, setMarkets] = useState<Market[]>([]);
  const [market, setMarket] = useState<Market>();
  const router = useRouter();

  const handleMarket = ({ market, isSameRegion = false }: HandleMarketProps) => {
    setMarket(market);
    const asPath = router?.asPath || '';
    let doRedirect = true;

    if (asPath === '/') {
      doRedirect = false;
    } else {
      const index = noRedirectionPages.findIndex((elem: any) => {
        return asPath.startsWith(elem);
      });
      if (index !== -1) doRedirect = false;
    }

    if (isSameRegion || !doRedirect) {
      router.push(router.asPath, router.asPath, { locale: market.locale });
    } else {
      router.push('/', '/', { locale: market.locale });
    }
  };

  useEffect(() => {
    const initialMarkets = router.locales?.map((nextLocale) => {
      const {
        locale,
        currency,
        currencyCode,
        countryCode,
        countryName,
        language = 'English',
        shortName,
      } = getLocalizationInfo(nextLocale);

      return {
        locale,
        currencyCode,
        region: countryName,
        flag: countryCode,
        currency: currency,
        language,
        shortName,
      };
    });

    if (!initialMarkets) return;

    setMarkets(initialMarkets);

    let initialMarket = initialMarkets.find((market) => market.locale === router.locale);
    if (!initialMarket) {
      initialMarket = initialMarkets.find((market) => market.locale === router.defaultLocale);
    }

    setMarket(initialMarket);
  }, [router.locale, router.defaultLocale, router.locales]);

  return { market, markets, handleMarket };
};

export default useMarket;
