import classnames from 'classnames';
// import MarketButton from 'components/commercetools-ui/organisms/market-button/market-button';
import styles from './top-nav-desktop-bar.module.scss';
import ShippingLocation from '../shipping-location';

const TopNavDesktopBar = () => {
  return (
    <div className={classnames(styles.topNavDesktopBar)}>
      <ShippingLocation />
      {/* <MarketButton /> */}
    </div>
  );
};

export default TopNavDesktopBar;
