import React from 'react';
import ContactUs, { Data } from '../../../ui/customer-support/components/contact-us';

interface Props {
  data: Data;
}

const ContactUsTastic = ({ data }: Props) => {
  return <ContactUs data={data} />;
};

export default ContactUsTastic;
