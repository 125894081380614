import { Product } from 'shared/types/product';
export type Locale = string | undefined;

export const getPrice = (product: Product, locale: Locale) => {
  const currencyCode = product.variants.find((v) => v.currencyCode)?.currencyCode;
  const showDecimals = !(
    product.retailPriceRange?.every((price) => price.includes('.00')) &&
    product.wholesalePriceRange?.every((price) => price.includes('.00'))
  );
  return {
    prices: product.retailPriceRange,
    salePrices: product.wholesalePriceRange,
    locale,
    showDecimals,
    currencyCode,
    showCurrencyCode: false,
  };
};
