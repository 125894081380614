export enum CreditCardLabelType {
  masterCard = 'Mastercard',
  visa = 'Visa',
  discover = 'Discover',
  jcb = 'JCB',
  americanexpress = 'Amex',
}

export interface CreditCardType {
  partnerIdUUID: string;
  expires: string;
  cardToken: string;
  maskedPan: string;
  cardType: CreditCardLabelType | '';
  cardBillingAddressId: string;
  nameOnCard: string;
  isCardPrimary: boolean;
  isExpired?: boolean;
}

export interface RequestCyberSourceDataMapper {
  container?: string;
  key?: string | '';
  value: CreditCardType[];
}

export interface ModalCyberSourceRequestMapper {
  isModalOpen: boolean;
  isConfirmationModalOpen?: boolean;
  iswarningMessageModalOpen?: boolean;
  isWarningMessageModalOpen?: boolean;
  creditCardDetails: CreditCardType;
  listCardsLength: number;
}

export interface DataPaymentSessionMapper {
  type?: string;
  attributes: AttributesPaymentSessionMapper;
}

export interface AttributesPaymentSessionMapper {
  partnerIdUUID: string;
  targetOrigin?: string;
  captureContext?: string;
}
export interface RequestHeaderPaymentSessionMapper {
  apikey: string;
}

export interface RequestparamsPaymentSessionMapper {
  channel: string;
}

export interface RequestResponsePaymentSessionMapper {
  data: DataPaymentSessionMapper[];
}

export interface AttributesSaveTokenMapper {
  partnerIdUUID: string;
  jwttoken: string;
  cardHolderName: string;
  cardBillingAddressId: string;
  isCardPrimary: boolean;
  email: string;
}

export interface RequestParamsSaveToken {
  type?: string;
  attributes: AttributesSaveTokenMapper;
}

export interface RequestSaveTokenMapper {
  data: RequestParamsSaveToken[];
}

export interface PaymentDetailsResponse {
  isError: boolean;
  data: RequestCyberSourceDataMapper;
  error?: any;
}

export interface ContextResponseMapper {
  isError: boolean;
  data: RequestResponsePaymentSessionMapper;
  error?: any;
}
export interface PaymentDetailsResponse {
  isError: boolean;
  data: RequestCyberSourceDataMapper;
}
