export const PartnerIdUUID = (): string => {
  const formatKey = 'xxxx4xxxyxxxxxyx';
  const timestamp = new Date().getTime().toString();

  function generateRAndomDigits(digits: number): string {
    let result = '';
    for (let i = 0; i < digits; i++) {
      result += Math.floor(Math.random() * 10);
    }
    return result;
  }

  const randomDigits: string = generateRAndomDigits(10 - timestamp.length);
  let generatedResult = parseInt(timestamp + randomDigits);

  return formatKey.replace(/[xy]/g, function (c) {
    const r = (generatedResult + Math.random() * 16) % 16 | 0;
    generatedResult = Math.floor(generatedResult / 16);
    return `${(c === 'x' ? r : r & (0x3 | 0x8)).toString(16)}`;
  });
};
