import React, { FC, useState } from 'react';
import { Heading, Button } from '@lululemon/ecom-pattern-library';
import cx from 'classnames';
import { PaymentMethodPayload } from 'context/cart';
import { useFormat } from 'helpers/hooks/useFormat';
import { isEmpty } from 'helpers/utils/isEmpty';
import { maskValue } from 'helpers/utils/maskCreditcardValue';
import { useAnalytics } from 'hooks/useAnalytics';
import PaymentAdd from 'ui/account/components/payment-methods/payment-add';
import PaymentEdit from 'ui/account/components/payment-methods/payment-edit';
import {
  CreditCardType,
  ModalCyberSourceRequestMapper,
  CreditCardLabelType,
} from 'frontastic/hooks/useCybersource/types';
import styles from './CCPayment.module.scss';
import CreditCardList from './creditCardList';
import CheckoutNotification from '../../checkoutNotification';
import { CheckoutPaymentNotificationType } from '../index';

export interface CCPaymentProps {
  creditCards: CreditCardType[];
  updatePaymentMethod: (data: PaymentMethodPayload) => void;
  notifications: CheckoutPaymentNotificationType;
  notificationHandler: (errorPayload: CheckoutPaymentNotificationType) => void;
}

const CCPayment: FC<CCPaymentProps> = ({ creditCards, updatePaymentMethod, notifications, notificationHandler }) => {
  const { formatMessage } = useFormat({ name: 'checkout' });
  const { formatMessage: formatPaymentMessage } = useFormat({ name: 'payment' });
  const [isOpenNewCC, setIsOpenNewCC] = useState(false);
  const [editModalProps, setEditModalProps] = useState<ModalCyberSourceRequestMapper>();

  const addCreditCardHandler = (event?: MouseEvent) => {
    event?.preventDefault();
    toggleAddModal();
  };

  const hasCreditCards = creditCards.length > 0;
  const initialPaymentNotifications = {
    creditCardError: null,
    arError: null,
    fetchCreditCardsError: null,
    addCreditCardModalNotification: null,
    editCreditCardModalNotification: null,
  };

  const toggleAddModal = () => {
    notificationHandler(initialPaymentNotifications);
    setIsOpenNewCC(!isOpenNewCC);
  };

  const updateNotification = (isError: boolean) => {
    const errorMessage = isError
      ? formatPaymentMessage({
          id: 'save.cc.error',
          defaultMessage: 'We were unable to add your credit card. Please try again.',
        })
      : formatPaymentMessage({
          id: 'save.cc.success',
          defaultMessage: 'New credit card has been added.',
        });

    notificationHandler({
      creditCardModalNotification: {
        message: errorMessage,
        type: isError ? 'error' : 'success',
      },
      creditCardError: null,
    });
  };
  const notificationData =
    notifications.fetchCreditCardsError || notifications.creditCardError || notifications.creditCardModalNotification;

  const successHandler = (isError: boolean, isRemove = false) => {
    const errorMessage = isError
      ? displayUpdateRemoveErrorMessage(isRemove)
      : displayUpdateRemoveSuccessMessage(isRemove);

    notificationHandler({
      creditCardModalNotification: {
        message: errorMessage,
        type: isError ? 'error' : 'success',
      },
      creditCardError: null,
    });
    ``;
  };

  const displayUpdateRemoveSuccessMessage = (isRemove: boolean) => {
    let message = '';
    if (isRemove) {
      message = formatPaymentMessage({
        id: 'payment.remove.success',
        defaultMessage: `Your 
        ${CreditCardLabelType[editModalProps?.creditCardDetails.cardType as keyof typeof CreditCardLabelType]}
        ending in ${maskValue(editModalProps?.creditCardDetails.maskedPan as string)} has been removed`,
      });
    } else {
      message = formatPaymentMessage({
        id: 'payment.update.success',
        defaultMessage: 'Your payment method has been updated.',
      });
    }
    return message;
  };

  function displayUpdateRemoveErrorMessage(isRemove: boolean) {
    let errorMessage = '';
    if (isRemove) {
      errorMessage = formatMessage({
        id: 'payment.remove.failure',
        values: {
          cardType: CreditCardLabelType[editModalProps?.creditCardDetails.cardType as keyof typeof CreditCardLabelType],
          maskedPan: maskValue(editModalProps?.creditCardDetails.maskedPan as string),
        },
        defaultMessage: `We were unable to remove {cardType} ending in {maskedPan}. Please try again.`,
      });
    }
    return errorMessage;
  }

  const openEditModal = (event: MouseEvent, creditCardDetail: CreditCardType) => {
    event.preventDefault();
    notificationHandler(initialPaymentNotifications);
    setEditModalProps({
      isModalOpen: true,
      isConfirmationModalOpen: false,
      creditCardDetails: creditCardDetail,
      listCardsLength: creditCards.length,
    });
  };

  return (
    <div className={styles.container} data-testid="cc-payment__container_test-id">
      <CheckoutNotification
        show={!isEmpty(notificationData)}
        {...notificationData}
        className={cx({ [styles.notification]: isEmpty(notifications.fetchCreditCardsError) })}
        name={'credit-cards'}
      />
      {isEmpty(notifications.fetchCreditCardsError) &&
        (hasCreditCards ? (
          <>
            <Heading tag="h4" className={styles.subtext} data-testid="cc-payment__heading_test-id">
              {formatMessage({
                id: 'select.cc.txt',
                defaultMessage: 'Select a credit card',
              })}
            </Heading>
            <CreditCardList
              creditCards={creditCards}
              addCreditCardHandler={addCreditCardHandler}
              updatePaymentMethod={updatePaymentMethod}
              openEditModal={openEditModal}
            />
          </>
        ) : (
          <>
            <div className={styles.subtitle}>
              {formatMessage({
                id: 'no.saved.card',
                defaultMessage: 'You do not have any saved credit cards on file.',
              })}
            </div>
            <Button
              kind="secondary"
              className={styles.addCard}
              onClick={addCreditCardHandler}
              data-testid="add-new-cc__button_test-id"
            >
              {formatMessage({ id: 'add.new.card', defaultMessage: 'ADD NEW CARD' })}
            </Button>
          </>
        ))}
      {isOpenNewCC && (
        <PaymentAdd
          toggleModalVisibility={addCreditCardHandler}
          creditCardsList={creditCards}
          updateNotification={updateNotification}
        />
      )}

      {editModalProps && (
        <>
          <PaymentEdit
            modalProps={editModalProps}
            setModalProps={setEditModalProps}
            creditCardsData={creditCards}
            onResponseHandler={successHandler}
          />
        </>
      )}
    </div>
  );
};

export default CCPayment;
