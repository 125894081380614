import React, { useEffect, useState, FC, useCallback, useMemo } from 'react';
import NextLink from 'next/link';
import { Link } from '@lululemon/ecom-pattern-library';
import { Address } from 'shared/types/account';
import Combobox, { Option } from 'components/commercetools-ui/atoms/combobox';
import { useCart, PaymentMethodPayload } from 'context/cart';
import { useFormat } from 'helpers/hooks/useFormat';
import { formatAddressDisplay } from 'helpers/utils/formatAddress';
import { isEmpty } from 'helpers/utils/isEmpty';
import { useProjectSettings } from 'hooks/useProjectSettings';
import { useBusinessUnit } from 'frontastic';
import styles from './ARPayment.module.scss';
import { CheckoutNotificationPayloadType } from '../../../index';
import CheckoutNotification from '../../checkoutNotification';
import { PaymentType } from '../index';

export interface ARPaymentProps {
  updatePaymentMethod: (data: PaymentMethodPayload) => void;
  arError: CheckoutNotificationPayloadType;
}

const ARPayment: FC<ARPaymentProps> = ({ updatePaymentMethod, arError }) => {
  const { formatMessage } = useFormat({ name: 'checkout' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const { billingAddresses, defaultBillingAddress, customInvoiceTerms } = useBusinessUnit();

  const [selectedBillingAddress, setSelectedBillingAddress] = useState<Address>();
  const { customerSupportEmail } = useProjectSettings();
  const { cart } = useCart();

  const updatePaymentMethodHandler = useCallback(
    (selectedAddress: Option) => {
      updatePaymentMethod({
        paymentMethod: PaymentType.AR,
        billingAddressId: selectedAddress.value,
      });
    },
    [updatePaymentMethod],
  );

  useEffect(() => {
    if (cart && cart?.payments?.billingAddress?.billingAddressId) {
      const result: Address = findPreSelectedBillingAddress(cart?.payments?.billingAddress?.billingAddressId as string);
      setSelectedBillingAddress(result);
    } else if (defaultBillingAddress) {
      setSelectedBillingAddress(defaultBillingAddress);
    } else if (billingAddresses.length > 0) {
      setSelectedBillingAddress(billingAddresses[0]);
    }
  }, [defaultBillingAddress, cart]);

  const findPreSelectedBillingAddress = (addressIdValue: string) => {
    return billingAddresses.filter((address: Address) => address.addressId === addressIdValue)[0] as Address;
  };

  const options = useMemo(() => {
    return billingAddresses.map((address: Address) => ({
      value: address?.addressId || '',
      label: formatAddressDisplay(address),
      isDefaultValue: address.isDefaultBillingAddress || false,
    }));
  }, [billingAddresses]);

  const searchHandler = (searchTxt: string): Option[] => {
    return options.filter((address) => {
      return address.label.toLowerCase().includes(searchTxt.toLowerCase());
    });
  };

  const hasARError = !isEmpty(arError);

  return (
    <div className={styles.container} data-testid="ar-payment__container_test-id">
      <CheckoutNotification show={hasARError} {...arError} className={styles.notification} />
      <div className={styles.subtext}>
        {formatMessage({ id: 'more.info.text', defaultMessage: 'For more information, please ' })}
        <NextLink href="account#payment" passHref>
          <Link data-testid="ar-payment__billing-info-link_test-id" className={styles.link}>
            {formatMessage({ id: 'go.to.billing.text', defaultMessage: 'go to Billing Information' })}
          </Link>
        </NextLink>
      </div>
      <div className={styles.paymentTerm}>{formatMessage({ id: 'payment.term', defaultMessage: 'Payment term' })}</div>
      <div className={styles.paymentTermValue} data-testid="ar-payment__invoice-term_test-id">
        {customInvoiceTerms
          ? `${formatMessage({ id: 'pay.net.text', defaultMessage: 'Pay via Net' })}${customInvoiceTerms}`
          : '-'}
      </div>
      <div className={styles.billingAddress}>
        {formatMessage({ id: 'billing.address.title', defaultMessage: 'Select a billing address' })}
      </div>
      <div className={styles.addressSubtext}>
        {formatMessage({
          id: 'billing.address.text',
          defaultMessage: 'If you want to update the billing address please contact your Account Manager at ',
        })}
        <NextLink href={`mailto:${customerSupportEmail}`} passHref>
          <Link data-testid="ar-payment__account-manager_test-id" className={styles.link}>
            {customerSupportEmail}
          </Link>
        </NextLink>
      </div>
      {billingAddresses &&
        billingAddresses.length > 0 &&
        selectedBillingAddress !== undefined &&
        selectedBillingAddress !== null && (
          <>
            <Combobox
              options={options}
              name="billing-addresses"
              onChange={updatePaymentMethodHandler}
              initialSelectedItem={
                {
                  value: selectedBillingAddress?.addressId || '',
                  label: formatAddressDisplay(selectedBillingAddress),
                  isDefaultValue: selectedBillingAddress.isDefaultBillingAddress,
                } as Option
              }
              searchHandler={searchHandler}
              label="Select Billing Address"
              noResultsFoundContent={
                <>
                  <div>{formatMessage({ id: 'no.address.found', defaultMessage: 'No address found.' })}</div>
                  <div>
                    {formatMessage({
                      id: 'search.subtext',
                      defaultMessage: 'Try searching with different keywords.',
                    })}
                  </div>
                </>
              }
            />
          </>
        )}
    </div>
  );
};

export default ARPayment;
