import React from 'react';
import LegalPage, { LegalPageProps } from '../../../ui/customer-support/components/legal-page';

interface Props {
  data: LegalPageProps;
}

const LegalPageTastic: React.FC<Props> = ({ data }) => {
  return <LegalPage {...data} />;
};

export default LegalPageTastic;
