import React, { useState } from 'react';
import { Heading, Icon, bagIcon, caratIcon, Button } from '@lululemon/ecom-pattern-library';
import cs from 'classnames';
import { LineItem } from 'shared/types/ngcCart';
import { useCart } from 'context';
import { useFormat } from 'helpers/hooks/useFormat';
import CartSummaryLineItem from './cart-summary-line-item';
import styles from './cartSummary.module.scss';
import { CheckoutStep } from '../..';
import PaymentSummary from '../paymentSummary';

type CartSummaryProps = {
  activeStep: CheckoutStep;
};

const CartSummary: React.FC<CartSummaryProps> = ({ activeStep }) => {
  const { cart } = useCart();
  const [expanded, setExpanded] = useState<boolean>(true);
  const { formatMessage } = useFormat({ name: 'checkout' });
  const lineItems = cart?.lineItems;

  const totalQuantity = cart?.totalQuantity || 0;
  const isMultipleItems = totalQuantity > 1;
  const cartLabel = isMultipleItems
    ? formatMessage({ id: 'items.in.cart', defaultMessage: 'Items in cart' })
    : formatMessage({ id: 'item.in.cart', defaultMessage: 'Item in cart' });

  return (
    <div className={`${cs(styles.cartSummaryContainer)}`} data-testid="orderSummary__container_test-id">
      <Heading className="lll-text-small" tag="h2">
        {formatMessage({
          id: 'order.summary.heading',
          defaultMessage: 'Order summary',
        })}
      </Heading>
      <Button
        data-testid="orderSummary__button_test-id"
        kind="transparent"
        className={styles.bagButton}
        onClick={() => setExpanded(!expanded)}
        aria-label={`${totalQuantity} ${cartLabel}`}
        aria-expanded={expanded}
      >
        <div className={styles.cartBagQtyContainer}>
          <div className="flex gap-8">
            <Icon content={bagIcon} data-testid="cart__icon_test-id" />
            {totalQuantity && (
              <div data-testid="cart-summary__total-quantity_test-id" className={styles.itemQuantity}>
                {totalQuantity}{' '}
                {isMultipleItems
                  ? formatMessage({ id: 'items', defaultMessage: 'items' })
                  : formatMessage({ id: 'item', defaultMessage: 'item' })}
              </div>
            )}
          </div>
          <Icon
            className={expanded ? cs(styles.caratIconExpanded) : cs(styles.caratIconCollapsed)}
            content={caratIcon}
          />
        </div>
      </Button>
      {expanded && (
        <div className={cs(styles.cartItemsContainer)} data-testid="CartSummary__LineItem_test-id">
          {lineItems?.map((lineItem: LineItem) => (
            <CartSummaryLineItem key={lineItem.id} lineItem={lineItem} />
          ))}
        </div>
      )}
      <PaymentSummary activeStep={activeStep} />
    </div>
  );
};

export default CartSummary;
