import { SizeGuideProps, SubcategorySizeGuide, PrimaryContent } from '../types';

export const PARENT_CATEGORY_SLUG_WOMENS = 'womens';
export const PARENT_CATEGORY_SLUG_MENS = 'mens';

export const SIZE_GUIDE_CATEGORIES = {
  womens: [
    'womens-bras',
    'womens-shoes-only-for-generic',
    'womens-underwear',
    'womens-socks',
    'womens-tops',
    'womens-skirts',
    'womens-pants',
    'womens-leggings',
    'womens-shorts',
    'womens-swimwearonesies',
    'womens-hats',
    'womens-leggings-redesign',
    'womens-pants-redesign',
  ],
  mens: [
    'mens-tops',
    'mens-shoes',
    'mens-shorts',
    'mens-socks',
    'mens-bottoms',
    'mens-pants',
    'mens-outerwear',
    'mens-underwear',
    'mens-hats',
  ],
};

export const DISALLOWED_SECONDARY_CATEGORIES = [
  'mens-bottoms',
  'womens-bottoms',
  'womens-pants-redesign',
  'womens-leggings-redesign',
];

export const ALPHA_GRADE_SIZES = ['XS', 'S', 'M', 'L', 'XL'];
export const ALPHA_GRADE_SIZES_FRENCH = ['TP', 'P', 'M', 'G', 'TG'];
export const NUMERIC_GRADE_SIZES = ['00', '0', '2', '4', '6', '8', '10', '12', '14', '16'];
export const NUMERIC_LEGGINGS_GRADE_SIZES = ['0', '2', '4', '6', '8', '10', '12', '14', '16', '18', '20'];
export const NUMERIC_WAIST_GRADE_SIZES = ['24', '25', '26', '27', '28', '29', '30', '31', '32', '33'];

// SIZE GRADE NAMES
export const ALPHA_GRADE_NAME = 'alpha';
export const NUMERIC_GRADE_NAME = 'numeric';
export const NUMERIC_LEGGINGS_GRADE_NAME = 'numeric-leggings';
export const NUMERIC_WAIST_GRADE_NAME = 'numeric-waist';

// TABLES_REFERENCES
export const NUMERIC_GRADE_TABLE_REFERENCE = '00-16X';
export const NUMERIC_LEGGINGS_TABLE_REFERENCE = '0-20X';
export const NUMERIC_WAIST_TABLE_REFERENCE = '24-33X';
export const ALPHA_GRADE_TABLE_REFERENCE = 'XS-XL';

/**
 * Utility function that formats the subcategory size guide data from contentful
 * @param contentfulResults
 * @returns
 */

export const formatContentfulSizeGuideData = (contentfulResults: any): SubcategorySizeGuide | null => {
  if (!contentfulResults) {
    return null;
  }

  try {
    const { items = [] } = contentfulResults;
    if (!Array.isArray(items) || items.length === 0) {
      return null;
    }

    const collection = items.map((entry: any) => {
      const fields = entry?.fields;

      if (!fields) {
        return null;
      }

      const {
        footerContent = [],
        modalFooterTitle = '',
        name = '',
        primaryContent = [],
        productCategoryId = '',
        productCategoryL1 = '',
        productCategoryL2 = '',
        secondaryContent = [],
        title = '',
      } = fields;

      return {
        footerContent,
        modalFooterTitle,
        name,
        primaryContent,
        productCategoryId,
        productCategoryL1,
        productCategoryL2,
        secondaryContent,
        title,
      } as SubcategorySizeGuide;
    });

    if (collection.length === 1) {
      return collection[0];
    }

    return null;
  } catch (e) {
    return null;
  }
};

/**
 * Utility function that formats the mens and womens size guide page data from contentful
 * @param contentfulResults
 * @returns
 */

export const formatSizeGuidePageData = (contentfulResults: any): SizeGuideProps | null => {
  if (!contentfulResults) {
    return null;
  }
  try {
    const { items = [] } = contentfulResults;
    if (!Array.isArray(items) || items.length === 0) {
      return null;
    }
    const {
      fields: { slug, components, pageMetadata },
    } = items[0];
    const title = items[0].fields?.components[0].fields?.heading;
    let tabListFields: any = {};
    const tabContentFields = components[0].fields.components.filter((component: any) => {
      const { id = '' } = component.sys.contentType.sys;
      if (id === 'linkList') {
        tabListFields = component;
      }
      return id === 'sizeGuide';
    });

    const { links: tabLists = [] } = tabListFields?.fields;

    const categories = tabContentFields.map(({ fields }: { fields: any }) => {
      if (!fields) {
        return {};
      }
      const {
        title: subtitle,
        json,
        productCategoryId,
        productCategoryL2,
        pageFooterTitle,
        secondaryContent,
        footerContent,
        primaryContent,
      } = fields;
      return {
        sizeGuideCategory: productCategoryId,
        subtitle,
        legacySizeGuideData: json,
        secondaryFilterTileTitle: productCategoryL2,
        pageFooterTitle,
        secondaryContent,
        primaryContent,
        footerContent,
      };
    });
    return {
      pageLinks: tabLists,
      sizeGuideTitle: title,
      pageMetadata: pageMetadata?.fields,
      slug,
      sizeGuideCategories: categories,
    };
  } catch (e) {
    return {
      pageLinks: [],
      sizeGuideTitle: '',
      slug: '',
      sizeGuideCategories: [],
    };
  }
};

/**
 * Utility function that formats the title of the size guide
 * @param inputTitle
 * @param shouldSplit
 * @returns
 */

export const formatSizeGuideTitle = (inputTitle = '', shouldSplit = false) => {
  let title = typeof inputTitle === 'string' ? inputTitle : '';
  let isLineBreakInTitle = false;
  const shouldSplitCheck = typeof shouldSplit !== 'boolean' ? false : shouldSplit;

  try {
    const hasAsterisk = title?.includes('*');
    isLineBreakInTitle = shouldSplitCheck && hasAsterisk;
    if (!hasAsterisk) return { title, isLineBreakInTitle };
    const split = title?.split('*');
    title = isLineBreakInTitle ? split.join('\n') : split.join('');
  } catch (ex) {
    return { title, isLineBreakInTitle };
  }

  return { title, isLineBreakInTitle };
};

/**
 * Utility function that returns the filtered categories
 * @param sizeGuideCategories
 * @returns
 */

export const getFilteredCategories = (sizeGuideCategories: any) => {
  if (!sizeGuideCategories || sizeGuideCategories?.length === 0) return [];

  return sizeGuideCategories.filter(
    ({ sizeGuideCategory }: { sizeGuideCategory: string }) =>
      !DISALLOWED_SECONDARY_CATEGORIES.includes(sizeGuideCategory),
  );
};

/**
 * Utility function that returns the active category index
 * @param pageLinks
 * @param slug
 * @returns
 */

export const getActiveCategoryIdx = (pageLinks: any, slug: string) => {
  if (!pageLinks || !pageLinks?.length || !slug) {
    return 0;
  }

  try {
    return pageLinks?.findIndex(
      ({ fields: { webRoute } }: { fields: { webRoute: any } }) =>
        webRoute.substr(webRoute.lastIndexOf('/') + 1) === slug,
    );
  } catch (ex) {
    return 0;
  }
};

/**
 * Utility function that returns the table size grade
 * @param tableName
 * @returns
 */

export const getTableSizeGrade = (tableName: any) => {
  if (tableName.includes(ALPHA_GRADE_TABLE_REFERENCE)) {
    return ALPHA_GRADE_NAME;
  }
  if (tableName.includes(NUMERIC_WAIST_TABLE_REFERENCE)) {
    return NUMERIC_WAIST_GRADE_NAME;
  }
  if (tableName.includes(NUMERIC_LEGGINGS_TABLE_REFERENCE)) {
    return NUMERIC_LEGGINGS_GRADE_NAME;
  }

  return '';
};

/**
 * converts a camelCase string to kebab-case by inserting a hyphen before each uppercase letter and then removing any leading hyphen
 * @param str
 */
export const toKebabCase = (str: string) => {
  return str.replace(/[A-Z]/g, (match) => '-' + match.toLowerCase()).replace(/^-/, '');
};

/**
 * Utility function that hashes a stringified object containing some unique value
 * and returns a hashed numeric value.
 * Performs @ ~900k ops/sec
 * @param {string} stringified numeric hash
 */
const generateHash = (str: string) => {
  let hash = 0;
  const len = str.length;

  if (len === 0) {
    return hash;
  }

  for (let i = 0; i < len; i += 1) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash |= 0; // Convert to 32bit integer
  }

  return hash;
};

const stringify = (obj: any) => JSON.stringify(obj);

/**
 * Utility function that stringifies an object containing some unique value
 * and returns a hashed stringified value
 * @param {object}
 */
export const createEncodedKey = (strOrObj: any) => stringify(generateHash(stringify(strOrObj)));

/**
 * Utility function that returns a boolean value indicating if the product is a one size product
 * @param sizes
 * @returns
 */

export const isOneSizeProduct = (sizes: string[]) =>
  sizes && sizes.length === 1 && ['ONE SIZE', 'taille unique', 'O/S'].includes(sizes[0]);

/**
 * Utility function that modifies the content of the size guide from contentful to remove content that doesn't apply for B2B size guide
 */

export const modifyContent = (primaryContent: PrimaryContent, sizeGuideCategory?: string) => {
  switch (sizeGuideCategory) {
    case 'womens-shoes-only-for-generic':
      primaryContent.fields.body.content = primaryContent.fields.body.content.slice(0, 3);
      break;
    case 'womens-leggings':
      primaryContent.fields.body.content = primaryContent.fields.body.content.slice(0, 2);
      break;
    case 'womens-pants':
      primaryContent.fields.body.content = primaryContent.fields.body.content.slice(0, 6);
      break;
    case 'womens-hats':
      primaryContent.fields.body.content = primaryContent.fields.body.content.slice(0, 8);
      break;
    case 'mens-shoes':
      primaryContent.fields.body.content = primaryContent.fields.body.content.slice(0, 4);
      break;
    case 'mens-hats':
      primaryContent.fields.body.content = primaryContent.fields.body.content.slice(0, 8);
      break;
    case 'mens-pants':
      const mensPantsContent =
        primaryContent.fields.body.content[4].data.target.fields.components[1].fields.components[0].fields.body.content;
      primaryContent.fields.body.content[4].data.target.fields.components[1].fields.components[0].fields.body.content =
        mensPantsContent.slice(0, 4);
      break;
    default:
      break;
  }

  return primaryContent;
};
