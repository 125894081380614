import { COMPONENT_TYPE } from 'helpers/constants/analyticsEventType';
import { toPageProductDisplayedProduct } from 'helpers/mappers/toProductCollectionList';

export const PDP_ANALYTIC_EVENT = {
  PDP_PAGE_VIEW: (pdpData: any) => {
    return {
      screen: {
        attributes: {
          gender: pdpData.variant?.attributes.gender[0] ?? '',
          pageType: 'pdp',
          primaryCategory: pdpData.category?.categoryId ?? '',
          subCategory1: pdpData.category?.subCategories[0] ?? '',
          categoryName: pdpData.category?.name ?? '',
        },
        collections: [''],
        header: {
          unified: pdpData.category?.name ?? '',
        },
      },
      collectionList: toPageProductDisplayedProduct([pdpData], 'product-list', COMPONENT_TYPE.PRODUCT_DETAILS),
    };
  },

  COLOR_SWATCH_CLICKED: (product: any, productText: string) => {
    return {
      component: {
        id: 'color_swatch',
        type: COMPONENT_TYPE.PRODUCT_DETAILS,
        text: productText,
      },
      collectionList: product,
    };
  },

  PRODUCT_IMAGE_ZOOM_MODAL: (product: any) => {
    return {
      component: {
        id: 'product_image_zoom',
        type: COMPONENT_TYPE.PRODUCT_DETAILS,
        text: '',
      },
      collectionList: product,
    };
  },
  PRODUCT_IMAGE_THUMBNAILS: (product: any) => {
    return {
      component: {
        id: 'product_image_thumbnail',
        type: COMPONENT_TYPE.PRODUCT_DETAILS,
        text: '',
      },
      collectionList: product,
    };
  },
  PRODUCT_EDUCATION_ACCORDION_CLICK: (title: string | undefined) => {
    return {
      component: {
        id: 'product_education_accordion',
        type: COMPONENT_TYPE.LINK,
        text: `${title}`,
      },
    };
  },
  SIZE_GUIDE_LINK_CLICKED: {
    component: {
      id: 'size_guide_link',
      type: COMPONENT_TYPE.LINK,
      text: 'size guide',
    },
  },
  PRODUCT_INSEAM_CLICKED: (data: any, productText: string) => {
    return {
      component: {
        id: 'size_swatch',
        type: COMPONENT_TYPE.PRODUCT_DETAILS,
        text: productText,
      },
      collectionList: data,
    };
  },
  INVENTORY_GRID_INTERACTION: (data: any, inputValue: string) => {
    return {
      component: {
        id: 'product_tile',
        type: COMPONENT_TYPE.INVENTORY_GRID,
        text: inputValue,
      },
      collectionList: data,
    };
  },
  ADD_TO_CART_CLICKED: (data: any) => {
    return {
      component: {
        id: 'add_to_cart',
        type: COMPONENT_TYPE.BUTTON,
        text: `Add to Bag`,
      },
      collectionList: data,
    };
  },

  ADD_TO_CART_ERROR: (errorDetails: any) => {
    return {
      error: {
        errorType: 'add_to_cart_error',
        errorMessage: 'Error in adding items to cart',
        errorGuestFacing: true,
        errorDetails: errorDetails,
      },
    };
  },
  VIEW_BAG_BUTTON_CLICK: {
    component: {
      id: 'add_to_bag_modal_view_bag',
      type: COMPONENT_TYPE.BUTTON,
      text: 'View Bag & Checkout',
    },
  },

  CONTINUE_SHOPPING_BUTTON_BLICK: {
    component: {
      id: 'add_to_bag_modal_continue',
      type: COMPONENT_TYPE.BUTTON,
      text: 'Continue Shopping',
    },
  },
};
