import React, { createContext } from 'react';
import { Market, HandleMarketProps } from 'shared/types/header';
import useMarket from 'helpers/hooks/useMarket';

const initialMarketState = {
  market: {} as Market,
  markets: [] as Market[],
  handleMarket: {} as ({ market }: HandleMarketProps) => void,
};

const MarketContext = createContext(initialMarketState);

interface Props {
  children: any;
}

const MarketProvider: React.FC<Props> = ({ children }) => {
  const { market, markets, handleMarket } = useMarket();

  return (
    <MarketContext.Provider value={{ market: market as Market, markets, handleMarket }}>
      {children}
    </MarketContext.Provider>
  );
};

export { MarketContext, MarketProvider };
